import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from '@mui/icons-material/Info';
import TokenIcon from '@mui/icons-material/Token';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

//icons
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const content = {
  tokenUsage: [
    'Each lead consumes 1 token. For 100 leads: 100 x 1 = 100 tokens.',
    'Each personalized email consumes tokens, hence 100 x 7 = 700 tokens.',
    'Each data augmentation consumes 8-10 tokens depending on the augmentation technique.',
  ],
  subscriptionDetails: {
    annual:
      'If you have an annual subscription, your tokens will be carried over to the next one.',
    monthly:
      'If you are a monthly subscriber, your tokens will be reset at the end of each month.',
  },
};

const fetchBilling = async () => {
  const response = await fetchWithRewrites('/api/billing');
  const res_json = await response.json();

  console.log(res_json, 'the response');

  return res_json;
};

interface BillingDetails {
  balance: number;
  usage: Record<string, number>;
  costs: Record<string, number>;
}

// Define component props interface
interface TokenCostsDisplayProps {
  billingDetails: BillingDetails;
}

const TokenCostsDisplay: React.FC<TokenCostsDisplayProps> = ({
  billingDetails,
}) => {
  return (
    <Paper sx={{ width: '100%', margin: 'auto', mt: 4 }}>
      <Card
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 2,
          boxShadow: 8,
        }}
      >
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TokenIcon color="primary" fontSize="large" />
              <Typography variant="h6" color="primary">
                Token Costs (per call)
              </Typography>
            </Box>
          }
        />
        <CardContent>
          {Object.keys(billingDetails.costs).length > 0 ? (
            <List sx={{ width: '100%' }}>
              {Object.entries(billingDetails.costs).map(
                ([key, value], index) => (
                  <ListItem
                    key={index}
                    sx={{
                      py: 1,
                      px: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(144, 202, 249, 0.08)',
                        borderRadius: 1,
                      },
                      transition: 'background-color 0.2s',
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <ArrowForwardIosIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'monospace',
                            color: 'text.primary',
                          }}
                        >
                          {key}
                        </Typography>
                      }
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography
                        variant="h6"
                        color="primary"
                        sx={{ fontWeight: 'bold' }}
                      >
                        {value}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 0.5 }}
                      >
                        tokens
                      </Typography>
                    </Box>
                  </ListItem>
                )
              )}
            </List>
          ) : (
            <Typography
              color="text.secondary"
              sx={{ py: 2, textAlign: 'center' }}
            >
              No costs data recorded
            </Typography>
          )}

          <Divider sx={{ my: 3 }} />

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              bgcolor: 'rgba(144, 202, 249, 0.05)',
              p: 2,
              borderRadius: 1,
            }}
          >
            <InfoIcon color="primary" fontSize="small" />
            <Typography variant="body2" color="text.secondary">
              To calculate your total tokens consumption, multiply your call
              usage with token costs per call for the corresponding call type
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
};

const BillingComponent = () => {
  const [billingDetails, setBillingDetails] = useState({
    balance: NaN,
    usage: {} as Record<string, number>,
    costs: {} as Record<string, number>,
  });

  const { snackbarEntries, setStoreState } = useAlchemystStoreForAi(
    (store) => ({
      snackbarEntries: store.snackbarEntries,
      setStoreState: store.setStoreState,
    })
  );

  useEffect(() => {
    console.log(billingDetails, 'THE BILLING DETAILS');
  }, [billingDetails]);

  useEffect(() => {
    fetchBilling()
      .then((response) => setBillingDetails(response))
      .catch((error) => {
        console.log(error);
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              message: 'An error occurred while fetching billing details',
              severity: 'error',
              type: 'alert',
              // autoHideDuration: 6000,
              notificationId: crypto.randomUUID(),
            },
          ],
        });
      });
  }, []);

  return (
    <Box sx={(theme) => ({ background: theme.palette.background.default })}>
      <Stack flex={1} flexDirection={'column'} spacing={3}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              textAlign: 'left',
            }}
          >
            <Typography variant="h4">Token Balance</Typography>
            <Typography variant="h5">{billingDetails.balance}</Typography>
            <Typography variant="body1">Remaining tokens</Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'left',
              paddingLeft: '0.8rem',
            }}
          >
            <Typography variant="h4">Usage (by calls)</Typography>
            {Object.keys(billingDetails.usage).length > 0 ? (
              Object.keys(billingDetails.usage).map((key, index) => (
                <Box key={index}>
                  <Typography>
                    {key}: {billingDetails.usage[key]}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography>No usage data recorded</Typography>
            )}
          </Box>
        </Box>
        <Box>
          {Object.keys(billingDetails.costs).length > 0 ? (
            <TokenCostsDisplay billingDetails={billingDetails} />
          ) : (
            <Typography>No costs data recorded</Typography>
          )}
          <Divider sx={{ py: 2, mx: 2 }} />
          <Typography>
            To calculate your total tokens consumption, multiply your call usage
            with token costs per call for the corresponding call type
          </Typography>
        </Box>
        <Stack
          spacing={3}
          //   sx={{
          //     paddingTop: '1.5rem',
          //   }}
        >
          <Paper
            elevation={3}
            sx={{
              padding: '0.8rem',
            }}
          >
            <Typography variant="h6" gutterBottom color="primary">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon sx={{ marginRight: '8px' }} />
                Token Usage
              </Box>
            </Typography>
            {content.tokenUsage.map((item, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{ marginBottom: '8px' }}
              >
                {item}
              </Typography>
            ))}
          </Paper>

          <Paper
            elevation={3}
            sx={{
              padding: '1rem',
            }}
          >
            <Typography variant="h6" gutterBottom color="primary">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon sx={{ marginRight: '8px' }} />
                Subscription Details
              </Box>
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginBottom: '4px', fontWeight: 'bold' }}
            >
              {content.subscriptionDetails.annual}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              {content.subscriptionDetails.monthly}
            </Typography>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BillingComponent;

const Blockquote = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        borderLeft: '4px solid #1976d2', // Accent border
        padding: '16px 24px',
        backgroundColor: '#f9f9f9', // Light background
        borderRadius: '8px',
        fontStyle: 'italic',
      }}
    >
      <Typography variant="body1" color="textSecondary">
        {children}
      </Typography>
    </Box>
  );
};
