import TimeInput from '@/common/DateTimePicker';
import { StyledButton } from '@/constants/themes';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import { StepConfig } from '@/types/campaign';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import CreateIcon from '@mui/icons-material/Create';
import InfoIcon from '@mui/icons-material/Info';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  dividerClasses,
} from '@mui/material';
import { RichTextEditor } from 'mui-tiptap';
import EditorMenuControls, { InsertTag } from '../EditorMenuControls';
import useExtensions from '../useExtensions';

export interface TextSpaceTabProps {
  currentStep: StepConfig | null;
  setCurrentStep: (currentStep: StepConfig | null) => void;
  selectedLeads: Record<string, any>[];
  usingAiFunctions: 'idle' | 'rephrasing' | 'generating';
  setUsingAiFunctions: (newState: 'idle' | 'rephrasing' | 'generating') => void;
  setOpenModal: (newState: boolean) => void;
  handleRichTextChange: (content: string, generatedUsingAI?: boolean) => void;
  editorKey: number;
  setEditorKey: React.Dispatch<React.SetStateAction<number>>;
  templates: { name: string; body: { bodyText: string } }[];
  handleSaveStep: () => void;
  setTemplateSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const selectTagsFromLeads = (selectedLeads: Record<string, any>[]) => {
  return selectedLeads.length > 0 ? Object.keys(selectedLeads[0]) : [];
};

function TextSpaceTab({
  currentStep,
  selectedLeads,
  setCurrentStep,
  setUsingAiFunctions,
  usingAiFunctions,
  setOpenModal,
  handleRichTextChange,
  editorKey,
  setEditorKey,
  handleSaveStep,
  templates,
  setTemplateSelected,
}: TextSpaceTabProps): React.ReactNode {
  const {
    campaignId,
    sequenceStepsConfig,
    sequenceStepFields,
    channels,
    stepConfig,
    campaignLeads,
  } = useCampaignBuilderStore((store) => ({
    campaignId: store.campaignData?.id,
    sequenceStepsConfig: store.campaignData?.stepConfig,
    channels: store.campaignData?.channels,
    sequenceStepFields: store.campaignData?.steps
      .map((step) => step.fields)
      .flat(),
    stepConfig: store.campaignData?.stepConfig,
    campaignLeads: store.campaignData?.leads,
  }));

  console.log('THE TEMPLATES', templates);

  const extractTextFromHtml = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const handleGenerateTemplateWithAi = async (
    currentStep: StepConfig,
    campaignContext: Record<string, any>[],
    content: string,
    action: 'rephrase' | 'create'
  ): Promise<void> => {
    setUsingAiFunctions(action === 'rephrase' ? 'rephrasing' : 'generating');

    const requestBody = {
      action,
      campaignId,
      template: content,
      type: currentStep.actions.includes('email') ? 'email' : 'social',
      leads: selectedLeads,
      localContext: campaignContext,
      currentContent: {
        text: currentStep?.message?.body || '',
        date: '',
        from: '',
        to: '',
        subject: currentStep.message.subject,
      },
      campaignHistory: (sequenceStepsConfig ?? [])
        .filter((_, idx) => idx < (currentStep.id ?? 0))
        .map((stepConfigEntry) => stepConfigEntry.message),
    };

    try {
      const response = await fetchWithRewrites('/api/campaigns/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        setUsingAiFunctions('idle');
        alert('Failed to generate content');
        return;
      }
      const responseJson = await response.json();
      if (response.ok) {
        if (currentStep) {
          // setStoreState({
          //   campaignData: {
          //     ...useCampaignBuilderStore.getState().campaignData,
          //     stepConfig: useCampaignBuilderStore
          //       .getState()
          //       .campaignData?.stepConfig.map((step) => {
          //         if (step.id === currentStep.id) {
          //           return {
          //             ...step,
          //             aiGenerated: true,
          //             message: {
          //               ...step.message,
          //               body: responseJson.content.text,
          //             },
          //             id: step.id || '', // Ensure id is always a string
          //           };
          //         }
          //         return step;
          //       }),
          //   },
          // });
          // console.log('THE CURRENT ID', currentStep.id);
          // setCurrentStep({
          //   ...currentStep,
          //   aiGenerated: true,
          //   message: {
          //     ...currentStep.message,
          //     body: responseJson.content.text,
          //   },
          // });
          //TODO forcing state udpate should change
          setEditorKey((prev) => prev + 1);
        }
        setUsingAiFunctions('idle');
        handleRichTextChange(responseJson.content.text, true);
      } else {
        setUsingAiFunctions('idle');
        console.error('Error while generating content', responseJson);
      }
    } catch (error) {
      console.error('Error while generating content', error);
      alert('Failed to generate content');
      setUsingAiFunctions('idle');
    }
  };

  // useEffect(() => {
  //   console.log('THE CURRENT STEP', currentStep);
  // }, [currentStep]);

  const toggleGenerateRephrasedContentWithAi = async () => {
    if (!currentStep) return;
    setCurrentStep({
      ...currentStep,
      useAi: {
        modify: !currentStep.useAi.modify,
      },
    });
  };
  const handleWaitTimeChange = (time: number): void => {
    if (currentStep) {
      setCurrentStep({ ...currentStep, waitTime: time });
    }
  };

  const handleSelectTag = (tag: string) => {};
  const handleSubjectSelectTag = (tag: string) => {
    if (currentStep) {
      setCurrentStep({
        ...currentStep,
        message: {
          ...currentStep.message,
          subject: `${currentStep.message.subject}{{${tag}}}`,
        },
      });
    }
  };

  const extensions = useExtensions({
    placeholder: 'Write your content here...',
  });

  const convertSecondsToTimeFormat = (totalSeconds: number) => {
    const days = Math.floor(totalSeconds / (24 * 3600));
    totalSeconds %= 24 * 3600;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);

    return {
      days,
      hours,
      minutes,
    };
  };

  return (
    <Box>
      <Typography gutterBottom variant="h6">
        {currentStep?.id === 0 ? 'Warmup Content' : `Follow-Up Content`}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 4,
          alignItems: 'flex-start',
        }}
      >
        <Box sx={{ flex: 1.2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              bgcolor: 'background.paper',
              color: 'text.secondary',
              '& svg': {
                m: 1,
              },
              [`& .${dividerClasses.root}`]: {
                mx: 0.5,
              },
            }}
          >
            <Typography
              sx={{
                px: 2,
              }}
            >
              Subject:{' '}
            </Typography>

            <Divider flexItem orientation="vertical" />
            <TextField
              fullWidth
              onChange={(e) => {
                if (!currentStep) return;
                setCurrentStep({
                  ...currentStep,
                  message: {
                    ...currentStep.message,
                    subject: e.target.value,
                  },
                });
              }}
              value={currentStep?.message?.subject || ''}
              variant="standard"
            />
            <Divider flexItem orientation="vertical" />
            <InsertTag
              availableTags={selectTagsFromLeads(selectedLeads)}
              onSelectTag={handleSubjectSelectTag}
            />
          </Box>

          <Box mt={3}>
            <Typography gutterBottom>Content:</Typography>
            <RichTextEditor
              content={currentStep?.message.body || ''}
              extensions={extensions}
              key={editorKey}
              onUpdate={({ editor }) => {
                handleRichTextChange(editor.getHTML());
              }}
              renderControls={() => (
                <EditorMenuControls
                  availableTags={selectTagsFromLeads(selectedLeads)}
                  onSelectTag={handleSelectTag}
                />
              )}
            />
          </Box>

          <Box mt={3}>
            {channels && channels?.whatsapp?.length > 0 && (
              <Stack
                direction="column"
                display="flex"
                gap={2}
                justifyContent="right"
                justifyItems="right"
              >
                <Typography>
                  WhatsApp channel was detected!. Please select a template for
                  whatsapp
                </Typography>
                <Box
                  display="flex"
                  gap={2}
                  justifyContent="left"
                  justifyItems="left"
                >
                  <Select
                    label="Select a Name"
                    onChange={(e) => {
                      if (currentStep) {
                        setCurrentStep({
                          ...currentStep,
                          templateNameForWhatsapp: e.target.value as string,
                        });
                        setTemplateSelected(false);
                      }
                    }}
                    value={currentStep?.templateNameForWhatsapp ?? ''}
                  >
                    {templates.map((item, idx) => (
                      <MenuItem key={idx} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {currentStep?.templateNameForWhatsapp && (
                    <Box
                      p={2}
                      sx={{
                        border: '1px solid gray',
                        borderRadius: '0.4rem',
                      }}
                    >
                      <Typography fontWeight="bold" variant="subtitle1">
                        Selected Template:
                      </Typography>
                      <Typography color="textPrimary" variant="body1">
                        {templates
                          .find(
                            (template) =>
                              template.name ===
                              currentStep.templateNameForWhatsapp
                          )
                          ?.body.bodyText.replace(
                            /{{\d+}}/g,
                            '{{Your content goes here}}'
                          )}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Stack>
            )}
          </Box>

          <Stack
            direction="row"
            display="flex"
            gap={4}
            justifyContent="right"
            justifyItems="right"
            py={2}
          >
            <Stack display="flex" flexDirection="row" gap={2}>
              <StyledButton
                disabled={usingAiFunctions !== 'idle'}
                onClick={async () => {
                  await handleGenerateTemplateWithAi(
                    currentStep!,
                    sequenceStepFields ?? [],
                    currentStep?.message?.body || '',
                    'create'
                  );
                }}
                startIcon={<CreateIcon />}
              >
                {usingAiFunctions !== 'idle'
                  ? `${usingAiFunctions[0] + usingAiFunctions.slice(1)}...`
                  : 'Generate template'}
              </StyledButton>
            </Stack>
            <Stack display="flex" flexDirection="row" gap={2}>
              <StyledButton
                disabled={usingAiFunctions !== 'idle'}
                onClick={async (): Promise<void> => {
                  if (!currentStep) return;
                  await handleGenerateTemplateWithAi(
                    currentStep,
                    sequenceStepFields ?? [],
                    currentStep.message?.body || '',
                    'rephrase'
                  );
                }}
                startIcon={<UpdateIcon />}
              >
                {usingAiFunctions !== 'idle'
                  ? `${usingAiFunctions[0] + usingAiFunctions.slice(1)}...`
                  : 'Rephrase template'}
              </StyledButton>
            </Stack>
            <Box
              border={1}
              borderRadius={10}
              display="flex"
              flexDirection="row"
              gap={2}
              justifyItems={'center'}
              px={2}
              py={1}
            >
              <Box
                alignContent={'space-between'}
                alignItems={'center'}
                display="flex"
                flexDirection="row"
                gap={1}
              >
                <Typography textAlign={'right'}>
                  DynaMail - Rephrase content while sending
                </Typography>
                <Switch
                  checked={currentStep?.useAi?.modify ?? false}
                  disabled={(campaignLeads?.length ?? 0) > 50}
                  onChange={toggleGenerateRephrasedContentWithAi}
                />
                <Tooltip
                  arrow
                  title="WARNING: This feature is highly experimental! This might create rubbish emails as easily as hyperpersonalized emails. ONLY use it if you ABSOLUTELY WANT TO rephrase the content while sending. Also, this is disabled for more than 50 leads."
                >
                  <InfoIcon />
                </Tooltip>
              </Box>
            </Box>
          </Stack>
        </Box>

        <Box sx={{ flex: 0.8 }}>
          <Typography gutterBottom>Wait Time Before Sending:</Typography>
          <TimeInput
            initialValues={convertSecondsToTimeFormat(
              currentStep?.waitTime ?? 0
            )}
            onChange={handleWaitTimeChange}
          />

          <Box mt={3}>
            <Typography gutterBottom>Content Preview</Typography>
            <Box
              p={2}
              sx={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                height: '200px',
                overflow: 'auto',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: (currentStep?.message?.body ?? '')
                    .replace('\n', '<br />')
                    .replace('<br>', '<br />'),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TextSpaceTab;
