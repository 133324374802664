import { EmailCredentials } from '@/types/email';

export const EMAIL_CREDENTIALS: Record<string, EmailCredentials> = {
  gmail: {
    providerIMAPHost: 'imap.gmail.com',
    providerIMAPPort: 993,
    providerSMTPHost: 'smtp.gmail.com',
    providerSMTPPort: 587,
    providerInboxName: 'INBOX',
    providerSentBoxName: '[Gmail]/Sent Mail',
  },
  yahoo: {
    providerIMAPHost: 'imap.mail.yahoo.com',
    providerIMAPPort: 993,
    providerSMTPHost: 'smtp.mail.yahoo.com',
    providerSMTPPort: 465,
    providerInboxName: 'Inbox',
    providerSentBoxName: 'Sent',
  },
  outlook: {
    providerIMAPHost: 'outlook.office365.com',
    providerIMAPPort: 993,
    providerSMTPHost: 'smtp-mail.outlook.com',
    providerSMTPPort: 587,
    providerInboxName: 'Inbox',
    providerSentBoxName: 'Sent',
  },
  zoho: {
    providerIMAPHost: 'imap.zoho.com',
    providerIMAPPort: 993,
    providerSMTPHost: 'smtp.zoho.com',
    providerSMTPPort: 465,
    providerInboxName: 'Inbox',
    providerSentBoxName: 'Sent',
  },
};
