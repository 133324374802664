import { inboxCommonStyles } from '@/constants/themes';
import { Button, IconButton, InputAdornment } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

//icons
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type EmailConfigModalProps = {
  setModalOpen: React.Dispatch<
    React.SetStateAction<
      Record<'addEmailModal' | 'inboxConfigModal' | 'imapModal', boolean>
    >
  >;
  inboxConfigModalVisible: boolean;
};

interface IInboxInfo {
  emailId: string;
  password: string;
  meetingLink: string;
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: theme.shadows[1],
  borderRadius: '0.8rem',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const StyledAccordionContent = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingTop: '1rem',
  backgroundColor: theme.palette.background.default,
}));

const HeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const CenterBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  paddingTop: '0.8rem',
});

const DeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
}));

function EmailConfigModal(props: EmailConfigModalProps) {
  const { inboxConfigModalVisible, setModalOpen } = props;

  const [inboxInfo, setInboxInfo] = useState<IInboxInfo[]>([]);
  const [fetchingInboxItems, setFetchingInboxItems] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });
  // const [token, setToken] = useState<string | null>('');
  // const { getToken } = fetchWithRewrites(;

  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  useEffect(() => {
    async function fetchUserEmailInboxInfo() {
      setFetchingInboxItems(true);
      const resData = await fetchWithRewrites('/api/inbox/list', {
        signal: createTimeoutSignal(),
      });
      const jsonRes = await resData.json();
      setInboxInfo(jsonRes.data);
      setFetchingInboxItems(false);
    }

    fetchUserEmailInboxInfo();
  }, []);

  return (
    <Modal
      open={inboxConfigModalVisible}
      onClose={closeModal}
      aria-labelledby="info-config-edit-modal"
      aria-describedby="edit-email-configs"
    >
      <Box sx={inboxCommonStyles.modalBox}>
        <Typography
          variant="h6"
          component="h5"
          sx={{ paddingBottom: '0.5rem' }}
        >
          Inbox Config
        </Typography>
        <Box
          sx={{
            maxHeight: '65vh',
            overflowY: 'auto',
          }}
        >
          {fetchingInboxItems ? (
            <CenterBox>
              <CircularProgress />
            </CenterBox>
          ) : inboxInfo.length === 0 ? (
            <Typography>No associated inboxes found</Typography>
          ) : (
            inboxInfo.map((inboxItem, index) => {
              return (
                <StyledAccordion key={index}>
                  <StyledAccordionSummary>
                    <HeaderBox>
                      <KeyboardArrowDownIcon />
                      <Typography>{inboxItem.emailId}</Typography>
                      <Button
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteInboxEmail(inboxItem.emailId);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </HeaderBox>
                  </StyledAccordionSummary>
                  <StyledAccordionContent>
                    <TextField
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                      value={inboxItem.password}
                      onChange={(e) => {
                        setInboxInfo((prevInboxInfo) =>
                          prevInboxInfo.map((item) =>
                            item.emailId === inboxItem.emailId
                              ? { ...item, password: e.target.value }
                              : item
                          )
                        );
                      }}
                      type={values.showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Meeting Link"
                      variant="outlined"
                      value={inboxItem.meetingLink}
                      onChange={(e) => {
                        setInboxInfo((prevInboxInfo) =>
                          prevInboxInfo.map((item) =>
                            item.emailId === inboxItem.emailId
                              ? { ...item, meetingLink: e.target.value }
                              : item
                          )
                        );
                      }}
                    />
                    {updateLoading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={() => {
                          updateInboxEmail(inboxItem.emailId, inboxItem);
                        }}
                      >
                        Update
                      </Button>
                    )}
                  </StyledAccordionContent>
                </StyledAccordion>
              );
            })
          )}
        </Box>
      </Box>
    </Modal>
  );

  function closeModal() {
    setModalOpen((prev) => ({
      ...prev,
      inboxConfigModal: false,
    }));
  }

  function handleClickShowPassword() {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  }

  async function deleteInboxEmail(inboxEmail: string) {
    const res = await fetchWithRewrites(
      `/api/inbox/delete?email=${inboxEmail}`,
      {
        method: 'DELETE',
        signal: createTimeoutSignal(),
      }
    );

    const jsonRes = await res.json();

    if (jsonRes.response === 'ok') closeModal();
  }

  async function updateInboxEmail(inboxEmail: string, updatedEmailData: any) {
    setUpdateLoading(true);
    const res = await fetchWithRewrites(
      `/api/inbox/update?email=${inboxEmail}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: updatedEmailData.emailId,
          password: updatedEmailData.password,
          meetingLink: updatedEmailData.meetingLink,
        }),
        signal: createTimeoutSignal(),
      }
    );

    const jsonRes = await res.json();
    setUpdateLoading(false);
    if (jsonRes.response === 'ok') closeModal();
  }
}

export default EmailConfigModal;
