import LeadsImportModal from '@/components/Leads/LeadsImportModal';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { useSession } from '@/hooks/useSession';
import {
  ConvertedData,
  Lead,
  RenderMessageProps,
} from '@/types/components/converse/messages';
import { SuccessAILead } from '@/types/leads';
import { prettyPrintObject } from '@/utils/leads';
import { OpenInNew } from '@mui/icons-material';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


function RenderMessage({
  role,
  message,
  json,
  animate,
}: RenderMessageProps): React.ReactNode {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  
  const { alchemyst, setStoreState, snackbarEntries, localLeads } =
    useAlchemystStoreForAi((store) => ({
      alchemyst: store.alchemyst,
      localLeads: store.localLeads,
      setStoreState: store.setStoreState,
      snackbarEntries: store.snackbarEntries,
    }));
  // const user = useSession().session?.user;
  const userData = useSession();

  const { log } = useLogging();
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [leadsImportModalOpen, setLeadsImportModalOpen] = useState(false);
  const [chatEmailModalOpen, setChatEmailModalOpen] = useState(false);
  const [localFields, setLocalFields] = useState<string[]>([]);
  const [isLeadsAvailable, setIsLeadsAvailable] = useState(false);

  const setLocalLeads = (leads: Lead[]) => {
    setStoreState({ localLeads: leads });
  };
  const convertJsonToForm = (jsonInput: string): ConvertedData | null => {
    try {
      const { searchResults = [], query = {} } = JSON.parse(jsonInput);
      // console.log('searchResults:', searchResults);
      if (!searchResults.data.length) return null;
      const fields = Object.keys(searchResults.data[0]);
      const leads = searchResults.data.map((item: Record<string, any>) =>
        fields.reduce(
          (acc: Lead, field) => ({
            ...acc,
            [field]: prettyPrintObject(item[field]),
          }),
          {}
        )
      );
      return { leads, fields, query };
    } catch (error) {
      console.error('Invalid JSON format:', error);
      return null;
    }
  };

  const handleConvertToLeadsPreview = () => {
    const convertedData = convertJsonToForm(json!);
    if (!convertedData) return;

    setIsLeadsAvailable(true);
    const { leads, fields } = convertedData;
    log(
      { message: `${leads.length} Leads generated`, count: leads.length },
      'platform/converse.prospector.leads'
    );
    setLocalLeads(leads);
    setLocalFields(fields);
    if (Object.keys(convertedData.query).includes('searchParams')) {
      setStoreState({ prospectorQuery: convertedData.query as SuccessAILead });
    }
  };

  useEffect(() => {
    if (json) handleConvertToLeadsPreview();
  }, [json]);

  useEffect(() => {
    if (role === 'assistant' && animate) {
      let index = 0;
      const interval = setInterval(() => {
        setDisplayedMessage(
          (prevMessage) => prevMessage + message[index++] || ''
        );
        if (index >= message.length) clearInterval(interval);
      }, 10);
    } else {
      setDisplayedMessage(message);
    }
  }, [animate, message, role]);

  const backgroundColor = () => {
    // Use #FFB04B in dark mode, original colors in light mode 
    if (isDarkMode) {
      return role === 'user' ? theme.palette.primary.main : theme.palette.primary.light;
    } else {
      return role === 'user' ? '#c0c0c0' : '#d9d9d9';
    }
  };

  const textColor = () => isDarkMode ? '#000000' : 'inherit';

  const handleOpenLeadsPreview = () => {
    handleConvertToLeadsPreview();
    setLeadsImportModalOpen(true);
  };
  
  // Prepare markdown component overrides based on current theme
  const markdownComponents = {
    p: ({node, ...props}: any) => <p style={{color: textColor()}} {...props} />,
    h1: ({node, ...props}: any) => <h1 style={{color: textColor()}} {...props} />,
    h2: ({node, ...props}: any) => <h2 style={{color: textColor()}} {...props} />,
    h3: ({node, ...props}: any) => <h3 style={{color: textColor()}} {...props} />,
    h4: ({node, ...props}: any) => <h4 style={{color: textColor()}} {...props} />,
    h5: ({node, ...props}: any) => <h5 style={{color: textColor()}} {...props} />,
    h6: ({node, ...props}: any) => <h6 style={{color: textColor()}} {...props} />,
    li: ({node, ...props}: any) => <li style={{color: textColor()}} {...props} />,
    a: ({node, ...props}: any) => <a style={{color: textColor()}} {...props} />,
    blockquote: ({node, ...props}: any) => (
      <blockquote 
        style={{
          color: textColor(), 
          borderLeft: `4px solid ${textColor() === 'inherit' ? 'currentColor' : textColor()}`
        }} 
        {...props} 
      />
    ),
    code: ({node, ...props}: any) => (
      <code 
        style={{
          color: textColor(),
          background: isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)'
        }} 
        {...props} 
      />
    )
  };
  
  return (
    <Box
      alignItems="flex-start"
      display="flex"
      justifyContent={role === 'user' ? 'flex-end' : 'flex-start'}
      mb={2}
    >
      {role !== 'user' && (
        <Avatar src={alchemyst.avatarUrl} sx={{ mr: 2 }}>
          {alchemyst.name[0]}
        </Avatar>
      )}
      <Box
        borderRadius={3}
        display="flex"
        flexDirection="column"
        maxWidth="60%"
        px={2}
        py={1}
        bgcolor={backgroundColor()}
        boxShadow={2}
      >
        <Typography 
          variant="caption" 
          sx={{
            fontWeight: isDarkMode ? 'bold' : 'normal',
            color: textColor()
          }}
        >
          {role !== 'user' ? alchemyst.name : userData.data?.given_name}
        </Typography>
        <ReactMarkdown 
          remarkPlugins={[remarkGfm]} 
          className="chat-message"
          components={markdownComponents}
        >
          {displayedMessage}
        </ReactMarkdown>
        
        {isLeadsAvailable && (
          <StyledButton
            color="inherit"
            onClick={handleOpenLeadsPreview}
            sx={{ mb: 1, borderRadius: 4, py: 0.5, px: 1 }}
            variant="outlined"
            style={{
              color:'#000000'
            }}
          >
            See leads preview <OpenInNew />
          </StyledButton>
        )}
        {displayedMessage.toLowerCase().includes('subject:') && (
          <StyledButton
            color="inherit"
            onClick={() => setChatEmailModalOpen(true)}
            sx={{ mb: 1, borderRadius: 4, py: 0.5, px: 1 }}
            variant="outlined"
          >
            Send email <OpenInNew />
          </StyledButton>
        )}
      </Box>
      {role === 'user' && (
        <Avatar src={userData.data?.picture || ''} sx={{ ml: 2 }}>
          U
        </Avatar>
      )}
      <LeadsImportModal
        localFields={localFields}
        localLeads={localLeads}
        open={leadsImportModalOpen}
        setOpen={setLeadsImportModalOpen}
      />
    </Box>
  );
}

export default RenderMessage;
