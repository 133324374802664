import { Lead, SuccessAILead } from '@/types/leads';

export const leads: Lead[] = [
  {
    id: 1,
    name: 'John Doe',
    title: 'Software Engineer at Tech Solutions',
    url: 'https://www.linkedin.com/in/john-doe-123456',
    location: 'United States',
    industry: 'Software Development',
    summary: 'Experienced software engineer with a focus on web development.',
    country: 'United States',
  },
  {
    id: 2,
    name: 'Jane Smith',
    title: 'Marketing Manager at Creative Agency',
    url: 'https://www.linkedin.com/in/jane-smith-789012',
    location: 'Canada',
    industry: 'Marketing',
    summary:
      'Passionate marketing professional with expertise in digital marketing.',
    country: 'Canada',
  },
  {
    id: 3,
    name: 'Michael Brown',
    title: 'Product Manager at Innovatech',
    url: 'https://www.linkedin.com/in/michael-brown-345678',
    location: 'Germany',
    industry: 'Technology',
    summary:
      'Skilled product manager with a track record of successful product launches.',
    country: 'Germany',
  },
  {
    id: 4,
    name: 'Emily Davis',
    title: 'Sales Director at SalesCorp',
    url: 'https://www.linkedin.com/in/emily-davis-901234',
    location: 'United Kingdom',
    industry: 'Sales',
    summary: 'Results-driven sales director with over 15 years of experience.',
    country: 'United Kingdom',
  },
  {
    id: 5,
    name: 'Robert Johnson',
    title: 'Financial Analyst at FinancePros',
    url: 'https://www.linkedin.com/in/robert-johnson-567890',
    location: 'Australia',
    industry: 'Finance',
    summary:
      'Financial analyst specializing in investment strategies and risk management.',
    country: 'Australia',
  },
  {
    id: 6,
    name: 'Laura Wilson',
    title: 'HR Manager at PeopleFirst',
    url: 'https://www.linkedin.com/in/laura-wilson-234567',
    location: 'Canada',
    industry: 'Human Resources',
    summary:
      'HR manager with a passion for employee development and engagement.',
    country: 'Canada',
  },
  {
    id: 7,
    name: 'James Anderson',
    title: 'CEO at Anderson Enterprises',
    url: 'https://www.linkedin.com/in/james-anderson-876543',
    location: 'United States',
    industry: 'Business',
    summary: 'Experienced CEO leading a multinational corporation.',
    country: 'United States',
  },
  {
    id: 8,
    name: 'Linda Martinez',
    title: 'Graphic Designer at Design Studio',
    url: 'https://www.linkedin.com/in/linda-martinez-123098',
    location: 'Spain',
    industry: 'Design',
    summary:
      'Creative graphic designer with a passion for branding and visual identity.',
    country: 'Spain',
  },
  {
    id: 9,
    name: 'Daniel Lee',
    title: 'Data Scientist at DataCorp',
    url: 'https://www.linkedin.com/in/daniel-lee-567234',
    location: 'South Korea',
    industry: 'Data Science',
    summary:
      'Data scientist with expertise in machine learning and big data analytics.',
    country: 'South Korea',
  },
  {
    id: 10,
    name: 'Sarah Taylor',
    title: 'Content Writer at WriteIt',
    url: 'https://www.linkedin.com/in/sarah-taylor-987654',
    location: 'United Kingdom',
    industry: 'Content Writing',
    summary: 'Creative content writer with a knack for storytelling.',
    country: 'United Kingdom',
  },
  {
    id: 11,
    name: 'Kevin Harris',
    title: 'Operations Manager at LogisticsPlus',
    url: 'https://www.linkedin.com/in/kevin-harris-234908',
    location: 'United States',
    industry: 'Logistics',
    summary:
      'Efficient operations manager with a focus on supply chain optimization.',
    country: 'United States',
  },
  {
    id: 12,
    name: 'Karen Clark',
    title: 'Recruiter at TalentFinders',
    url: 'https://www.linkedin.com/in/karen-clark-765432',
    location: 'Australia',
    industry: 'Recruitment',
    summary: 'Dedicated recruiter helping companies find top talent.',
    country: 'Australia',
  },
  {
    id: 13,
    name: 'Brian Walker',
    title: 'Consultant at Walker Consulting',
    url: 'https://www.linkedin.com/in/brian-walker-345678',
    location: 'Canada',
    industry: 'Consulting',
    summary:
      'Strategic consultant with a focus on business growth and development.',
    country: 'Canada',
  },
  {
    id: 14,
    name: 'Jessica Scott',
    title: 'UX Designer at UserFirst',
    url: 'https://www.linkedin.com/in/jessica-scott-876543',
    location: 'Germany',
    industry: 'UX Design',
    summary:
      'User experience designer creating intuitive and engaging interfaces.',
    country: 'Germany',
  },
  {
    id: 15,
    name: 'Matthew Evans',
    title: 'Project Manager at ProjectSuccess',
    url: 'https://www.linkedin.com/in/matthew-evans-123456',
    location: 'United States',
    industry: 'Project Management',
    summary:
      'Project manager with expertise in agile methodologies and team leadership.',
    country: 'United States',
  },
  {
    id: 16,
    name: 'Anna White',
    title: 'Business Analyst at BizAnalyze',
    url: 'https://www.linkedin.com/in/anna-white-789012',
    location: 'United Kingdom',
    industry: 'Business Analysis',
    summary:
      'Business analyst specializing in process improvement and data analysis.',
    country: 'United Kingdom',
  },
  {
    id: 17,
    name: 'Christopher Martinez',
    title: 'IT Manager at TechSupport',
    url: 'https://www.linkedin.com/in/christopher-martinez-456789',
    location: 'Canada',
    industry: 'Information Technology',
    summary:
      'IT manager with a focus on network security and infrastructure management.',
    country: 'Canada',
  },
  {
    id: 18,
    name: 'Amanda Roberts',
    title: 'Product Designer at DesignHub',
    url: 'https://www.linkedin.com/in/amanda-roberts-987654',
    location: 'Australia',
    industry: 'Product Design',
    summary:
      'Innovative product designer with a passion for user-centered design.',
    country: 'Australia',
  },
  {
    id: 19,
    name: 'William Johnson',
    title: 'Accountant at FinanceWorks',
    url: 'https://www.linkedin.com/in/william-johnson-123456',
    location: 'United States',
    industry: 'Accounting',
    summary:
      'Experienced accountant with a focus on financial reporting and analysis.',
    country: 'United States',
  },
  {
    id: 20,
    name: 'Patricia Thomas',
    title: 'HR Specialist at PeopleConnect',
    url: 'https://www.linkedin.com/in/patricia-thomas-789012',
    location: 'Canada',
    industry: 'Human Resources',
    summary:
      'HR specialist with a passion for talent acquisition and employee relations.',
    country: 'Canada',
  },
  {
    id: 21,
    name: 'Joshua Lewis',
    title: 'SEO Specialist at WebRank',
    url: 'https://www.linkedin.com/in/joshua-lewis-456789',
    location: 'United Kingdom',
    industry: 'SEO',
    summary:
      'SEO specialist with a focus on increasing organic search visibility.',
    country: 'United Kingdom',
  },
  {
    id: 22,
    name: 'Jennifer Lee',
    title: 'Brand Manager at BrandMasters',
    url: 'https://www.linkedin.com/in/jennifer-lee-987654',
    location: 'Germany',
    industry: 'Marketing',
    summary: 'Brand manager with expertise in brand strategy and management.',
    country: 'Germany',
  },
  {
    id: 23,
    name: 'Richard Green',
    title: 'Software Architect at CodeCraft',
    url: 'https://www.linkedin.com/in/richard-green-123456',
    location: 'United States',
    industry: 'Software Development',
    summary: 'Software architect with a focus on scalable software solutions.',
    country: 'United States',
  },
  {
    id: 24,
    name: 'Elizabeth Wilson',
    title: 'PR Specialist at PublicRelationsInc',
    url: 'https://www.linkedin.com/in/elizabeth-wilson-789012',
    location: 'Canada',
    industry: 'Public Relations',
    summary:
      'PR specialist with a passion for media relations and brand communication.',
    country: 'Canada',
  },
  {
    id: 25,
    name: 'Charles Thompson',
    title: 'Business Development Manager at GrowthCorp',
    url: 'https://www.linkedin.com/in/charles-thompson-456789',
    location: 'Australia',
    industry: 'Business Development',
    summary:
      'Business development manager with a focus on strategic partnerships.',
    country: 'Australia',
  },
  {
    id: 26,
    name: 'Megan Walker',
    title: 'Data Analyst at DataInsights',
    url: 'https://www.linkedin.com/in/megan-walker-987654',
    location: 'United Kingdom',
    industry: 'Data Analysis',
    summary: 'Data analyst with expertise in data visualization and reporting.',
    country: 'United Kingdom',
  },
  {
    id: 27,
    name: 'Mark Hernandez',
    title: 'Sales Manager at SalesExperts',
    url: 'https://www.linkedin.com/in/mark-hernandez-123456',
    location: 'Canada',
    industry: 'Sales',
    summary: 'Sales manager with a proven track record in B2B sales.',
    country: 'Canada',
  },
  {
    id: 28,
    name: 'Emily Robinson',
    title: 'Customer Service Manager at SupportNow',
    url: 'https://www.linkedin.com/in/emily-robinson-789012',
    location: 'United States',
    industry: 'Customer Service',
    summary:
      'Customer service manager dedicated to improving customer satisfaction.',
    country: 'United States',
  },
  {
    id: 29,
    name: 'Andrew Lee',
    title: 'Graphic Designer at ArtWorks',
    url: 'https://www.linkedin.com/in/andrew-lee-456789',
    location: 'Australia',
    industry: 'Design',
    summary:
      'Graphic designer with a passion for creating visually appealing designs.',
    country: 'Australia',
  },
  {
    id: 30,
    name: 'Samantha Martinez',
    title: 'Marketing Analyst at MarketTrends',
    url: 'https://www.linkedin.com/in/samantha-martinez-987654',
    location: 'Germany',
    industry: 'Marketing',
    summary:
      'Marketing analyst with expertise in market research and analysis.',
    country: 'Germany',
  },
];

export const agendaPlaybookList = [
  // {
  //   name: 'Sales-ey Pitch',
  //   description:
  //     'Write an engaging and persuasive email pitch for a product, emphasizing its unique selling points, potential benefits, and a strong call-to-action to encourage immediate purchase. The tone should be confident, assertive, and slightly urgent.',
  // },
  {
    name: 'Personalized & Concise Pitch',
    description:
      'Create a short, personalized email pitch tailored to an individual recipient. Highlight how the product or service can address their specific needs or challenges, using a friendly and approachable tone.',
  },
  {
    name: 'Business Pitch: Strengths and Weaknesses',
    description:
      'Develop a professional email for a business audience that outlines the product’s key strengths and potential weaknesses. Use a balanced, objective tone to provide a realistic assessment, demonstrating honesty and credibility.',
  },
  {
    name: 'Follow-Up Email',
    description:
      'Draft a follow-up email to a potential client after an initial meeting. Summarize key points discussed, reiterate mutual benefits, and include a gentle reminder of next steps or actions expected from the recipient.',
  },
];

export const successAIDefaultSettings: SuccessAILead = {
  searchParams: {
    page: 1,
    per_page: 100,
    annual_revenue: [],
    employee_count: [],
    industries: [],
    titles: [],
    countries: [],
    name: '',
    technologies: [],
    company_domains: [],
    total_funding: [],
    keywords: [],
    states: [],
    cities: [],
  },
};

export const prospectorDefaultSettings = [
  'full_name',
  'industry',
  'job_title',
  'sub_role',
  'industry_2',
  'emails',
  'mobile',
  'phone_numbers',
  'company_name',
  'company_industry',
  'company_website',
  'company_size',
  'company_founded',
  'location',
  'locality',
  'metro',
  'region',
  'skills',
  'first_name',
  'middle_initial',
  'middle_name',
  'last_name',
  'birth_year',
  'birth_date',
  'gender',
  'linkedin_url',
  'linkedin_username',
  'facebook_url',
  'facebook_username',
  'twitter_url',
  'twitter_username',
  'github_url',
  'github_username',
  'company_linkedin_url',
  'company_facebook_url',
  'company_twitter_url',
  'company_location_name',
  'company_location_locality',
  'company_location_metro',
  'company_location_region',
  'company_location_geo',
  'company_location_street_address',
  'company_location_address_line_2',
  'company_location_postal_code',
  'company_location_country',
  'company_location_continent',
  'last_updated',
  'start_date',
  'job_summary',
  'location_country',
  'location_continent',
  'street_address',
  'address_line_2',
  'postal_code',
  'location_geo',
  'linkedin_connections',
  'inferred_salary',
  'years_experience',
  'summary',
  'countries',
  'interests',
];
