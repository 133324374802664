import { Header, StyledContainer } from '@/constants/themes';
import { Box, Tab, Tabs, Typography } from '@mui/material';
// import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useState } from 'react';
import ApiKeys from './ApiKeys';
import BillingComponent from './Billing';

function SettingsPage(): React.ReactNode {
  const [value, setValue] = useState(0);

  return (
    <StyledContainer maxWidth="xl">
      <Header>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'space-between'}
          width={'100%'}
          gap={2}
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              Settings
            </Typography>
            <Typography variant="body1" marginTop={2}>
              Manage your settings here.
            </Typography>
          </Box>
        </Box>
      </Header>
      <Box sx={{ width: '100%', height: '100%' }}>
        <NavTabs setValue={setValue} value={value} />
        <Box sx={{ width: '100%', height: '100%' }}>
          {value === 1 && <BillingComponent />}
          {value === 0 && <ApiKeys />}
        </Box>
      </Box>
    </StyledContainer>
  );
}

export default SettingsPage;

function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 ||
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

export function NavTabs(props: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) {
  const { setValue, value } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (
      event.type === 'click' &&
      samePageLinkNavigation(
        event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
      )
    ) {
      setValue(newValue);
    }
  };

  return (
    <Box sx={{ width: '100%', padding: '1rem 0' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="nav tabs example"
        role="navigation"
      >
        <Tab label="API Keys" />
        <Tab label="Billing" />
      </Tabs>
    </Box>
  );
}
