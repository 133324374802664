import { StyledButton } from '@/constants/themes';
import { Box, Stack, Typography } from '@mui/material';

function PayUp() {
  return (
    <Stack
      spacing={3}
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
      }}
    >
      <Box>
        <Typography variant="h1">Umm...</Typography>
        <Typography variant="h4">You forgetting something?</Typography>
      </Box>
      <Box maxWidth="40%" gap={2} display="flex" flexDirection="column">
        <Typography variant="body1">
          We&apos;re delighted that you are loving our product that you come
          back to it again and again, but it seems like we have a problem{' '}
          {'💰💰💰'}
        </Typography>
        <Typography variant="body1">
          Please pay up and reactivate your account by mailing the{' '}
          <a href="mailto:sales@getalchemystai.com" className="font-bold">
            AlchemystAI Sales Team
          </a>{' '}
          with your payment receipt and account details.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={2}>
        <StyledButton
          // @ts-ignore
          target="_blank"
          // href={`${process.env.REACT_APP_PAYMENTS_URL ?? 'http://localhost:3200'}/pay`}
          href="mailto:sales@getalchemystai.com"
          variant="contained"
          type="button"
          sx={{
            my: 2,
            maxWidth: '10vw',
          }}
        >
          <Typography variant="subtitle1">To Payments</Typography>
        </StyledButton>
        <StyledButton
          // @ts-ignore
          target="_blank"
          href="mailto:sales@getalchemystai.com"
          variant="outlined"
          type="button"
          sx={{
            my: 2,
            maxWidth: '10vw',
          }}
        >
          <Typography variant="subtitle1">Reach Out</Typography>
        </StyledButton>
      </Box>
    </Stack>
  );
}

export default PayUp;
