import { Log } from '@/types/logging';
import { useEffect, useRef } from 'react';

class TempLocalStorage {
  private storage: Record<string, string>;

  public constructor() {
    this.storage = {};
  }

  public getStorage(): Record<string, string> {
    return this.storage;
  }

  public setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  public getItem(key: string): string | null {
    return this.storage.hasOwnProperty(key) ? this.storage[key] : null;
  }

  public removeItem(key: string): void {
    delete this.storage[key];
  }

  public clear(): void {
    this.storage = {};
  }

  public key(index: number): string | null {
    const keys = Object.keys(this.storage);
    return keys[index] || null;
  }

  public get length(): number {
    return Object.keys(this.storage).length;
  }
}

const useLogging = () => {
  const storageRef = useRef<Storage>(new TempLocalStorage()); // Initialize useRef

  useEffect(() => {
    try {
      if (localStorage) {
        // Step 1: Copy all contents to localStorage

        Object.entries(storageRef.current.getStorage()).forEach(
          ([key, value]) => localStorage.setItem(key, value as string)
        );

        storageRef.current = localStorage; // Now point the storage to localStorage
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getLogs = (): Log[] =>
    JSON.parse(storageRef.current.getItem('logs') || '[]');
  const setLogs = (update: (prevLogs: Log[]) => Log[] | Log[]) => {
    if (typeof update === 'function') {
      const currentLogs = JSON.parse(
        storageRef.current.getItem('logs') || '[]'
      );
      const newLogs = update(currentLogs);
      storageRef.current.setItem('logs', JSON.stringify(newLogs));
    } else {
      storageRef.current.setItem('logs', JSON.stringify(update));
    }
  };
  const addLogs = (logs: Log[]) => {
    setLogs((prevState) => [...prevState, ...logs]);
  };
  const log = (
    event: string | (Record<string, any> & { message: string }),
    context: string
  ) => {
    const currentTimeStamp = performance.now();
    const generatedLog: Log = {
      context,
      message: typeof event === 'string' ? event : event.message,
      metadata:
        typeof event === 'string' ? {} : { ...event, message: undefined },
      timestamp: currentTimeStamp,
    };

    addLogs([generatedLog]);
  };

  return { getLogs, setLogs, addLogs, log };
};
export default useLogging;
