import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { inboxCommonStyles } from '../../../constants/themes';
import SelectEmailsToCheck from './SelectEmailsToCheck';
import SelectInboxesToCheck from './SelectInboxesToCheck';

export interface AddEmailModalProps {
  open: boolean;
  onClose: () => void;
}

function AddEmailModal({ open, onClose }: AddEmailModalProps) {
  const { selectedInboxEmails, emailList } = useAlchemystStoreForAi(
    (store) => ({
      selectedInboxEmails: store.inbox.selectedInboxEmails,
      emailList: store.inbox.emailsToCheck,
    })
  );
  const [modalTabIndex, setModalTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setModalTabIndex(newValue);
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="add-emails-modal">
      <Box sx={inboxCommonStyles.modalBox}>
        <Tabs value={modalTabIndex} onChange={handleTabChange}>
          <Tab label="Select Inbox Emails" />
          <Tab label="Add Emails" />
        </Tabs>
        <Box height={'50vh'} overflow="auto" sx={{ p: 2 }}>
          {modalTabIndex === 0 && <SelectInboxesToCheck onClose={onClose} />}
          {modalTabIndex === 1 && <SelectEmailsToCheck onClose={onClose} />}
        </Box>
      </Box>
    </Modal>
  );
}

export default AddEmailModal;
