import { Header, StyledButton } from '@/constants/themes';
import { Done, EditNote } from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Box, IconButton, TextField } from '@mui/material';
import React from 'react';

interface CampaignBuilderHeaderProps {
  titleEditing: {
    name: string;
    description: string;
    isEditingName: boolean;
    isEditingDescription: boolean;
  };
  setTitleEditing: React.Dispatch<
    React.SetStateAction<{
      name: string;
      description: string;
      isEditingName: boolean;
      isEditingDescription: boolean;
    }>
  >;
  handleUpdateCampaign: (key: 'name' | 'description', value: string) => void;
  onSave: () => void;
}

const CampaignBuilderHeader: React.FC<CampaignBuilderHeaderProps> = ({
  titleEditing,
  setTitleEditing,
  handleUpdateCampaign,
  onSave,
}) => {
  const { name, description, isEditingName, isEditingDescription } =
    titleEditing;

  const handleToggleEditing = (
    field: 'isEditingName' | 'isEditingDescription'
  ) => {
    setTitleEditing((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleChange =
    (key: 'name' | 'description') =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleUpdateCampaign(key, e.target.value);
    };

  return (
    <Header gap={10}>
      <Box flex={1} width="100%">
        {/* Name Section */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            InputProps={{ style: { fontSize: '1.5rem' } }}
            disabled={!isEditingName}
            fullWidth
            onChange={handleChange('name')}
            placeholder="Enter Name"
            value={name}
            variant="standard"
          />
          <IconButton onClick={() => handleToggleEditing('isEditingName')}>
            {isEditingName ? <Done /> : <EditNote />}
          </IconButton>
        </Box>

        {/* Description Section */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <TextField
            InputProps={{ style: { fontSize: '1rem' } }}
            disabled={!isEditingDescription}
            fullWidth
            maxRows={4}
            multiline
            onChange={handleChange('description')}
            placeholder="Enter Description"
            size="small"
            value={description}
            variant="standard"
          />
          <IconButton
            onClick={() => handleToggleEditing('isEditingDescription')}
          >
            {isEditingDescription ? <Done /> : <EditNote />}
          </IconButton>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" gap={2}>
        <StyledButton
          size="small"
          startIcon={<AutoFixHighIcon />}
          sx={{
            padding: '10px 20px',
            fontSize: '15px',
            borderRadius: '8px',
          }}
          variant="outlined"
        >
          Enhance Workflow using AI
        </StyledButton>
        <StyledButton
          size="small"
          startIcon={<Done />}
          sx={{
            padding: '10px 20px',
            fontSize: '15px',
            borderRadius: '8px',
          }}
          variant="contained"
          onClick={onSave}
        >
          Save
        </StyledButton>
      </Box>
    </Header>
  );
};

export default CampaignBuilderHeader;
