import { StyledButton } from '@/constants/themes';
import useContextStore from '@/hooks/useContextStore';
import type { ContextNode } from '@/types/context/context-page';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import Delete from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useState } from 'react';

interface NodeTableProps {
  nodes: ContextNode[];
}

function CustomToolbar({
  selectedRows,
  selectedNodes,
  contextNodes,
}: {
  selectedRows: string[];
  selectedNodes: ContextNode[];
  contextNodes: ContextNode[];
}): React.ReactNode {
  const { deletedNodeIds, setStoreState } = useContextStore((store) => ({
    deletedNodeIds: store.deletedNodeIds,
    setStoreState: store.setStoreState,
  }));
  const handleDelete = async () => {
    if (selectedRows.length === 0) return;

    try {
      await Promise.all(
        selectedRows.map(async (id) => {
          let backendUrl =
            process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:3001';
          backendUrl = backendUrl.endsWith('/')
            ? backendUrl.slice(0, -1)
            : backendUrl;

          const deleteUrl = new URL(`${backendUrl}/api/context/delete`);

          const res = await fetchWithRewrites(deleteUrl, {
            method: 'POST',
            body: JSON.stringify({
              source: id.toString(),
              by_doc: false,
              by_id: true,
            }),
          });

          if (res.ok) {
            return res.json();
          }
          console.log('Failed to delete node:', res.statusText);

          setStoreState({ deletedNodeIds: deletedNodeIds.add(id) });
        })
      );

      // Update the store by filtering out deleted nodes
      setStoreState({
        contextNodes: contextNodes?.filter(
          (node: ContextNode) => !selectedRows.includes(node.id)
        ),
        selectedNodes: selectedNodes?.filter(
          (node: ContextNode) => !selectedRows.includes(node.id)
        ),
      });
    } catch (error) {
      console.error('Failed to delete nodes:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <GridToolbar />
      <StyledButton
        color="error"
        disabled={selectedRows.length === 0}
        onClick={handleDelete}
        startIcon={<Delete />}
        sx={{ mt: 0.5, p: 0.4 }}
        variant="text"
      >
        Delete
      </StyledButton>
    </Box>
  );
}

function NodeOperator({ nodes }: NodeTableProps) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { setStoreState, selectedNodes, contextNodes } = useContextStore(
    (store) => ({
      setStoreState: store.setStoreState,
      contextNodes: store.contextNodes,
      selectedNodes: store.selectedNodes,
    })
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 150 },
    { field: 'content', headerName: 'Content', width: 300 },
  ];

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGridPro
        autoPageSize
        autosizeOnMount
        checkboxSelection
        columns={columns}
        getRowId={(row) => row.id}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection as string[]);
        }}
        pagination
        rowSelection
        rows={nodes}
        slots={{
          toolbar: () => (
            <CustomToolbar
              contextNodes={contextNodes}
              selectedNodes={selectedNodes}
              selectedRows={selectedRows}
            />
          ),
        }}
      />
    </Box>
  );
}

export default NodeOperator;
