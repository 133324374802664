export const extractEmails = (data: Record<string, any>): string[] => {
  const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
  const emails: string[] = [];

  const findEmailsInValue = (value: any) => {
    if (typeof value === 'string' && emailRegex.test(value)) {
      emails.push(value);
    } else if (Array.isArray(value)) {
      value.forEach((item) => findEmailsInValue(item));
    }
  };

  Object.values(data).forEach((value) => findEmailsInValue(value));
  return emails;
};
