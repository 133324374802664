export const tunerOptions = [
  {
    name: 'Prospector',
    keyword: '\n\nuse prospector.',
  },
  {
    name: 'Web Search',
    keyword: '\n\nuse web search.',
  },
  {
    name: 'Organization Context',
    keyword: '\n\nuse context.',
  },
];
