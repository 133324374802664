import { Field } from '@/types/campaign/field';

export const generateFieldData = (type: string): Field | {} => {
  switch (type) {
    case 'input.select':
      return {
        field_type: 'input.select',
        name: 'Dropdown Selector',
        control_var: `dropdown_${crypto.randomUUID()}`,
        placeholder: 'Select an option',
        options: [],
        large_options: false,
        multiselect: false,
      };
    case 'min_max_range':
      return {
        field_type: 'min_max_range',
        name: 'Min-Max Range',
        control_var: `range_${crypto.randomUUID()}`,
        max_placeholder: 'Max',
        min_placeholder: 'Min',
      };
    case 'input.textarea':
      return {
        name: 'Text Area Input',
        field_type: 'input.textarea',
        control_var: `textarea_${crypto.randomUUID()}`,
        placeholder: 'Enter your text here',
        multiple: true,
      };
    case 'list_of_features':
      return {
        control_var: `features_${crypto.randomUUID()}`,
        field_type: 'list_of_features',
        name: 'Features and Benefits',
        painpoints_and_solutions: [],
      };
    case 'list_of_text_inputs':
      return {
        control_var: `text_inputs_${crypto.randomUUID()}`,
        field_type: 'list_of_text_inputs',
        name: 'Supporting Evidence List',
        elements: [],
      };
    case 'input.switch':
      return {
        control_var: `switch_${crypto.randomUUID()}`,
        field_type: 'input.switch',
        name: 'Toggle Switch',
      };
    default:
      console.error('Unknown field type');
      return {};
  }
};
