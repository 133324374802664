import { CampaignLead } from '@/types/campaign';

/**
 * Extracts all unique field names from an array of leads
 * @param leads Array of leads to extract fields from
 * @returns Array of unique field names sorted alphabetically
 */
export const extractFields = (leads: Record<string, any>[]): string[] => {
  if (!leads || leads.length === 0) {
    return [];
  }

  // Collect all unique field names from all leads
  const fieldSet = new Set<string>();

  // Process all leads to get a complete set of fields
  leads.forEach((lead) => {
    Object.keys(lead).forEach((key) => {
      // Skip metadata fields that start with underscore and required CampaignLead fields
      if (!key.startsWith('_') && !['id', 'status', 'progress'].includes(key)) {
        fieldSet.add(key);
      }
    });
  });

  // Convert to array and sort alphabetically for consistency
  return Array.from(fieldSet).sort();
};

/**
 * Normalizes an array of leads to ensure all leads have all fields
 * @param leads Array of leads to normalize
 * @returns Normalized array of leads with consistent fields
 */
export const normalizeLeads = (
  leads: Record<string, any>[]
): Record<string, any>[] => {
  if (!leads || leads.length === 0) return [];

  // Collect all field names from all leads
  const fieldSet = new Set<string>();
  leads.forEach((lead) => {
    Object.keys(lead).forEach((key) => {
      // Skip metadata fields that start with underscore and system fields
      if (!key.startsWith('_') && !['id', 'status', 'progress'].includes(key)) {
        fieldSet.add(key);
      }
    });
  });

  // Convert to array
  const allFields = Array.from(fieldSet);

  // Create normalized leads with consistent fields
  return leads.map((lead) => {
    const normalizedLead: Record<string, any> = {
      // Preserve existing system fields if they exist
      ...(lead.status !== undefined ? { status: lead.status } : {}),
      ...(lead.progress !== undefined ? { progress: lead.progress } : {}),
    };

    // Ensure all fields exist (with empty values if not present)
    allFields.forEach((field) => {
      normalizedLead[field] = lead[field] !== undefined ? lead[field] : '';
    });

    // Add any metadata fields (those starting with underscore)
    Object.keys(lead).forEach((key) => {
      if (key.startsWith('_')) {
        normalizedLead[key] = lead[key];
      }
    });

    return normalizedLead;
  });
};

/**
 * Normalizes campaign leads to ensure all leads have all fields
 * @param leads Array of campaign leads to normalize
 * @returns Normalized array of campaign leads with consistent fields
 */
export const normalizeCampaignLeads = (
  leads: CampaignLead[]
): CampaignLead[] => {
  if (!leads || leads.length === 0) return [];

  const allFields = extractFields(leads);

  return leads.map((lead) => {
    const normalizedLead = { ...lead };

    // Ensure all fields exist in each lead
    allFields.forEach((field) => {
      if (normalizedLead[field] === undefined) {
        normalizedLead[field] = '';
      }
    });

    return normalizedLead as CampaignLead;
  });
};
