import { successAIDefaultSettings } from '@/constants/leads';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import type { SuccessAILead } from '@/types/leads';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { prettyPrintObject } from '@/utils/leads';
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';

function LeadsFilterSettings({ table }: { table?: boolean }): React.ReactNode {
  const [fetchingLeads, setFetchingLeads] = useState<boolean>(false);
  const { localLeads, setStoreState, prospectorQuery, snackbarEntries } =
    useAlchemystStoreForAi((store) => ({
      localLeads: store.localLeads,
      setStoreState: store.setStoreState,
      prospectorQuery: store.prospectorQuery,
      snackbarEntries: store.snackbarEntries,
    }));

  console.log('Prospector Query: ', prospectorQuery);
  if (!prospectorQuery) {
    return;
  }
  const [localProspectorQueryState, setLocalProspectorQueryState] =
    useState<SuccessAILead>({
      searchParams: {
        ...successAIDefaultSettings.searchParams,
        ...prospectorQuery.searchParams,
      },
    });

  const prospectorQueryChanged = (): boolean => {
    return (
      JSON.stringify(prospectorQuery) !==
      JSON.stringify(localProspectorQueryState)
    );
  };

  useEffect(() => {
    console.log('Number of leads now = ', localLeads.length);
  });

  const [moreLeadsCount, setMoreLeadsCount] = useState(0);

  const setLocalProspectorQuery = (prospectorQ: SuccessAILead): void => {
    setLocalProspectorQueryState(prospectorQ);
  };

  const handleFetchMoreLeads = async (count: number): Promise<void> => {
    // fetch more leads here
    let offset = 100;
    if (prospectorQueryChanged()) {
      console.log('Prospector Query changed!');
      // NOTE: So the idea is, we first fetch the first 100 of the leads in the leads preview. Now if the user changes the filters, we need to fetch the leads again from the start, else we continue from 101st lead entry.
      // offset = 0;
    }
    const requestBody = {
      count,
      offset,
      filters: localProspectorQueryState,
    };

    setFetchingLeads(true);
    const leadsResponse = await fetchWithRewrites('/api/leads/fetch', {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });

    if (leadsResponse.ok) {
      const leadsData: { data: SuccessAILead[]; statusText: string } =
        await leadsResponse.json();
      if (leadsData.data.length === 0) {
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              message: 'No more leads to fetch',
              notificationId: crypto.randomUUID(),
              severity: 'info',
              type: 'alert',
              // autoHideDuration: 5000,
              // variant: 'determinate',
            },
          ],
        });
      }
      setStoreState({ localLeads: [...localLeads, ...(leadsData.data ?? [])] });
    }
    setFetchingLeads(false);
  };

  return (
    <Stack spacing={3}>
      <Stack spacing={2}>
        <Typography>Filter Settings</Typography>
        <Box>
          {prospectorQuery
            ? Object.keys(localProspectorQueryState.searchParams)
                .filter((key) => !['page', 'per_page'].includes(key))
                .map((key) => (
                  <TextField
                    key={key}
                    label={(key[0].toUpperCase() + key.slice(1))
                      .split('_')
                      .join(' ')}
                    onChange={(e) => {
                      setLocalProspectorQuery({
                        searchParams: {
                          ...localProspectorQueryState.searchParams,
                          [key]: Array.isArray(
                            localProspectorQueryState.searchParams[
                              key as keyof typeof localProspectorQueryState.searchParams
                            ]
                          )
                            ? e.target.value.split(',')
                            : e.target.value,
                        },
                      });
                    }}
                    value={
                      Array.isArray(
                        localProspectorQueryState.searchParams[
                          key as keyof typeof localProspectorQueryState.searchParams
                        ]
                      )
                        ? ((localProspectorQueryState.searchParams[
                            key as keyof typeof localProspectorQueryState.searchParams
                          ] as string[] | undefined) ?? [].join(','))
                        : (localProspectorQueryState.searchParams[
                            key as keyof typeof localProspectorQueryState.searchParams
                          ] ?? [])
                    }
                  />
                ))
            : null}
        </Box>
      </Stack>
      <Box alignItems="center" display="flex" gap={2}>
        <Typography>Add more</Typography>
        <TextField
          onChange={(e) => {
            setMoreLeadsCount(parseInt(e.target.value));
          }}
          type="number"
          value={moreLeadsCount}
        />
        <Typography>leads</Typography>
        <StyledButton
          disabled={fetchingLeads}
          onClick={async () => {
            await handleFetchMoreLeads(moreLeadsCount);
          }}
          variant="contained"
        >
          {fetchingLeads ? 'Fetching Leads...' : 'Add Leads'}
        </StyledButton>
      </Box>
      {!!table && !fetchingLeads && localLeads.length > 0 ? (
        <Box
          sx={{
            scroll: 'auto',
            alignContent: 'center',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <DataGridPro
            // autoPageSize
            // autosizeOnMount
            columns={Object.keys(localLeads[0] ?? {}).map((key) => ({
              id: key,
              field: key,
              headerName: key,
              width: 150,
            }))}
            getRowId={(row) => {
              return row.id ?? String(Math.random());
            }}
            pageSizeOptions={[25, 50, 100]}
            pagination
            rows={localLeads.map((item: Record<string, any>) =>
              Object.keys(localLeads[0] ?? {}).reduce(
                (acc: Record<string, any>, field) => ({
                  ...acc,
                  [field]: prettyPrintObject(item[field]),
                }),
                {}
              )
            )}
            // rowSelection
            // checkboxSelection
            slots={{ toolbar: GridToolbar }}
            sx={{
              width: '100%',
              height: '60vh',
              scrollBehavior: 'smooth',
              scroll: 'auto',
            }}
          />
        </Box>
      ) : null}
      {!!table && fetchingLeads ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}
    </Stack>
  );
}

export default LeadsFilterSettings;
