import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

function ActionSelectorBox({
  actions,
  allActions,
  setActions,
  stepConfigIdx,
}: {
  actions: string[];
  allActions: { label: string; enabled: boolean }[];
  setActions: (actions: string | string[], idx?: number) => void;
  stepConfigIdx?: number;
}) {
  // console.log('Selected actions=', actions);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const selectedValues =
      typeof value === 'string' ? value.split(',') : value || [];
    console.log('Selected Values = ');
    console.log(selectedValues);
    setActions(selectedValues, stepConfigIdx);
  };

  return (
    <Select multiple value={actions} onChange={handleChange}>
      {allActions.map((action, idx) => (
        <MenuItem
          value={action.label}
          disabled={!action.enabled}
          key={`action-${idx + 1}-${action.label}`}
          // disabled={action.toLowerCase() !== 'email'}
        >
          {action.label.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ActionSelectorBox;
