import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import ReplyActionBar from './ReplyActionBar';

function ViewConversationThread({
  threadId,
  emails,
}: {
  threadId: string;
  emails: Record<string, any>[];
}): React.ReactNode {
  return (
    <Stack maxHeight="80vh" overflow="auto">
      {/* <Box>Inbox: {inbox}</Box> */}
      <Stack
        height={'65vh'}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {emails.map((email, idx) => (
          <Box key={`email-${idx + 1}`} my={2} mt={6}>
            <Divider />
            <Card
              sx={{
                px: 3,
                py: 2,
              }}
            >
              <Typography variant="h4">{email.subject}</Typography>
              <CardContent>
                <div dangerouslySetInnerHTML={{ __html: email.body }} />
              </CardContent>
            </Card>
          </Box>
        ))}
      </Stack>
      <Box bottom={0} right={0} width="100%">
        <ReplyActionBar emailsInThread={emails} threadId={threadId} />
      </Box>
    </Stack>
  );
}

export default ViewConversationThread;
