import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { LangChainJSON } from '@/types/ai/messages';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

/**
 * Returns an object containing the translated text information, capturing the app's key features and user experience.
 * Provides essential information about the app's functionality, including AI-driven language translation.
 *
 * @param {Object} headlessScraperQuery An object containing the user's prompt and the URL of the webpage to scrape.
 * @returns {Object} An object containing theTranslated text information.
 */
const getHeadlessScrapingResults = async (headlessScraperQuery: {
  userPrompt: string;
  pageUrl: string;
}) => {
  // const response = await fetchWithRewrites( '/api/leads/augment', {
  //   method: 'POST',
  //   body: JSON.stringify(headlessScraperQuery),
  //   signal: createTimeoutSignal(),
  // });
  // const res_json = await response.json();

  let requestFinished = false;
  let startTime = performance.now();

  let resp: LangChainJSON = {
    lc: 0,
    id: ['AIMessage'],
    type: 'assistant',
    lc_kwargs: {
      additional_kwargs: {},
      content: "Couldn't get answer from API.",
      response_metadata: {},
    },
  };

  while (!(requestFinished || performance.now() - startTime > 600_000)) {
    try {
      const response = await fetchWithRewrites('/api/leads/augment', {
        method: 'POST',
        body: JSON.stringify(headlessScraperQuery),
      });
      const res_json = await response.json();

      if (response.status === 201) {
        resp = res_json;
        requestFinished = true;
      }

      console.log('Input going to the api = ', headlessScraperQuery);
      console.log('Resulting JSON = ', resp);
      return resp;
    } catch (error) {}
  }
};

/**
 * Retrieves headless scraping results by querying the headless scraper
 * with the provided user prompt and page URL.
 *
 * @param {HeadlessScraperQuery} query - An object containing the user prompt and page URL.
 * @returns {LangChainJSON|null} The results of the headless scraping operation, or null if an error occurs.
 */
const getBatchedHeadlessScrapingResults = async (
  headlessScraperQueries: { userPrompt: string; pageUrl: string }[],
  statusUpdateFunction: (status: 'working' | 'open' | 'closed') => any
) => {
  statusUpdateFunction('working');
  const results = await Promise.all(
    headlessScraperQueries.map(async (headlessScraperQuery) =>
      getHeadlessScrapingResults(headlessScraperQuery)
    )
  );
  statusUpdateFunction('open');

  return results.map((result, idx) => ({
    result,
    query: headlessScraperQueries[idx],
  }));
};

const checkIfObjectIsPresentInArray = (
  object: Record<string, any>,
  objectsArray: Record<string, any>[],
  exclude: string[]
) => {
  return objectsArray.some((objectEntry) => {
    let equalityStatus = true;

    Object.keys(objectEntry)
      .filter((key) => !exclude.includes(key))
      .forEach((fieldKey) => {
        equalityStatus =
          equalityStatus && objectEntry[fieldKey] === object[fieldKey];
      });

    return equalityStatus;
  });
};

function BatchScraper(): React.ReactNode {
  const [fieldNames, setFieldNames] = useState<string[]>([]);
  const [sourceField, setSourceField] = useState<string | null>(null);
  const [targetField, setTargetField] = useState<string | null>(null);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const { setStoreState, leads, selectedLeads } = useAlchemystStoreForAi(
    (store) => ({
      leads: store.leads,
      selectedLeads: store.selectedLeads,
      setStoreState: store.setStoreState,
    })
  );

  const [workStatus, setWorkStatus] = useState<
    'open' | 'closed' | 'working' | 'saved'
  >('open');

  const [userPrompt, setUserPrompt] = useState<string | null>(null);

  useEffect(() => {
    setFieldNames(Object.keys(leads[0] ?? {}));
  }, [leads]);

  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleFetchAugmentorData = async () => {
    try {
      setWorkStatus('working');
      const currentResults = await getBatchedHeadlessScrapingResults(
        selectedLeads
          .filter((lead) => isValidUrl(lead[sourceField ?? fieldNames[0]])) // Check if the URL is valid or not.
          .map((lead) => ({
            pageUrl: lead[sourceField ?? fieldNames[0]] ?? '',
            userPrompt: userPrompt ?? 'No user prompt',
          })),
        setWorkStatus
      );
      console.log('Received data = ', JSON.stringify(currentResults));
      if (currentResults.length === 0) {
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              severity: 'error',
              message: "Couldn't get a response from Augmentor API.",
              type: 'alert',
              notificationId: crypto.randomUUID(),
            },
          ],
        });
        return;
      }
      let leadsModified: typeof leads = leads.map((lead, idx) => {
        console.log('Company name = ', lead['Company Name']);
        if (
          checkIfObjectIsPresentInArray(lead, selectedLeads, [
            targetField ?? 'New column',
          ])
        ) {
          console.log(currentResults[idx].result);
          return {
            ...lead,
            [targetField ?? 'New column']: (
              currentResults[idx].result ?? {
                lc_kwargs: { content: 'No answer found.' },
              }
            ).lc_kwargs.content.toString(),
          };
        } else {
          return {
            ...lead,
            [targetField ?? 'New column']:
              lead[targetField ?? 'New column'] ?? '',
          };
        }
      });
      console.log(
        'Augmented lead column data = ',
        leadsModified[0][targetField ?? 'New column']
      );
      setStoreState({ leads: leadsModified });
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'success',
            message: 'Bulk Data Augmentation complete.',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    } catch (error) {
      console.log('Error: ', (error as Error).message);
    } finally {
      setWorkStatus('open');
    }
  };

  const handleChangeUserPrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPrompt(e.target.value);
  };

  return selectedLeads.length > 0 ? (
    <Stack padding={2} spacing={3} width={'100%'}>
      <FormControl fullWidth>
        <InputLabel id="select-reference-field">Reference Field</InputLabel>
        <Select
          labelId="select-reference-field"
          id="reference-field"
          value={sourceField ?? fieldNames[0]}
          label="Reference field"
          onChange={(e) => setSourceField(e.target.value.toString())}
        >
          {fieldNames.map((fieldName, idx) => (
            <MenuItem key={`menu-item-${idx + 1}`} value={fieldName}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="select-target-field">Target Field</InputLabel>
        <Select
          labelId="select-target-field"
          id="target-field"
          value={targetField ?? fieldNames[0]}
          label="Target field"
          onChange={(e) => setTargetField(e.target.value.toString())}
        >
          {fieldNames.map((fieldName) => (
            <MenuItem value={fieldName}>{fieldName}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Query"
        value={userPrompt ?? ''}
        multiline
        minRows={5}
        maxRows={6}
        onChange={handleChangeUserPrompt}
        placeholder="Enter what you want from the data."
      />
      <StyledButton
        variant="contained"
        onClick={handleFetchAugmentorData}
        disabled={!userPrompt || ['saved', 'working'].includes(workStatus)}
        endIcon={
          ['saved', 'working'].includes(workStatus) && (
            <CircularProgress size={'1vh'} />
          )
        }
      >
        {!['saved', 'working'].includes(workStatus) ? 'Augment' : 'working'}
      </StyledButton>
    </Stack>
  ) : (
    <Typography variant="body1">
      Please select atleast one lead to start
    </Typography>
  );
}

export default BatchScraper;
