import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  useTheme,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { MessagesDisplayProps, MessageType } from './types';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import RenderMessage from './messages/RenderMessage';
import { Message } from '@/types/converse/message';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';

const MessagesDisplay: React.FC<MessagesDisplayProps> = ({
  messages: initialMessages,
  selectedChatId,
}) => {
  const theme = useTheme();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { chatChannel } = useAlchemystStoreForAi((store) => ({
    chatChannel: store.chatChannel,
  }));

  // Format timestamp to a readable format
  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Format date to a readable format
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  // Fetch messages when selectedChatId changes
  useEffect(() => {
    const fetchMessages = async () => {
      if (!selectedChatId) {
        setMessages([]);
        return;
      }

      setIsLoading(true);
      try {
        // Fetch the messages from the API
        const messagesResponse = await fetchWithRewrites(
          `/api/chat/fetch/${selectedChatId}`,
          {
            method: 'GET',
            signal: createTimeoutSignal(),
          }
        );
        const messagesJson: { messages: Message[] } =
          await messagesResponse.json();
        setMessages(messagesJson.messages);
      } catch (error) {
        console.error('Failed to fetch messages:', error);
        // If there's an error, convert initialMessages to the Message format as fallback
        if (initialMessages && initialMessages.length > 0) {
          const convertedMessages: Message[] = initialMessages.map((msg) => ({
            id: msg.id,
            role: msg.sender === 'outgoing' ? 'user' : 'assistant',
            message: msg.text,
            json: '',
          }));
          setMessages(convertedMessages);
        } else {
          setMessages([]);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchMessages();
  }, [selectedChatId, initialMessages]);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Handle export of single transcript as a .txt file
  const handleExportTranscript = async () => {
    if (!selectedChatId || messages.length === 0) return;

    try {
      // Create a formatted text string with the transcript content
      let exportContent = `Transcript ID: ${selectedChatId}\n`;
      exportContent += `Date: ${formatDate(new Date().toISOString())}\n`;
      exportContent += `Platform: ${chatChannel}\n\n`;

      // Add messages
      messages.forEach((message) => {
        const sender = message.role === 'user' ? 'You' : 'Assistant';
        exportContent += `${sender}: ${message.message}\n\n`;
      });

      // Create a blob and trigger download
      const blob = new Blob([exportContent], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `conversation_${selectedChatId}.txt`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export transcript:', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        bgcolor: 'background.paper',
        borderRadius: 2,
        overflow: 'hidden',
      }}
    >
      {/* Messages Header */}
      <Box
        sx={{
          p: 2,
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'background.default',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Conversations</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {isLoading && <CircularProgress size={24} />}
          <Tooltip title="Export conversation as text file">
            <span>
              <IconButton
                onClick={handleExportTranscript}
                disabled={!selectedChatId || messages.length === 0}
                color="primary"
                size="small"
                sx={{
                  backgroundColor: 'rgba(255, 154, 53, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 154, 53, 0.2)',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    color: 'rgba(0, 0, 0, 0.3)',
                  },
                }}
              >
                <FileDownloadOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      {/* Messages Container */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : messages.length > 0 ? (
          <Box width="100%">
            {messages.map((message) => (
              <Box key={message.id} width="100%">
                <RenderMessage
                  animate={false}
                  id={message.id}
                  json={message.json}
                  message={message.message}
                  role={message.role}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {selectedChatId
                ? 'No messages to display for this conversation'
                : 'Select a conversation from the sidebar to view messages'}
            </Typography>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>
    </Box>
  );
};

export default MessagesDisplay;
