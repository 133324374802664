import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { mapFieldsForAllRecords } from '@/utils/leads';
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

const ORIGINAL_FIELD_NAME_WIDTH = '20%';
const EDITED_FIELD_NAME_WIDTH = '20%';

function LeadMapSettings({
  setNonce,
}: {
  setNonce?: (nonce: number) => void;
} = {}): React.ReactNode {
  const [currentlyEditingField, setCurrentlyEditingField] = useState<{
    key: keyof (typeof currentLeads)[0];
    value: string;
  } | null>(null);
  const { leads, leadsMapSettings, setStoreState, leadSets, activeLeadSetId } =
    useAlchemystStoreForAi((store) => ({
      leads: store.leads,
      leadsMapSettings: store.leadsMapSettings,
      setStoreState: store.setStoreState,
      leadSets: store.leadSets,
      activeLeadSetId: store.activeLeadSetId,
    }));
  const [localLeadsMapSettings, setLocalLeadsMapSettings] =
    useState<Record<string, string>>(leadsMapSettings);

  // Get the current leads based on active lead set
  const currentLeads =
    activeLeadSetId && leadSets[activeLeadSetId]
      ? leadSets[activeLeadSetId].leads
      : leads;

  useEffect(() => {
    // Skip the effect if there are no leads to map
    if (!currentLeads.length) return;

    // Only run the mapping if leadsMapSettings actually changed
    const mappedLeads = mapFieldsForAllRecords(currentLeads, leadsMapSettings);

    // Check if the mapping actually changed anything before updating the store
    const currentLeadsStr = JSON.stringify(currentLeads);
    const mappedLeadsStr = JSON.stringify(mappedLeads);
    if (currentLeadsStr === mappedLeadsStr) return;

    if (activeLeadSetId && leadSets[activeLeadSetId]) {
      // Update the lead set
      setStoreState({
        leadSets: {
          ...leadSets,
          [activeLeadSetId]: {
            ...leadSets[activeLeadSetId],
            leads: mappedLeads,
            fields: Object.keys(mappedLeads[0] ?? {}),
          },
        },
        // Also update legacy fields for backward compatibility
        leads: mappedLeads,
        fields: Object.keys(mappedLeads[0] ?? {}),
      });
    } else {
      // Legacy behavior
      setStoreState({
        leads: mappedLeads,
        fields: Object.keys(mappedLeads[0] ?? {}),
      });
    }
  }, [
    leadsMapSettings,
    currentLeads,
    activeLeadSetId,
    leadSets,
    setStoreState,
  ]);

  // useEffect(() => {
  //   console.log('Local Leads Map Settings: ');
  //   console.log(localLeadsMapSettings);
  // }, [localLeadsMapSettings]);

  const handleLeadsMapSettingsChange = (): void => {
    setStoreState({ leadsMapSettings: localLeadsMapSettings });
    if (setNonce) {
      setNonce(Math.random());
    }
    // setLocalLeadsMapSettings({});
  };

  const getAvailableOptionsForLeadsMapping = (): string[] => {
    const leadsOptionsOriginally = Object.keys(currentLeads[0] ?? {});
    const leadsOptionsMapped = Object.keys(localLeadsMapSettings);
    return leadsOptionsOriginally.filter(
      (option) => !leadsOptionsMapped.includes(option)
    );
  };

  const checkIfLocalSettingsIsInSyncWithGlobalSettings = (): boolean => {
    return (
      JSON.stringify(localLeadsMapSettings) === JSON.stringify(leadsMapSettings)
    );
  };

  return (
    <Box>
      <Box>
        <Stack direction="column" mb={2} spacing={2}>
          {Object.entries(localLeadsMapSettings).map(([key, value]) => (
            <Stack direction="row" key={`field-${key}`} spacing={2}>
              <Typography
                alignSelf="baseline"
                sx={{ minWidth: ORIGINAL_FIELD_NAME_WIDTH }}
              >
                {key}
              </Typography>
              <TextField
                label="Field Name"
                onChange={(e) => {
                  setLocalLeadsMapSettings({
                    [key]: e.target.value,
                  });
                }}
                sx={{ minWidth: EDITED_FIELD_NAME_WIDTH }}
                value={localLeadsMapSettings[key] ?? ''}
              />
              <StyledButton
                onClick={() => {
                  setLocalLeadsMapSettings((prevState) => {
                    const { [key]: removed, ...rest } = prevState;
                    return rest;
                  });
                }}
              >
                Remove
              </StyledButton>
            </Stack>
          ))}
        </Stack>
        <Stack direction="row" key="new-field" spacing={2}>
          <Select
            onChange={(e) => {
              setCurrentlyEditingField({
                key: e.target.value,
                value: '',
              });
            }}
            sx={{
              minWidth: ORIGINAL_FIELD_NAME_WIDTH,
            }}
            value={currentlyEditingField?.key}
          >
            {getAvailableOptionsForLeadsMapping().map(
              (option, leadsMappingIdx) => (
                <MenuItem
                  key={`option-${option}.${leadsMappingIdx + 1}`}
                  value={option}
                >
                  {option}
                </MenuItem>
              )
            )}
          </Select>
          <TextField
            label="Field Name"
            onChange={(e) => {
              setCurrentlyEditingField({
                key: currentlyEditingField?.key ?? '',
                value: e.target.value,
              });
            }}
            sx={{
              minWidth: EDITED_FIELD_NAME_WIDTH,
            }}
            value={currentlyEditingField?.value ?? ''}
          />
          <StyledButton
            onClick={() => {
              setLocalLeadsMapSettings((prevState) => ({
                ...prevState,
                [currentlyEditingField?.key ?? '']:
                  currentlyEditingField?.value ?? '',
              }));
              setCurrentlyEditingField(null);
            }}
          >
            Add
          </StyledButton>
        </Stack>
      </Box>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <StyledButton
          disabled={checkIfLocalSettingsIsInSyncWithGlobalSettings()}
          onClick={handleLeadsMapSettingsChange}
        >
          Apply
        </StyledButton>
      </Box>
    </Box>
  );
}

export default LeadMapSettings;
