import { countryListWithCode } from '@/constants/countries';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CampaignWorkflowTemplate } from '@/types/campaign';
import { ArrowBack, ArrowRight } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

interface StartWithTemplateFormProps {
  name: string;
  campaignDescription: string;
  locations: {
    name: string;
    code: string;
  }[];
  creatingCampaign: boolean;
  campaignName: string;
  tags: string[];
  setCampaignName: React.Dispatch<React.SetStateAction<string>>;
  setCampaignDescription: React.Dispatch<React.SetStateAction<string>>;
  setLocations: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        code: string;
      }[]
    >
  >;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<CampaignWorkflowTemplate | null>
  >;
  onCreateCampaign: () => void;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
}

const StartWithTemplateForm: React.FC<StartWithTemplateFormProps> = ({
  name,
  campaignDescription,
  locations,
  campaignName,
  tags,
  setTags,
  setCampaignName,
  setCampaignDescription,
  setLocations,
  setSelectedTemplate,
  onCreateCampaign,
  creatingCampaign,
}) => {
  const selectedLeads = useAlchemystStoreForAi((state) => state.selectedLeads);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: '100%',
        height: '100%',
        padding: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <IconButton
          sx={{
            padding: '10px',
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            backgroundColor: 'action.selected',
          }}
          onClick={() => setSelectedTemplate(null)}
        >
          <ArrowBack />
        </IconButton>

        <Typography variant="h5">{name}</Typography>
      </Box>
      <TextField
        variant="outlined"
        fullWidth
        size="medium"
        label="Campaign Name"
        placeholder="Campaign Name"
        value={campaignName}
        autoFocus
        onChange={(e) => setCampaignName(e.target.value)}
        sx={{
          backgroundColor: 'background.default',
        }}
      />
      <TextField
        variant="outlined"
        fullWidth
        size="medium"
        label="Campaign Description"
        placeholder="Campaign Description"
        value={campaignDescription}
        onChange={(e) => setCampaignDescription(e.target.value)}
        sx={{
          backgroundColor: 'background.default',
        }}
      />
      <Autocomplete
        multiple
        value={locations}
        onChange={(_, selectedOptions) => setLocations(selectedOptions)}
        options={countryListWithCode}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, selectedValue) =>
          option.code === selectedValue.code
        }
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              variant="outlined"
              color="primary"
              label={option.name}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Locations"
            placeholder="Select Locations"
            fullWidth
            margin="normal"
          />
        )}
      />
      <Autocomplete
        multiple
        freeSolo
        value={tags}
        onChange={(_, selectedOptions) => setTags(selectedOptions)}
        options={[]}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Tags"
            placeholder="write tag and press enter"
            fullWidth
            margin="normal"
          />
        )}
      />

      <Box>
        {!selectedLeads || selectedLeads.length === 0 ? (
          <Typography variant="body1" sx={{ color: 'error.main' }}>
            no leads selected
          </Typography>
        ) : (
          <Typography variant="body1">
            {selectedLeads.length} leads selected
          </Typography>
        )}
      </Box>

      <StyledButton
        variant="contained"
        size="medium"
        sx={{
          padding: '10px 20px',
          width: 'fit-content',
          alignSelf: 'flex-end',
        }}
        endIcon={<ArrowRight />}
        onClick={onCreateCampaign}
        disabled={
          !campaignName ||
          !campaignDescription ||
          !locations.length ||
          !selectedLeads ||
          selectedLeads.length === 0 ||
          creatingCampaign
        }
      >
        Create Campaign
      </StyledButton>
    </Box>
  );
};

export default StartWithTemplateForm;
