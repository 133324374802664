import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import type { CampaignWorkflow } from '@/types/campaign';
import { fetchCampaigns } from '@/utils/campaigns/fetchCampaigns';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import CampaignDashboard from './CampaignDashboard';
import CampaignForm from './CampaignForm';

interface CampaignDetailsProps {
  campaignId: string;
}

interface EmailItem {
  _id: string;
  emailId: string;
}

function CampaignDetails({
  campaignId,
}: CampaignDetailsProps): React.ReactNode {
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const alchemystPersona = useAlchemystStoreForAi((store) => store.alchemyst);
  const [senderName, setSenderName] = useState<string>(alchemystPersona.name);
  const campaignWorkflows = useAlchemystStoreForAi(
    (store) => store.campaignWorkflows
  );
  const { inboxEmails, setAlchemystStoreState, inbox, snackbarEntries } =
    useAlchemystStoreForAi((store) => ({
      inboxEmails: store.inbox.inboxEmails,
      inbox: store.inbox,
      setAlchemystStoreState: store.setStoreState,
      snackbarEntries: store.snackbarEntries,
    }));

  const campaignData = useCampaignBuilderStore((store) => store.campaignData);
  const setBuilderStoreState = useCampaignBuilderStore(
    (store) => store.setStoreState
  );

  //TODO use sse for irt tracking

  console.log('campaignWorkflows:', campaignWorkflows);

  const fetchCampaignWorkflows = async (): Promise<void> => {
    setLoading(true);
    try {
      setBuilderStoreState({ campaignData: null });
      setAlchemystStoreState({ selectedLeads: [] });

      const _campaignWorkflows = await fetchCampaigns();

      const currentCampaign = _campaignWorkflows.find(
        (c) => c.id === campaignId
      );

      setAlchemystStoreState({
        campaignWorkflows: _campaignWorkflows,
        selectedLeads: currentCampaign?.leads ?? [],
      });

      setBuilderStoreState({
        campaignData: currentCampaign || null,
      });
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const foundCampaign = campaignWorkflows.find((c) => c.id === campaignId);
  //   console.log('THE FOUND CAMPAIGN', foundCampaign);
  //   if (foundCampaign) {
  //     setBuilderStoreState({ campaignData: foundCampaign });
  //   } else {
  //     void fetchCampaignWorkflows();
  //   }
  // }, [campaignId]);

  useEffect(() => {
    setBuilderStoreState({ campaignData: null });
    setAlchemystStoreState({ selectedLeads: [] });

    const foundCampaign = campaignWorkflows.find((c) => c.id === campaignId);

    if (foundCampaign) {
      setBuilderStoreState({ campaignData: foundCampaign });
      setAlchemystStoreState({
        selectedLeads: foundCampaign.leads ?? [],
      });
    } else {
      fetchCampaignWorkflows();
    }
  }, [campaignId]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await fetchWithRewrites(
          '/api/inbox/list?emailsOnly=true',
          {
            signal: createTimeoutSignal(),
          }
        );
        if (!response.ok) throw new Error('Failed to fetch emails');
        const emails = await response.json();
        const inboxEmailsList = emails.data.map(
          (item: EmailItem) => item.emailId
        );
        setAlchemystStoreState({
          inbox: {
            ...inbox,
            inboxEmails: inboxEmailsList,
          },
        });

        // console.log(emails.data[0]);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateCampaign = (updatedCampaign: CampaignWorkflow): void => {
    const updatedCampaigns = campaignWorkflows.map((workflow) =>
      workflow.id === updatedCampaign.id ? updatedCampaign : workflow
    );
    setAlchemystStoreState({ campaignWorkflows: updatedCampaigns });
  };

  const handleChangeStatus = async (
    targetStatus: CampaignWorkflow['status']
  ): Promise<void> => {
    if (
      inboxEmails.length === 0 &&
      targetStatus === 'running' &&
      (campaignData?.channels.emails ?? []).length === 0
    ) {
      setAlchemystStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: 'No inbox emails found',
            type: 'alert',
            variant: 'determinate',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      throw new Error('No inbox emails found');
    } else {
      setAlchemystStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'info',
            message: 'Starting campaign',
            type: 'alert',
            variant: 'determinate',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      console.log('inboxEmails:', inboxEmails);
    }

    if (!inboxEmails) {
      setAlchemystStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: 'No inbox emails found',
            type: 'alert',
            variant: 'determinate',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      console.log('No inbox emails found');
      return;
    }

    console.log('Sender Name:', senderName);

    const updatedFromEmails = inboxEmails.map((email, index) => {
      return {
        email,
        number: index,
        username: senderName,
        fullName: senderName,
      };
    });

    if (!campaignData?.stepConfig) return;
    let stepIndex = 0;
    for (const stepConfig of campaignData.stepConfig) {
      if (stepConfig.actions.length === 0 && targetStatus === 'running') {
        setAlchemystStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              severity: 'error',
              message: `Please add at least one action to step ${stepIndex + 1}`,
              type: 'alert',
              variant: 'determinate',
              notificationId: crypto.randomUUID(),
            },
          ],
        });
        return;
      }
      stepIndex += 1;
    }

    console.log('Updated from emails:', updatedFromEmails);
    const response = await fetchWithRewrites('/api/campaigns/update', {
      method: 'POST',
      body: JSON.stringify({
        ...campaignData,
        status: targetStatus,
        stepConfig: campaignData?.stepConfig.map((step) => {
          return {
            ...step,
            from: updatedFromEmails,
          };
        }),
      }),
    });
    if (!response.ok) {
      console.error('Failed to start campaign');
    }

    if (campaignData) {
      setBuilderStoreState({
        campaignData: {
          ...campaignData,
          status: targetStatus,
        },
      });
    }

    setAlchemystStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          severity: targetStatus === 'running' ? 'success' : 'info',
          message: `Campaign set to ${targetStatus}`,
          type: 'alert',
          // variant: 'determinate',
          notificationId: crypto.randomUUID(),
          autoHideDuration: 5000,
        },
      ],
    });
  };

  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!campaignData) {
    return <div>Campaign not found</div>;
  }

  if (campaignData.status === 'draft') {
    return <CampaignForm />;
  }
  return (
    <>
      {campaignData ? (
        <CampaignDashboard
          campaign={campaignData}
          onSenderNameChange={setSenderName}
          onStatusChange={handleChangeStatus}
          senderName={senderName}
        />
      ) : null}
    </>
  );
}

export default CampaignDetails;
