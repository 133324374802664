export const groupListByKey = <T extends Record<string, any>>(
  list: T[],
  key: keyof T
): Record<string, T[]> => {
  const listGroupedByKeys: Record<string, T[]> = {};

  const groups: string[] = [];

  list.forEach((item) => {
    const keyValue = item[key] as string;
    if (!groups.includes(keyValue)) {
      groups.push(keyValue);
      listGroupedByKeys[keyValue] = [item];
    } else {
      listGroupedByKeys[keyValue].push(item);
    }
  });

  console.log('THE LSIT', listGroupedByKeys);

  return listGroupedByKeys;
};
