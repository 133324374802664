import { Add, Search } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import { useQueryParams } from 'raviger';
import { useEffect, useState } from 'react';
import { Header, StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import type { CampaignWorkflow } from '@/types/campaign';
import { fetchCampaigns } from '@/utils/campaigns/fetchCampaigns';
import AddCampaignModal from './addCampaign/AddCampaignModal';
import CampaignCard from './CampaignCard';

function SalesCampaign() {
  const campaignWorkflows = useAlchemystStoreForAi(
    (store) => store.campaignWorkflows
  );
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredCampaigns, setFilteredCampaigns] =
    useState<CampaignWorkflow[]>(campaignWorkflows);
  const [openAddCampaignModal, setOpenAddCampaignModal] =
    useState<boolean>(false);
  const [{ start }, setQuery] = useQueryParams();

  const fetchCampaignWorkflows = async () => {
    const _campaignWorkflows: CampaignWorkflow[] = await fetchCampaigns();
    setStoreState({
      campaignWorkflows: _campaignWorkflows,
    });
  };

  useEffect(() => {
    if (!campaignWorkflows.length) fetchCampaignWorkflows();
    if (start === 'true') {
      setOpenAddCampaignModal(true);
      setQuery({});
    }
  }, []);

  useEffect(() => {
    // Filter campaigns whenever the search query changes
    const filtered = campaignWorkflows.filter(
      (workflow) =>
        (workflow.name ?? '')
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        (workflow.description ?? '')
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );
    setFilteredCampaigns(filtered);
  }, [searchQuery, campaignWorkflows]);

  const handleSearchCampaign = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleUpdateCampaign = (campaign: CampaignWorkflow) => {
    const updatedCampaigns = campaignWorkflows.map((workflow) =>
      workflow.id === campaign.id ? campaign : workflow
    );
    setStoreState({ campaignWorkflows: updatedCampaigns });
  };

  const handleDeleteCampaign = (id: string) => {
    const updatedCampaigns = campaignWorkflows.filter(
      (workflow) => workflow.id !== id
    );
    setStoreState({ campaignWorkflows: updatedCampaigns });
  };

  return (
    <Box
      sx={{
        padding: '1rem',
      }}
    >
      <Header>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Search sx={{ color: 'gray', fontSize: 30 }} />
          <TextField
            fullWidth
            onChange={handleSearchCampaign}
            placeholder="Search Campaigns"
            size="small"
            sx={{ borderRadius: '8px' }}
            value={searchQuery}
            variant="standard"
          />
        </Box>
        <Box alignItems="center" display="flex" gap={2}>
          <StyledButton
            onClick={() => {
              setOpenAddCampaignModal(true);
            }}
            startIcon={<Add />}
            sx={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '8px',
            }}
            variant="contained"
          >
            Add Campaign
          </StyledButton>
        </Box>
      </Header>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
          gap: '1rem',
        }}
      >
        {filteredCampaigns.map((campaign) => (
          <CampaignCard campaign={campaign} key={campaign.id} />
        ))}
      </Box>
      <AddCampaignModal
        onClose={() => {
          setOpenAddCampaignModal(false);
        }}
        open={openAddCampaignModal}
      />
    </Box>
  );
}

export default SalesCampaign;
