import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import fetchThreadIdListClientSide from '@/utils/inbox/fetchThreadIdList';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { AddEmailModal } from './AddEmailModal';
import Conversations from './Conversations';
import EmailConfigModal from './EmailConfigModal';
import ImapModal from './ImapModal';
import InboxMenu, { ModalOpenProps } from './InboxMenu';
import ViewConversationThread from './ViewConversationThread';

/**
 * Group an array of objects by a key
 */
export const groupListByKey = <T extends Record<string, any>>(
  list: T[],
  key: keyof T
): Record<string, T[]> => {
  const listGroupedByKeys: Record<string, T[]> = {};

  const groups: string[] = [];

  list.forEach((item) => {
    const keyValue = item[key] as string;
    if (!groups.includes(keyValue)) {
      groups.push(keyValue);
      listGroupedByKeys[keyValue] = [item];
    } else {
      listGroupedByKeys[keyValue].push(item);
    }
  });

  return listGroupedByKeys;
};

function InboxLayout(): React.ReactNode {
  /* ------------------------------------------ States ---------------------------------------------------- */
  const [emailsWithThreads, setEmailsWithThreads] = useState<{
    threads: string[];
    messages: Record<string, any>[];
  }>({ threads: [], messages: [] });
  const [fetchingEmails, setFetchingEmails] = useState<boolean>(false);
  const [currentThreadId, setCurrentThreadId] = useState<string | null>(null);
  const [currentThreadEmails, setCurrentThreadEmails] = useState<
    Record<string, any>[]
  >([]);
  const [modalOpen, setModalOpen] = useState<ModalOpenProps>({
    addEmailModal: false,
    inboxConfigModal: false,
    imapModal: false,
  });
  const [inboxAuthenticated, setInboxAuthenticated] = useState<boolean>(false);
  const [isCredentialsSet, setIsCredentialsSet] = useState<boolean>(true);

  const {
    emailsToCheck,
    inboxEmails,
    setStoreState,
    autoFetchCampaignEmails,
    inbox,
  } = useAlchemystStoreForAi((store) => ({
    emailsToCheck: store.inbox.emailsToCheck,
    inboxEmails: store.inbox.inboxEmails,
    inbox: store.inbox,
    setStoreState: store.setStoreState,
    autoFetchCampaignEmails: store.inbox.autoFetchCampaignEmails,
  }));

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl]);

  /* --------------------------------------------- Handlers ------------------------------------------------- */

  const handleViewConversation = (threadId: string) => {
    setCurrentThreadId(threadId);
    setCurrentThreadEmails(
      emailsWithThreads.messages.filter(
        (message) => message.threadId === threadId
      )
    );
  };

  const setEmailsToCheck = (emails: string[]) => {
    setStoreState({ inbox: { ...inbox, emailsToCheck: emails } });
  };

  /* ---------------------------------------------- Effects ----------------------------------------------- */

  useEffect(() => {
    console.log('emailsToCheck');
    console.log(emailsToCheck);
  }, [emailsToCheck]);

  useEffect(() => {
    const emailsToCheckInStorage = JSON.parse(
      localStorage.getItem('emailsToCheck') || '[]'
    ) as string[];
    setEmailsToCheck(
      Array.from<string>(new Set([...emailsToCheck, ...emailsToCheckInStorage]))
    );
  }, []);

  useEffect(() => {
    if (autoFetchCampaignEmails) {
      handleEmailsFetching();
    }
  }, [autoFetchCampaignEmails === true]);

  /* ------------------------------------------- Handlers ------------------------------------------------- */

  const handleEmailsFetching = async (): Promise<void> => {
    setFetchingEmails(true);
    const fetchedThreadIds = await fetchThreadIdListClientSide(
      emailsToCheck,
      inbox.selectedInboxEmails
    );
    console.log(fetchedThreadIds);
    setEmailsWithThreads(fetchedThreadIds);
    setTimeout(() => setFetchingEmails(false), 30_000);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  /* ------------------------------------------- JSX ------------------------------------------------------ */
  return (
    <Stack spacing={2}>
      <InboxMenu
        fetchingEmails={fetchingEmails}
        handleEmailsFetching={handleEmailsFetching}
        handleMenuClick={handleMenuClick}
        handleMenuClose={handleMenuClose}
        isMenuOpen={isMenuOpen}
        menuAnchorEl={menuAnchorEl}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      {(!fetchingEmails || emailsWithThreads.messages.length > 0) && (
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box width="25%">
            <Conversations
              emails={emailsWithThreads.messages}
              handleViewConversation={handleViewConversation}
            />
          </Box>
          <Box width="75%">
            {currentThreadId ? (
              <ViewConversationThread
                emails={currentThreadEmails}
                threadId={currentThreadId}
              />
            ) : (
              <Typography>Select a conversation to view</Typography>
            )}
          </Box>
        </Stack>
      )}
      {fetchingEmails && emailsWithThreads.messages.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <CircularProgress />
          <Typography>Loading emails...</Typography>
        </Box>
      )}
      {modalOpen.inboxConfigModal ? (
        <EmailConfigModal
          inboxConfigModalVisible={modalOpen.inboxConfigModal}
          setModalOpen={setModalOpen}
        />
      ) : null}

      <AddEmailModal
        onClose={() => {
          setModalOpen((prev) => ({
            ...prev,
            addEmailModal: false,
          }));
        }}
        open={modalOpen.addEmailModal}
      />

      <ImapModal
        onClose={(event?: {}, reason?: 'escapeKeyDown' | 'backdropClick') => {
          if (reason === 'backdropClick' && inboxEmails.length === 0) return;
          setModalOpen((prev) => ({
            ...prev,
            imapModal: false,
          }));
        }}
        open={modalOpen.imapModal}
        setInboxAuthenticated={setInboxAuthenticated}
        setIsCredentialsSet={setIsCredentialsSet}
      />
    </Stack>
  );
}

export default InboxLayout;
