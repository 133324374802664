import { Header, StyledButton, StyledContainer } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Box, Stack, styled, Switch, Typography } from '@mui/material';

import ContextVisualizer from '@/components/Context/visualizer';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
  inputProps: { accept: '.csv,.docx,.doc,.pdf,.txt' },
});

function ContextPage(): React.ReactNode {
  const [isUploading, setIsUploading] = useState(false);
  const [documentType, setDocumentType] = useState<'internal' | 'external'>(
    'internal'
  );
  const { snackbarEntries, setStoreState } = useAlchemystStoreForAi(
    (store) => ({
      snackbarEntries: store.snackbarEntries,
      setStoreState: store.setStoreState,
    })
  );
  const [hostName, setHostName] = useState('http://localhost:3000');

  const addSnackbarEntry = (entry: (typeof snackbarEntries)[0]) => {
    setStoreState({
      snackbarEntries: [...snackbarEntries, entry],
    });
  };

  useEffect(() => {
    if (!!window) {
      setHostName(window.location.origin);
    }
  }, []);

  const handleSingleFileUpload = async (
    file: File | null,
    scope: 'internal' | 'external'
  ) => {
    try {
      const MAX_SIZE = 50 * 1024 * 1024; // 50 MB
      if (!file) return;
      if (file.size > MAX_SIZE) {
        addSnackbarEntry({
          message: 'Uploaded file must be under 50MB',
          severity: 'error',
          type: 'alert',
          notificationId: crypto.randomUUID(),
        });
        return;
      }
      setIsUploading(true);

      // Prepare file metadata
      const fileMetadata = {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        lastModified: file.lastModified,
      };

      const notificationId = `platform.resource.${fileMetadata.fileName}.${fileMetadata.fileType}`;

      try {
        const contextProcessorUrl = `${process.env.REACT_APP_BACKEND_URL}/api/context/add`;
        if (!contextProcessorUrl) {
          throw new Error('CONTEXT_PROCESSOR_URL is not defined');
        }

        // const worker = new Worker('/workers/upload_patch.js', {
        //   type: 'module',
        // }); // TODO: Replace with actual worker path

        // console.log('THE WORKER', worker);

        // const workerOnMessageHandler = (
        //   data: {
        //     type: 'progress' | 'complete' | 'error';
        //     payload: { value: number; message: string };
        //   } & Record<string, any>,
        //   notificationId: string
        // ) => {
        //   console.log('Received payload...');
        //   const { type, payload } = data;
        //   console.log(payload);

        //   switch (type) {
        //     case 'progress':
        //       console.log(`Processing progress: ${JSON.stringify(payload)}%`);
        //       updateSnackbarEntry({
        //         notificationId,
        //         ...payload,
        //       });
        //       break;
        //     case 'complete':
        //       console.log('File processing and uploading complete');
        //       updateSnackbarEntry({
        //         notificationId,
        //         ...payload,
        //         type: 'progress',
        //       });
        //       break;
        //     case 'error':
        //       console.error('Error in worker:', payload);
        //       break;
        //   }
        // };

        // worker.onmessage = (event) =>
        //   workerOnMessageHandler(event.data, notificationId);

        // worker.onerror = (error) => {
        //   console.error('Error in worker:', error);
        // };

        //TODO extend support for other file types
        const fileContent = await readFileContent(file);
        console.log(
          'File content = ',
          fileContent.slice(Math.min(60, fileContent.length))
        );

        // await storeFileContentInIndexedDB(file.name, fileContent);

        // TODO: Replace with actual user ID

        const fetchUserIdResponse = await fetchWithRewrites('/api/profile', {
          signal: createTimeoutSignal(),
        });
        const fetchUserIdJson: { user_id: string } =
          await fetchUserIdResponse.json();
        const userId = fetchUserIdJson.user_id;
        console.log('Received userId = ', userId);

        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              type: 'progress',
              value: 0,
              message: 'Processing documents',
              severity: 'info',
              notificationId,
            },
          ],
        });

        console.log('File reading complete, posting message to worker...');
        // await worker.postMessage({ fileContent, apiUrl, fileMetadata, userId });
        // worker.postMessage({
        //   fileContent,
        //   contextProcessorUrl,
        //   fileMetadata,
        //   userId,
        //   accessToken,
        // });

        const documentData = {
          content: fileContent,
          ...fileMetadata,
        };

        const uploadDocumentsRes = await fetchWithRewrites(
          contextProcessorUrl,
          {
            method: 'POST',
            body: JSON.stringify({
              // user_id: userId,
              documents: [documentData],
              source: 'platform/maya/smart-settings.upload',
              context_type: 'resource',
              chained: false,
              scope,
              metadata: {
                size: file.size,
                file_name: file.name,
                doc_type: file.type,
                modalities: ['text', 'image'],
              },
            }),
            signal: createTimeoutSignal(),
          }
        );

        const documentUploadResult = await uploadDocumentsRes.json();

        if (uploadDocumentsRes.ok) {
          console.log('Document processed successfully:', documentUploadResult);
          setStoreState({
            snackbarEntries: [
              ...snackbarEntries,
              {
                notificationId,
                type: 'progress',
                value: 100,
                message: 'Documents processed successfully',
                severity: 'success',
              },
            ],
          });
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setIsUploading(false);
      }
    } catch (error) {
      console.error('Error processing file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    scope: 'internal' | 'external'
  ): Promise<void> => {
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          type: 'alert',
          message: 'Started uploading documents',
          severity: 'info',
          notificationId: crypto.randomUUID(),
        },
      ],
    });
    await Promise.all(
      [...(event.target.files ?? [])].map(async (file) => {
        await handleSingleFileUpload(file, scope);
      })
    );
  };

  const readFileContent = async (file: File): Promise<string> => {
    console.log('Reading file...');
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    switch (fileExtension) {
      case 'pdf':
        return readPdfContent(file);
      case 'docx':
        return readDocxContent(file);
      case 'xlsx':
        return JSON.stringify(await readXlsxContent(file));
      default:
        console.log('Reading text file...');
        return file.text();
    }
  };

  const readPdfContent = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);

    console.log('Getting PDF text...');
    try {
      const response = await fetchWithRewrites('/api/upload', {
        method: 'POST',
        body: formData,
        signal: createTimeoutSignal(),
      });

      if (!response.ok) {
        console.log('Failed to process PDF');
      }

      const responseData: Record<string, any> & { text: string } =
        await response.json();

      console.log('Finished uploading pdf file to context processor.');

      return responseData.text;
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error;
    }
  };

  const readDocxContent = async (file: File): Promise<string> => {
    console.log('Reading Docx File...');
    // This function would use the mammoth package to read DOCX content
    // For demonstration, we'll use a placeholder implementation
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve('DOCX content placeholder');
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const readXlsxContent = async (file: File): Promise<string[][]> => {
    console.log('Reading XLSX File...');

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target?.result;

        if (result) {
          try {
            const data = new Uint8Array(result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            console.log(jsonData);
            // @ts-ignore
            resolve(jsonData);
          } catch (error) {
            console.error('Error parsing XLSX file:', error);
            reject(error);
          }
        } else {
          reject(new Error('Failed to read file'));
        }
      };

      reader.onerror = () => {
        reject(new Error('File reading failed'));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const storeFileContentInIndexedDB = async (
    fileName: string,
    content: string
  ) => {
    console.log('Writing to IndexedDB...');
    const dbName = 'FileContentsDB';
    const storeName = 'fileContents';
    const version = 1;

    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, version);

      request.onerror = (event) => reject('IndexedDB error');

      request.onsuccess = (event) => {
        const db = request.result;
        const transaction = db.transaction([storeName], 'readwrite');
        const store = transaction.objectStore(storeName);
        const addRequest = store.put({ fileName, content });

        addRequest.onerror = () => reject('Error storing file content');
        addRequest.onsuccess = () => resolve(undefined);
      };

      request.onupgradeneeded = (event) => {
        const db = request.result;
        db.createObjectStore(storeName, { keyPath: 'fileName' });
      };
    });
  };

  return (
    <StyledContainer maxWidth="xl">
      <Header>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'space-between'}
          width={'100%'}
          gap={2}
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              Context
            </Typography>
            <Typography variant="body1" marginTop={2}>
              Manage your organizational context here.
            </Typography>
          </Box>
          <Stack
            alignItems="right"
            justifyItems="right"
            flex="column"
            spacing={3}
          >
            <StyledButton
              variant="contained"
              // @ts-ignore
              component="label"
              startIcon={<AutoFixHighIcon />}
              sx={{
                padding: '8px 26px',
                fontSize: '16px',
                borderRadius: '8px',
              }}
              disabled={isUploading}
            >
              {isUploading ? 'Uploading...' : 'Upload Documents (Max 50 MB)'}
              <VisuallyHiddenInput
                type="file"
                multiple
                onChange={(e) => handleFileUpload(e, documentType)}
                disabled={isUploading}
              />
            </StyledButton>
            <Box
              display="flex"
              flexDirection={'row'}
              gap={2}
              alignItems="center"
            >
              <Typography>
                Allow these documents to be used in public-facing tasks?
              </Typography>
              <Switch
                checked={documentType === 'external'}
                onChange={() => {
                  setDocumentType(
                    documentType === 'internal' ? 'external' : 'internal'
                  );
                }}
              ></Switch>
            </Box>
          </Stack>
        </Box>
      </Header>
      <ContextVisualizer />
    </StyledContainer>
  );
}

export default ContextPage;
