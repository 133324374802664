// /* eslint-disable react-hooks/rules-of-hooks */
import { chatChannels } from '@/constants/chatHistory';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { ChatHistoryContainerProps } from '@/types/components/converse/history';
import Add from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChatHistoryList from './ChatHistoryList';
import ChatHistorySkeleton from './ChatHistorySkeleton';

/**
 * ChatHistoryContainer component
 *
 * This component displays the chat history and a "New Chat" button to start a new chat session.
 * If loading, it shows a skeleton loader to indicate data is being fetched.
 * Otherwise, it renders the chat history list and the button to initiate a new chat.
 *
 * @param loading - Boolean to determine if the skeleton loader should display
 * @param setCurrentChatID - Callback to set the current chat ID; used to start a new or existing chat
 * @param props - Additional properties passed down to the ChatHistoryList component
 */

function ChatHistoryContainer({
  loading,
  setCurrentChatID,
  ...props
}: ChatHistoryContainerProps): React.ReactNode {
  const { chatChannel, setStoreState } = useAlchemystStoreForAi((store) => ({
    chatChannel: store.chatChannel,
    setStoreState: store.setStoreState,
  }));
  if (loading) {
    return <ChatHistorySkeleton skeletonCount={10} />;
  }

  const setChatChannel = (chatChannelName: string): void => {
    setStoreState({ chatChannel: chatChannelName });
  };

  return (
    <Box sx={{ paddingX: 2 }}>
      <Typography
        sx={{
          marginBottom: 3,
        }}
        variant="h5"
      >
        Chat History
      </Typography>

      <Stack spacing={3}>
        <Stack spacing={1}>
          <StyledButton
            color="primary"
            onClick={() => {
              setCurrentChatID('');
              setStoreState({ isResearchMode: false });
            }}
            startIcon={<Add />}
            sx={{
              borderRadius: 1,
              color: '#ff9a35',
              borderColor: '#ff9a3560',
              '&:hover': {
                borderColor: '#ff9a35',
                backgroundColor: '#ff9a3510',
              },
            }}
            variant="outlined"
          >
            New Chat
          </StyledButton>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Option</InputLabel>
              <Select
                id="demo-simple-select"
                label="Option"
                labelId="demo-simple-select-label"
                onChange={(e) => {
                  setChatChannel(e.target.value);
                }}
                value={chatChannel}
              >
                {chatChannels.map((channel) => (
                  <MenuItem
                    key={`channel-${channel.channel}`}
                    value={channel.channel}
                  >
                    {channel.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
        <Divider />
        <ChatHistoryList setCurrentChatID={setCurrentChatID} {...props} />
      </Stack>
    </Box>
  );
}

export default ChatHistoryContainer;
