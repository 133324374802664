import React from 'react';
import { useDrag } from 'react-dnd';

interface DraggableComponentProps {
  id: string | undefined;
  html: string | React.ReactElement;
  coordinates: { x: number; y: number };
}

const DraggableComponent: React.FC<DraggableComponentProps> = ({
  id,
  html,
  coordinates,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BOX',
    item: { id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  if (typeof html !== 'string') {
    return (
      <div
        ref={(element) => {
          if (element) {
            drag(element);
          }
        }}
        draggable
        style={{
          position: 'absolute',
          top: coordinates.y, // Corrected to use y for top and x for left
          left: coordinates.x,
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <html />
      </div>
    );
  }
  return (
    <div
      ref={(element) => {
        if (element) {
          drag(element);
        }
      }}
      dangerouslySetInnerHTML={{ __html: html }}
      draggable
      style={{
        position: 'absolute',
        top: coordinates.y, // Corrected to use y for top and x for left
        left: coordinates.x,
        opacity: isDragging ? 0.5 : 1,
      }}
    />
  );
};

export default DraggableComponent;
