import { campaignChannels } from '@/constants/campaigns/campaignChannels';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface SelectorProps {
  channel: keyof typeof campaignChannels;
  items: string[];
  title: string;
}

const Selector = ({ items, title, channel }: SelectorProps) => {
  const { campaignData, setStoreState } = useCampaignBuilderStore((store) => ({
    campaignData: store.campaignData,
    setStoreState: store.setStoreState,
  }));

  const handleToggle = (item: string, channel: string) => {
    if (campaignData)
      setStoreState({
        campaignData: {
          ...campaignData,
          channels: {
            ...campaignData.channels,
            [channel]: (campaignData.channels[channel] ?? []).includes(item)
              ? campaignData.channels[channel].filter((e) => e !== item)
              : [...(campaignData.channels[channel] ?? []), item],
          },
        },
      });
  };

  const allSelected =
    items.length > 0 &&
    campaignData?.channels[channel] &&
    items.every((item) => campaignData.channels[channel].includes(item));

  const handleToggleAll = () => {
    if (campaignData) {
      if (allSelected) {
        setStoreState({
          campaignData: {
            ...campaignData,
            channels: {
              ...campaignData.channels,
              [channel]: [],
            },
          },
        });
      } else {
        setStoreState({
          campaignData: {
            ...campaignData,
            channels: {
              ...campaignData.channels,
              [channel]: [...items],
            },
          },
        });
      }
    }
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {items.length > 1 && (
            <ListItem disablePadding>
              <ListItemButton onClick={handleToggleAll}>
                <Checkbox
                  checked={allSelected}
                  disableRipple
                  edge="start"
                  tabIndex={-1}
                />
                <ListItemText primary="Select All" />
              </ListItemButton>
            </ListItem>
          )}

          {items.map((item) => (
            <ListItem disablePadding key={item}>
              <ListItemButton
                onClick={() => {
                  handleToggle(item, channel);
                }}
              >
                <Checkbox
                  checked={
                    campaignData?.channels[channel].includes(item) ?? false
                  }
                  disableRipple
                  edge="start"
                  tabIndex={-1}
                />
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

function CommunicationSelector() {
  const [emails, setEmails] = useState<string[]>([]);
  const [whatsappContacts, setWhatsappContacts] = useState<string[]>([]);
  const [telegramContacts, setTelegramContacts] = useState<string[]>([]);

  useEffect(() => {
    const fetchInboxEmailsConfigured = async () => {
      const queryParams = new URLSearchParams({
        emailsOnly: 'true',
      });

      const res = await fetchWithRewrites(`/api/inbox/list?${queryParams}`);
      const data = await res.json();

      setEmails(data.data.map((item: { emailId: string }) => item.emailId));
    };

    fetchInboxEmailsConfigured();
  }, []);

  useEffect(() => {
    const fetchWhatsAppContacts = async () => {
      const res = await fetchWithRewrites(
        `/api/maya/integrations/whatsapp/fetch`
      );
      const data = await res.json();

      // console.log('THE DATA', data);
      setWhatsappContacts(
        data.data.map(
          (item: { credentials: { phoneNumber: string } }) =>
            item.credentials.phoneNumber
        )
      );
    };

    fetchWhatsAppContacts();
  }, []);
  return (
    <div>
      <Selector
        channel={campaignChannels.emails}
        items={emails}
        title="Email"
      />
      <Selector
        channel={campaignChannels.whatsapp}
        items={whatsappContacts}
        title="WhatsApp"
      />
      <Selector
        channel={campaignChannels.telegram}
        items={telegramContacts}
        title="Telegram"
      />
    </div>
  );
}

export default CommunicationSelector;
