import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { LangChainJSON } from '@/types/ai/messages';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { CopyAll } from '@mui/icons-material';
import { CircularProgress, Stack, TextField } from '@mui/material';
import { useState } from 'react';

const IndividualScraper = () => {
  const [currentBrowsingUrl, setBrowsingUrl] = useState<string | null>(null);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const { currentAugmentorOutput, setStoreState } = useAlchemystStoreForAi(
    (store) => ({
      currentAugmentorOutput: store.currentAugmentorOutput,
      setStoreState: store.setStoreState,
    })
  );

  const [workStatus, setWorkStatus] = useState<
    'open' | 'closed' | 'working' | 'saved'
  >('open');

  const [userPrompt, setUserPrompt] = useState<string | null>(null);

  // const [token, setToken] = useState<string | null>(null);
  // const { getToken } = fetchWithRewrites(;

  // const getAuthToken = async () => {
  //   const token = await getToken();
  //   setToken(token);
  // };

  // useEffect(() => {
  //   getAuthToken();
  // }, []);

  const isValidUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const getHeadlessScrapingResults = async (headlessScraperQuery: {
    userPrompt: string;
    pageUrl: string;
  }) => {
    const res = await fetchWithRewrites('/api/leads/augment', {
      method: 'POST',
      body: JSON.stringify(headlessScraperQuery),
      signal: createTimeoutSignal(),
    });

    const res_json = await res.json();
    let resp: LangChainJSON | null = null;
    if (res.status === 201) {
      resp = res_json;
    }

    console.log('Input going to the api = ', headlessScraperQuery);
    console.log('Resulting JSON = ', resp);
    return resp;
  };

  const getBatchedHeadlessScrapingResults = async (
    headlessScraperQueries: { userPrompt: string; pageUrl: string }[],
    statusUpdateFunction: (status: 'working' | 'open' | 'closed') => any
  ) => {
    statusUpdateFunction('working');
    const results = await Promise.all(
      headlessScraperQueries.map(async (headlessScraperQuery) =>
        getHeadlessScrapingResults(headlessScraperQuery)
      )
    );
    statusUpdateFunction('open');

    return results;
  };

  const handleFetchAugmentorData = async () => {
    if (!currentBrowsingUrl || !isValidUrl(currentBrowsingUrl)) return;
    setWorkStatus('working');
    const currentResult = await getBatchedHeadlessScrapingResults(
      [
        {
          pageUrl: currentBrowsingUrl,
          userPrompt: userPrompt ?? 'No user prompt',
        },
      ],
      setWorkStatus
    );
    console.log('Received data = ', JSON.stringify(currentResult));
    if (!currentResult) {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: "Couldn't get a response from Augmentor API.",
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      return;
    }
    setStoreState({
      currentAugmentorOutput: currentResult[0]?.lc_kwargs.content,
    });
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          severity: 'success',
          message: 'Data Augmentation complete. Select a cell to paste it in.',
          type: 'alert',
          notificationId: crypto.randomUUID(),
        },
      ],
    });
    setWorkStatus('open');
  };

  const handleChangeUserPrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPrompt(e.target.value);
  };

  const handleChangeBrowsingUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrowsingUrl(e.target.value);
  };

  const handleCopyCurrentAugmentorOutput = () => {
    if (!currentAugmentorOutput) return;
    navigator.clipboard.writeText(currentAugmentorOutput);
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          severity: 'success',
          message: 'Data copied to clipboard.',
          type: 'alert',
          notificationId: crypto.randomUUID(),
        },
      ],
    });
  };

  return (
    <Stack padding={2} spacing={3} width={'100%'}>
      {/* <Typography variant="h6">Augment Data</Typography> */}
      <TextField
        label="Target Webpage"
        multiline
        maxRows={2}
        value={currentBrowsingUrl ?? ''}
        onChange={handleChangeBrowsingUrl}
      />
      <TextField
        label="Prompt"
        value={userPrompt ?? ''}
        multiline
        minRows={5}
        maxRows={6}
        onChange={handleChangeUserPrompt}
        placeholder="Enter what you want from the data."
      />
      <StyledButton
        variant="contained"
        onClick={handleFetchAugmentorData}
        disabled={
          !currentBrowsingUrl ||
          !userPrompt ||
          ['saved', 'working'].includes(workStatus)
        }
        endIcon={
          ['saved', 'working'].includes(workStatus) && (
            <CircularProgress size={'1vh'} />
          )
        }
      >
        {!['saved', 'working'].includes(workStatus) ? 'Augment' : 'working'}
      </StyledButton>
      {currentAugmentorOutput && (
        <Stack spacing={3}>
          <TextField
            label="Output"
            value={currentAugmentorOutput ?? ''}
            multiline
            minRows={5}
            maxRows={6}
          />
          <StyledButton
            variant="contained"
            onClick={handleCopyCurrentAugmentorOutput}
            endIcon={<CopyAll />}
          >
            Copy to clipboard
          </StyledButton>
        </Stack>
      )}
    </Stack>
  );
};

export default IndividualScraper;
