import { IOSSwitch } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { IntegrationsEntry } from '@/types/integrations';
import { Settings } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type IntegrationCardProps = {
  item: IntegrationsEntry;
  setConfigModals: React.Dispatch<
    React.SetStateAction<{
      whatsapp: boolean;
      telegram: boolean;
      slack: boolean;
      hubspot: boolean;
    }>
  >;
};

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  item,
  setConfigModals,
}) => {
  const [enabled, setEnabled] = useState(item.enabled);
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);

  // // TODO  logic to be modified
  // useEffect(() => {
  //   async function initCall() {
  //     const initPayload = {
  //       userId: 'testId',
  //       organisationId: 'testOrg',
  //       accessToken:
  //         'EAAIb7eOfEZA0BO21T0b0ImmjbsYD6v9GaKI3y2Soat7rnAsJmZBi1JZAwZA5S0CpnCowfZC1bkjHbs2GiDOgQjUq4B5SRkXfs229n2Mf8EZAg9s9GoLZB0QZAuhKKqvkfU0Incv4RnQXZAXRhBEiHPR6IWFsmVOtbmjQxU9LOnUZB13luEwPmppMtRCFliGljfFyzO46MNQ73VNspMpV4ITbZAgrXwo0KjWP9ATnKcZD',
  //       phoneNumberId: '550306534828752',
  //       appId: '593658493473181',
  //       appSecret: 'a99ef2c0dde37e89a77e1eda97f184ae',
  //       verifyToken: 'ALCHEMYST@2024',
  //       apiKey: 'sk-OWPSM-DUT1E-MAO72-RGZ29',
  //     };

  //     const res = await fetch('http://localhost:8000/init', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(initPayload),
  //     });

  //     const data = await res.json();
  //     console.log(data, 'THE DATA');

  //     if (res.ok) return true;
  //   }

  //   if (enabled && item.name.toLowerCase() === 'whatsapp') {
  //     initCall();
  //   }
  // }, [enabled]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: 'space-between',
        padding: 3,
        paddingX: 6,
        marginBottom: 2,
        borderRadius: 2,
        background:
          currentTheme === 'dark'
            ? 'linear-gradient(320deg, #000000 0%, #0A0A0A 100%)'
            : 'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
      }}
      className={
        currentTheme === 'dark' ? 'custom-border' : 'custom-border_for_light'
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
        <img src={item.icon} alt={item.name} width={80} height={80} />
        <Box marginLeft={2}>
          <Typography variant="h4">{item.name}</Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ marginTop: 1, width: '90%' }}
          >
            {item.description}
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginTop: 1, flexWrap: 'wrap' }}
          >
            {item.tags &&
              item.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  color="secondary"
                  variant="outlined"
                />
              ))}
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          marginTop: { xs: 2, md: 0 },
        }}
      >
        <IOSSwitch
          checked={enabled}
          onChange={() => setEnabled(!enabled)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <IconButton color="primary" aria-label="settings">
          <Settings
            fontSize={'medium'}
            onClick={() => {
              if (item.name.toLowerCase() === 'whatsapp') {
                setConfigModals((prev) => ({
                  ...prev,
                  whatsapp: true,
                }));
              } else if (item.name.toLowerCase() === 'telegram') {
                setConfigModals((prev) => ({
                  ...prev,
                  telegram: true,
                }));
              }
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default IntegrationCard;
