import type { DrawerComponentProps } from '@/types/drawer';

export const mainDrawerComponents: DrawerComponentProps[] = [
  {
    name: 'Dashboard',
    id: 'dashboard',
    path: '/dashboard',
    // icon: <SpaceDashboardIcon />,
  },
  {
    name: 'Converse',
    id: 'converse',
    path: '/converse',
    // icon: <MarkUnreadChatAltIcon />,
  },
  {
    name: 'Prospector',
    id: 'prospector',
    path: '/prospector',
    // icon: <SettingsAccessibilityIcon />,
  },
  {
    name: 'Transcripts',
    id: 'communications',
    path: '/communications',
    // icon: <ForumIcon />,
  },
  {
    name: 'Leads',
    id: 'leads',
    path: '/leads',
    // icon: <SettingsAccessibilityIcon />,
  },
  {
    name: 'Campaigns',
    id: 'campaigns',
    path: '/campaigns',
  },
  {
    name: 'Inbox',
    id: 'shopping_cart',
    path: '/inbox',
    // icon: <AllInboxIcon />,
  },
  {
    name: 'Email Queue',
    id: 'email_queue',
    path: '/email-queue',
    // icon: <MailIcon />,
  },
  // {
  //   name: 'Prospector',
  //   id: 'recommendations',
  //   path: '/prospector',
  //   // icon: <LightbulbIcon />,
  // },
  {
    name: 'Integrations',
    id: 'integrations',
    path: '/integrations',
    // icon: <LinkIcon />,
  },
  {
    name: 'Context',
    id: 'context',
    path: '/context',
    // icon: <SettingsSuggestIcon />,
  },
  {
    name: 'Settings',
    id: 'settings',
    path: '/settings',
    // icon: <Settings />,
  },
];

export const settingsDrawerComponents: DrawerComponentProps[] = [
  {
    name: 'API Keys',
    id: 'api-keys',
    path: '/settings/api-keys',
  },
  {
    name: 'Billing',
    id: 'billing',
    path: '/settings/billing',
  },
  {
    name: 'Usage',
    id: 'usage',
    path: '/settings/usage',
  },
];

export const integrationsDrawerComponents: DrawerComponentProps[] = [
  {
    name: 'My integrations',
    id: 'my-integrations',
    path: '/integrations',
  },
  {
    name: 'Slack',
    id: 'slack',
    path: '/integrations/slack',
  },
  {
    name: 'Discord',
    id: 'discord',
    path: '/integrations/discord',
  },
  {
    name: 'Telegram',
    id: 'telegram',
    path: '/integrations/telegram',
  },
  {
    name: 'Google Workspace',
    id: 'ms-teams',
    path: '/integrations/google-workspace',
  },
];

export const navbarComponents: DrawerComponentProps[] = [
  {
    name: 'Dashboard',
    id: 'dashboard',
    path: '/dashboard',
  },
  {
    name: 'Integrations',
    id: 'integrations',
    path: '/integrations',
  },
  {
    name: 'Docs',
    id: 'docs',
    path: '/docs',
  },
];
