import { ActionSelectorBox } from '@/common/ActionSelectorBox';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import { StepConfig } from '@/types/campaign';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { StepsEditor } from './StepsEditor';

function CampaignSequence(): React.ReactNode {
  const AVAILABLE_INTEGRATIONS = [
    { label: 'Email', enabled: true },
    { label: 'Whatsapp', enabled: true },
    { label: 'Telegram', enabled: true },
    { label: 'Voice', enabled: false },
  ];

  const { campaignData, setStoreState } = useCampaignBuilderStore((store) => ({
    campaignData: store.campaignData,
    selectedActions: store.metadata.actions ?? [],
    setStoreState: store.setStoreState,
  }));
  const selectedLeads = useAlchemystStoreForAi((store) => store.selectedLeads);

  const [openModal, setOpenModal] = React.useState(false);
  const [currentStepConfig, setCurrentStepConfig] = React.useState<StepConfig>({
    id: campaignData?.stepConfig.length || 0,
    actions: [],
    aiGenerated: false,
    from: [],
    message: {
      type: 'email',
      subject: '',
      body: '',
    },
    name: '',
    waitTime: 0,
    templateNameForWhatsapp: '',
    useAi: {
      create: false,
      modify: false,
    },
  });

  useEffect(() => {
    console.log('Current step = ', currentStepConfig);
  }, [currentStepConfig]);

  const handleAddStep = (): void => {
    const currentSteps = campaignData?.stepConfig || [];
    const maxId = currentSteps.reduce(
      (max, step) => Math.max(max, step.id),
      -1
    );
    const newId = maxId + 1;

    setCurrentStepConfig({
      id: newId,
      // emailType: '',
      from: [],
      message: {
        type: 'email',
        subject: '',
        body: '',
      },
      waitTime: 1,
      aiGenerated: false,
      actions: [],
      name: '',
      templateNameForWhatsapp: '',
      useAi: {
        create: false,
        modify: false,
      },
    });

    setOpenModal(true);
  };

  const editStep = (step: StepConfig, id: number) => {
    setCurrentStepConfig(step);
    setOpenModal(true);
  };

  const deleteStep = (id: number) => {
    if (!campaignData) return;
    const updatedSequenceSteps = campaignData.stepConfig.filter(
      (step) => step.id !== id
    );
    setStoreState({
      campaignData: {
        ...campaignData,
        stepConfig: updatedSequenceSteps,
      },
    });
  };

  const setSelectedActions = (
    selectedActions: string | string[],
    stepIdx?: number
  ) => {
    console.log('Selected index = ', stepIdx);
    console.log('Total number of actions = ', campaignData?.stepConfig.length);
    let selectedActionsParsed = [];

    if (typeof selectedActions === 'string') {
      selectedActionsParsed = selectedActions.split(',');
    } else {
      selectedActionsParsed = selectedActions;
    }

    // TODO logic to be refined
    if (typeof stepIdx !== 'number') {
      console.log('Step Index not found');
      // @ts-ignore
      setCurrentStepConfig((prevState) => ({
        ...(prevState ?? {}),
        actions: selectedActionsParsed,
      }));
      setStoreState({
        // @ts-ignore
        campaignData: {
          ...campaignData,
          metadata: { actions: selectedActionsParsed },
        },
      });
    } else {
      console.log('Step Index found: ', stepIdx);
      console.log('Selected Actions = ');
      console.log(selectedActionsParsed);
      console.log('Updating step config for step: ', stepIdx);
      const updatedStepConfig = campaignData?.stepConfig.map(
        (config, stepConfigEntryIdx) => {
          if (stepIdx === config.id) {
            return { ...config, actions: selectedActionsParsed };
          } else return config;
        }
      );
      if (updatedStepConfig) {
        setCurrentStepConfig((prevState) => ({
          ...prevState,
          actions: selectedActionsParsed,
        }));
        setStoreState({
          // @ts-ignore
          campaignData: {
            ...campaignData,
            metadata: { actions: selectedActionsParsed },
            stepConfig: updatedStepConfig,
          },
        });
      }
    }
  };

  const saveStepInfo = (id: number) => {
    if (!currentStepConfig) return;
    if (!campaignData) return;

    setStoreState({
      campaignData: {
        ...campaignData,
        stepConfig: campaignData.stepConfig.some((step) => step.id === id)
          ? campaignData.stepConfig.map((step) =>
              step.id === id ? currentStepConfig : step
            )
          : [...campaignData.stepConfig, currentStepConfig],
      },
    });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Campaign Sequence
      </Typography>
      <Stepper orientation="vertical">
        <Step>
          <StepLabel>Start</StepLabel>
        </Step>
        {campaignData?.stepConfig.map((step, idx) => (
          <Step key={step.id}>
            <StepLabel>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                alignContent={'baseline'}
              >
                <Typography>
                  {idx === 0 ? 'Warmup Content' : `Follow-Up Content ${idx}`}
                </Typography>
                <Typography>via</Typography>
                <Box>
                  <ActionSelectorBox
                    setActions={setSelectedActions}
                    stepConfigIdx={idx}
                    actions={campaignData.stepConfig[idx]?.actions || []}
                    allActions={AVAILABLE_INTEGRATIONS}
                  />
                  <IconButton onClick={() => editStep(step, idx)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteStep(step.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </StepLabel>
            <StepContent>
              <Typography>
                Wait Time: {step.waitTime}{' '}
                {step.waitTime === 1 ? 'Day' : 'Days'}
              </Typography>
              <Typography>Subject:</Typography>
              <Box p={2}>{step.message.subject}</Box>
            </StepContent>
          </Step>
        ))}
        <Step>
          <StepLabel>
            <Stack
              direction={'row'}
              display="flex"
              gap={2}
              alignContent={'baseline'}
            >
              <Button onClick={handleAddStep} variant="contained">
                {campaignData?.stepConfig.length === 0
                  ? 'Add Warmup'
                  : 'Add Follow-Up'}
              </Button>
              {/* <Typography>via</Typography> */}
              {/* <ActionSelectorBox
                // stepConfigIdx={totalStepsCount}
                allActions={AVAILABLE_INTEGRATIONS}
                actions={[]}
                setActions={setSelectedActions}
              /> */}
            </Stack>
          </StepLabel>
        </Step>
      </Stepper>
      <StepsEditor
        selectedLeads={selectedLeads}
        openModal={openModal}
        setOpenModal={setOpenModal}
        currentStep={currentStepConfig}
        // @ts-ignore
        setCurrentStep={setCurrentStepConfig}
        onSave={(id) => saveStepInfo(id)}
      />
    </Box>
  );
}

export default CampaignSequence;
