import { tunerOptions } from '@/constants/tuner_options';
import { TunerRenderProps } from '@/types/components/converse/messages';
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import useTheme from '@mui/material/styles/useTheme';

/**
 * TunerRender component renders a list of selected tuner options as chips.
 * Each chip can be deleted to remove the corresponding tuner option.
 *
 * @component
 * @param {TunerRenderProps} props - The props for the TunerRender component.
 * @param {string[]} props.selectedTuners - An array of selected tuner keywords.
 * @param {React.Dispatch<React.SetStateAction<string[]>>} props.setSelectedTuners - Function to update the selected tuners.
 * @returns {JSX.Element} The rendered TunerRender component.
 */
function TunerRender({
  selectedTuners,
  setSelectedTuners,
}: TunerRenderProps): React.ReactNode {
  const theme = useTheme();
  return (
    <Stack direction={'row'} spacing={0.5}>
      {selectedTuners.map((tuner, index) => {
        const tunerName = tunerOptions.find((t) => t.keyword === tuner)?.name;
        return (
          <Chip
            key={tuner}
            label={tunerName}
            onDelete={() => {
              setSelectedTuners(selectedTuners.filter((t) => t !== tuner));
            }}
            color="primary"
            sx={{
              fontWeight: 600,
              outline: '1px solid',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              bottom: -15,
              borderRadius: 0,
              paddingTop: 2,
              paddingBottom: 4,
              borderTopLeftRadius: index === 0 ? 15 : 5,
              borderTopRightRadius:
                index === selectedTuners.length - 1 ? 15 : 5,
            }}
          />
        );
      })}
    </Stack>
  );
}

export default TunerRender;
