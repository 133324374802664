import { ContextNode } from '@/types/context/context-page';
import { create } from 'zustand';

export interface ContextStoreType {
  selectedNodes: ContextNode[];
  contextNodes: ContextNode[];
  deletedNodeIds: Set<string>;
  setStoreState: (newState: Partial<ContextStoreType>) => void;
}

const useContextStore = create<ContextStoreType>((set) => ({
  selectedNodes: [],
  contextNodes: [],
  deletedNodeIds: new Set(),
  setStoreState: (newState: Partial<ContextStoreType>) => {
    set((state) => ({ ...state, ...newState }));
  },
}));

export default useContextStore;
