import { ModalHeader, StyledButton, StyledModal } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { EventData } from '@/types/calendarEvent';
import sendEmail from '@/utils/email/sendEmail';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { Close, Event } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import Moment from 'react-moment';

type CheckMailModalProps = {
  open: boolean;
  onClose: () => void;
  eventData: EventData;
  setEventData: React.Dispatch<React.SetStateAction<EventData>>;
};

const ModalContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  maxHeight: '80vh',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  gap: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));

function ScheduleEventModal({
  open,
  onClose,
  eventData,
  setEventData,
}: CheckMailModalProps): React.ReactNode {
  const [isScheduling, setIsScheduling] = useState<boolean>(false);
  const { addLogs } = useLogging();
  const setStoreState = useAlchemystStoreForAi((state) => state.setStoreState);
  const snackbarEntries = useAlchemystStoreForAi(
    (state) => state.snackbarEntries
  );

  const handleConfirm = async (): Promise<void> => {
    setIsScheduling(true);

    try {
      const response = await fetchWithRewrites('/api/calendar/native/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eventData),
        signal: createTimeoutSignal(),
      });

      console.log('Response status = ', response.status);

      if (!response.ok) {
        throw new Error('Failed to confirm mail');
      }

      const res = await sendEmail(eventData);
      if (!res) {
        return;
      }

      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: 'Event scheduled successfully!',
            severity: 'success',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });

      // Log the event
      addLogs([
        {
          message: `Event scheduled for ${eventData?.subject}`,
          timestamp: Date.now(),
          context: 'calendar:scheduled-event',
          metadata: { event: eventData },
        },
      ]);
      setIsScheduling(false);
      setTimeout(onClose, 1000);
    } catch (error) {
      console.error('Failed to schedule event:', error);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: 'Failed to schedule event',
            severity: 'error',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      setIsScheduling(false);
    } finally {
      setIsScheduling(false);
    }
  };

  const handleFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEventData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInviteesChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    const invitees = value.split(',').map((invitee) => invitee.trim());
    setEventData((prevData) => ({
      ...prevData,
      invitees,
    }));
  };

  const handleClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="schedule-event-modal"
      aria-describedby="schedule-event-modal-description"
    >
      <ModalContent>
        <ModalHeader>
          <Typography variant="h5">Schedule Event</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </ModalHeader>
        <TextField
          name="subject"
          label="Subject"
          value={eventData?.subject}
          onChange={handleFieldChange}
          fullWidth
        />
        <TextField
          name="description"
          label="Description"
          value={eventData?.description ?? ''}
          onChange={handleFieldChange}
          fullWidth
          multiline
          rows={4}
        />
        <TextField
          name="meetingLink"
          label="Meeting Link"
          value={eventData?.meetingLink}
          onChange={handleFieldChange}
          fullWidth
        />
        <Box>
          <Typography variant="inherit" color={'gray'}>
            Start Time
          </Typography>
          <Moment format="YYYY-MM-DD HH:mm">{eventData?.startTime}</Moment>
        </Box>
        <Box>
          <Typography variant="inherit" color={'gray'}>
            End Time
          </Typography>
          <Moment format="YYYY-MM-DD HH:mm">{eventData?.endTime}</Moment>
        </Box>
        <TextField
          name="invitees"
          label="Invitees"
          value={eventData?.invitees.join(', ')}
          onChange={handleInviteesChange}
          fullWidth
        />
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          disabled={isScheduling}
          startIcon={isScheduling ? <CircularProgress size={20} /> : <Event />}
        >
          {isScheduling ? 'Scheduling...' : 'Schedule'}
        </StyledButton>
      </ModalContent>
    </StyledModal>
  );
}

export default ScheduleEventModal;
