import { prospectorDefaultSettings } from '@/constants/leads';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { bigQueryLeadDataType } from '@/types/bigQuery/bigQueryLeadData';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { prettyPrintObject } from '@/utils/leads';
import { SystemUpdateAlt } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useNavigate } from 'raviger';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const LeadsFilter = ({ table }: { table?: boolean }) => {
  const navigate = useNavigate();

  const [fetchingLeads, setFetchingLeads] = useState<boolean>(false);
  const [moreLeadsCount, setMoreLeadsCount] = useState(0);
  const { localLeads, setStoreState, bigQuery, snackbarEntries, leadSets } =
    useAlchemystStoreForAi((store) => ({
      fields: store.fields,
      localLeads: store.localLeads,
      setStoreState: store.setStoreState,
      bigQuery: store.bigQuery,
      snackbarEntries: store.snackbarEntries,
      leadSets: store.leadSets,
    }));
  const [fields, setFields] = useState();
  const [isImporting, setIsImporting] = useState(false);
  const [localFields, setLocalFields] = useState<string[]>([]);

  if (!bigQuery) {
    return;
  }

  const [localProspectorQueryState, setLocalProspectorQueryState] =
    useState<bigQueryLeadDataType>({
      searchParams: {
        ...bigQuery?.searchParams,
      },
    });

  const setLocalProspectorQuery = (prospectorQ: bigQueryLeadDataType): void => {
    setLocalProspectorQueryState(prospectorQ);
  };

  const prospectorQueryChanged = (): boolean => {
    return (
      JSON.stringify(bigQuery) !== JSON.stringify(localProspectorQueryState)
    );
  };

  const handleFetchMoreLeads = async (count: number): Promise<void> => {
    setStoreState({
      leads: [],
      fields: [],
      localFields: [],
      localLeads: [],
    });
    let offset = 100;
    if (prospectorQueryChanged()) {
      offset = 0;
    }
    const requestBody = {
      count,
      offset,
      filters: localProspectorQueryState,
    };

    setFetchingLeads(true);
    const leadsResponse = await fetchWithRewrites('/api/leads/fetch-alt', {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });

    if (leadsResponse.ok) {
      const leadsData: {
        data: Record<string, Record<string, any>>;
        statusText: string;
      } = await leadsResponse.json();

      const leadsArray = leadsData?.data ? Object.values(leadsData.data) : [];

      if (leadsArray.length === 0) {
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              message: 'No more leads to fetch',
              notificationId: crypto.randomUUID(),
              severity: 'info',
              type: 'alert',
              // autoHideDuration: 5000,
              // variant: 'determinate',
            },
          ],
        });
      }
      setStoreState({
        fields: prospectorDefaultSettings,
        localFields: prospectorDefaultSettings,
        localLeads: [...leadsArray],
      });
    }
    setFetchingLeads(false);
  };

  const onImportData = (): void => {
    setIsImporting(true);
    const localLeadsWithIds = localLeads.map((lead, index) => ({
      ...lead,
      id: index,
    }));
    const newLeadSetId = uuidv4();
    setStoreState({
      leads: [...localLeadsWithIds],
      leadSets: {
        ...leadSets,
        [newLeadSetId]: {
          leads: [...localLeadsWithIds],
          fields: [...localFields],
          selectedLeads: [],
          fileName: `file-${newLeadSetId}`,
          dateAdded: new Date(),
        },
      },
      activeLeadSetId: newLeadSetId,
      selectedLeads: [],
    });
    setTimeout(() => {
      setIsImporting(false);
      navigate('/leads');
    }, 1000);
  };

  return (
    <Stack spacing={3}>
      <StyledButton
        sx={{
          width: '30%',
        }}
        color="primary"
        onClick={onImportData}
        startIcon={
          isImporting ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <SystemUpdateAlt />
          )
        }
        variant="contained"
      >
        Import Data to Leads
      </StyledButton>
      <Stack spacing={2}>
        <Typography>Filter Settings</Typography>
        <Box>
          {bigQuery
            ? Object.keys(localProspectorQueryState.searchParams)
                .filter((key) => !['page', 'per_page'].includes(key))
                .map((key) => (
                  <TextField
                    key={key}
                    label={(key[0].toUpperCase() + key.slice(1))
                      .split('_')
                      .join(' ')}
                    onChange={(e) => {
                      setLocalProspectorQuery({
                        searchParams: {
                          ...localProspectorQueryState.searchParams,
                          [key]: Array.isArray(
                            localProspectorQueryState.searchParams[
                              key as keyof typeof localProspectorQueryState.searchParams
                            ]
                          )
                            ? e.target.value.split(',')
                            : e.target.value,
                        },
                      });
                    }}
                    value={
                      Array.isArray(
                        localProspectorQueryState.searchParams[
                          key as keyof typeof localProspectorQueryState.searchParams
                        ]
                      )
                        ? ((localProspectorQueryState.searchParams[
                            key as keyof typeof localProspectorQueryState.searchParams
                          ] as string[] | undefined) ?? [].join(','))
                        : (localProspectorQueryState.searchParams[
                            key as keyof typeof localProspectorQueryState.searchParams
                          ] ?? [])
                    }
                  />
                ))
            : null}
        </Box>
      </Stack>
      <Box alignItems="center" display="flex" gap={2}>
        <Typography>Fetch</Typography>
        <TextField
          onChange={(e) => {
            setMoreLeadsCount(parseInt(e.target.value));
          }}
          type="number"
          value={moreLeadsCount}
        />
        <Typography>leads</Typography>
        <StyledButton
          disabled={fetchingLeads}
          onClick={async () => {
            await handleFetchMoreLeads(moreLeadsCount);
          }}
          variant="contained"
        >
          {fetchingLeads ? 'Fetching Leads...' : 'Go'}
        </StyledButton>
      </Box>
      {!!table && !fetchingLeads && localLeads.length > 0 ? (
        <Box
          sx={{
            scroll: 'auto',
            alignContent: 'center',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <DataGridPro
            // autoPageSize
            // autosizeOnMount
            columns={Object.keys(localLeads[0] ?? {}).map((key) => ({
              id: key,
              field: key,
              headerName: key,
              width: 150,
            }))}
            getRowId={(row) => {
              return row.id ?? String(Math.random());
            }}
            pageSizeOptions={[25, 50, 100]}
            pagination
            rows={localLeads.map((item: Record<string, any>) =>
              Object.keys(localLeads[0] ?? {}).reduce(
                (acc: Record<string, any>, field) => ({
                  ...acc,
                  [field]: prettyPrintObject(item[field]),
                }),
                {}
              )
            )}
            // rowSelection
            // checkboxSelection
            slots={{ toolbar: GridToolbar }}
            sx={{
              width: '100%',
              height: '60vh',
              scrollBehavior: 'smooth',
              scroll: 'auto',
            }}
          />
        </Box>
      ) : null}
      {!!table && fetchingLeads ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}
    </Stack>
  );
};

export default LeadsFilter;
