import { Stack, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import BatchScraper from './BatchScraper';
import IndividualScraper from './IndividualScraper';

const ParentScraperComponent = ({ mode }: { mode: 'batch' | 'individual' }) => {
  const [scrapingMode, setScrapingMode] = useState<typeof mode>('batch');

  return (
    <Stack spacing={2}>
      <Stack flex={1} direction={'row'}>
        <Typography variant="body1">Individual</Typography>
        <Switch
          checked={scrapingMode === 'batch'}
          onChange={() =>
            setScrapingMode((prevMode) =>
              prevMode === 'batch' ? 'individual' : 'batch'
            )
          }
        />
        <Typography variant="body1">Batch</Typography>
      </Stack>
      {scrapingMode === 'individual' ? <IndividualScraper /> : <BatchScraper />}
    </Stack>
  );
};

export default ParentScraperComponent;
