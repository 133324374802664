import AugmentComponent from '@/components/Augment';
import CampaignParent from '@/components/Campaigns/CampaignParent';
import { CampaignBuilder } from '@/components/Campaigns/builder';
import CampaignDetails from '@/components/Campaigns/details/CampaignDetails';
import CommunicationsPage from '@/components/Communications';
import { ChatMainLayout } from '@/components/Converse';
import Dashboard from '@/components/Dashboard/Dashboard';
import AutoEmailLayout from '@/components/Emails/AutoEmailLayout';
import InboxLayout from '@/components/Inbox/InboxLayout';
import InstructionsComponent from '@/components/Instructions/InstructionsComponent';
import Integrations from '@/components/Integrations/Integrations';
import LeadsFilterSettings from '@/components/Leads/LeadsFilterSettings';
import PayUp from '@/components/PayUp';
import Workbench from '@/components/ai-native/WorkX/Workbench';
import AppLayout from '@/components/layout/AppLayout';
import ProfilePage from '@/components/profile/page';
import SettingsPage from '@/components/settings/SettingPage';
import SignInPage from '@/pages/signIn/page';
import { Container } from '@mui/material';
import { Redirect, useNavigate } from 'raviger';
import LeadsComponent from '../components/Leads/LeadsComponent';
import ContextPage from '../pages/context/page';
import PageNotFound from '../pages/not-found/page';
import LeadsFilter from '@/components/Leads/LeadsFilter';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const navigate = useNavigate();

  const match = /(^| )accessToken=([^;]+)/.exec(document.cookie);

  if (!match) {
    navigate('/auth');
  }

  return <AppLayout>{children}</AppLayout>;
}

export const routes = {
  '/auth': () => <SignInPage />,
  '/': () => (
    <ProtectedRoute>
      <Redirect to="/dashboard" />
      {/* <UserButon /> */}
    </ProtectedRoute>
  ),
  '/payup': () => <PayUp />,
  '/augment': () => (
    <ProtectedRoute>
      <AugmentComponent />
    </ProtectedRoute>
  ),
  '/campaigns': () => (
    <ProtectedRoute>
      <CampaignParent />
    </ProtectedRoute>
  ),
  '/campaigns/builder': () => (
    <ProtectedRoute>
      <CampaignBuilder />
    </ProtectedRoute>
  ),
  '/campaigns/:id': ({ id }: { id: string }) => (
    <ProtectedRoute>
      <CampaignDetails campaignId={id} />
    </ProtectedRoute>
  ),
  '/inbox': () => (
    <ProtectedRoute>
      <InboxLayout />
    </ProtectedRoute>
  ),
  '/email-queue': () => (
    <ProtectedRoute>
      <AutoEmailLayout />
    </ProtectedRoute>
  ),
  '/settings': () => (
    <ProtectedRoute>
      <SettingsPage />
    </ProtectedRoute>
  ),
  '/converse': () => (
    <ProtectedRoute>
      <ChatMainLayout />
    </ProtectedRoute>
  ),
  '/dashboard': () => (
    <ProtectedRoute>
      <Dashboard />
    </ProtectedRoute>
  ),
  '/integrations': () => (
    <ProtectedRoute>
      <Integrations />
    </ProtectedRoute>
  ),
  '/prospector': () => (
    <ProtectedRoute>
      {/* <Prospector /> */}
      <Container>
        {/* <LeadsFilterSettings table /> */}
        <LeadsFilter table />
      </Container>
    </ProtectedRoute>
  ),
  '/leads': () => (
    <ProtectedRoute>
      <LeadsComponent />
    </ProtectedRoute>
  ),
  '/context': () => (
    <ProtectedRoute>
      <ContextPage />
    </ProtectedRoute>
  ),
  '/workx': () => (
    <ProtectedRoute>
      <Workbench components={[]} setComponents={() => {}} />
    </ProtectedRoute>
  ),
  '/instructions': () => (
    <ProtectedRoute>
      <InstructionsComponent />
    </ProtectedRoute>
  ),
  '/profile': () => (
    <ProtectedRoute>
      <ProfilePage />
    </ProtectedRoute>
  ),
  '/communications': () => (
    <ProtectedRoute>
      <CommunicationsPage />
    </ProtectedRoute>
  ),
  '*': () => <PageNotFound />,
};
