import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import type { FeaturesProps } from './types';

const Features: React.FC<FeaturesProps> = ({
  name,
  onChange,
  value = [{ painpoint: '', solution: '' }],

  sx,
}) => {
  const [features, setFeatures] = useState<
    {
      painpoint: string;
      solution: string;
    }[]
  >(value);

  const [errors, setErrors] = useState<
    {
      painpoint: boolean;
      solution: boolean;
    }[]
  >([{ painpoint: false, solution: false }]);

  // Handle feature updates
  const updateFeature = (
    index: number,
    key: 'painpoint' | 'solution',
    value: string
  ) => {
    const updatedFeatures = [...features];
    if (updatedFeatures[index]) {
      updatedFeatures[index][key] = value;
    }

    const updatedErrors = [...errors];
    if (updatedErrors[index]) {
      updatedErrors[index][key] = !value.trim();
    }

    setFeatures(updatedFeatures);
    setErrors(updatedErrors);
    onChange(updatedFeatures);
  };

  // Add a new feature
  const addFeature = () => {
    setFeatures([...features, { painpoint: '', solution: '' }]);
    setErrors([...errors, { painpoint: false, solution: false }]);
  };

  // Remove a feature
  const deleteFeature = (index: number) => {
    const updatedFeatures = features.filter((_, i) => i !== index);
    const updatedErrors = errors.filter((_, i) => i !== index);

    setFeatures(updatedFeatures);
    setErrors(updatedErrors);
    onChange(updatedFeatures);
  };

  // Validate all fields before adding a new feature
  const canAddFeature = () => {
    const currentErrors = features.map((feature) => ({
      painpoint: !feature.painpoint.trim(),
      solution: !feature.solution.trim(),
    }));
    setErrors(currentErrors);
    return !currentErrors.some((error) => error.painpoint || error.solution);
  };

  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography color="text.primary" variant="h6">
        {name}
      </Typography>
      {features.map((feature, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              flex: 1,
            }}
          >
            <TextField
              error={errors[index]?.painpoint || false}
              fullWidth
              helperText={
                errors[index]?.painpoint ? 'Painpoint is required.' : ''
              }
              label="Painpoint"
              onChange={(event) => {
                updateFeature(index, 'painpoint', event.target.value);
              }}
              value={feature.painpoint}
            />
            <TextField
              error={errors[index]?.solution || false}
              fullWidth
              helperText={
                errors[index]?.solution ? 'Solution is required.' : ''
              }
              label="Solution"
              onChange={(event) => {
                updateFeature(index, 'solution', event.target.value);
              }}
              value={feature.solution}
            />
          </Box>
          <IconButton
            aria-label="Delete feature"
            color="error"
            disabled={features.length === 1}
            onClick={() => {
              deleteFeature(index);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        color="primary"
        disabled={features.some(
          (feature) => !feature.painpoint.trim() || !feature.solution.trim()
        )}
        onClick={() => canAddFeature() && addFeature()}
        startIcon={<AddIcon />}
        sx={{ width: 'fit-content' }}
        variant="contained"
      >
        Add Painpoints
      </Button>
    </Box>
  );
};

export default Features;
