import { CircularProgressMessage } from '@/components/animations/progress/circular';
import MuiXLicense from '@/components/MuiXLicense';
import { darkTheme, lightTheme } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CssBaseline, Snackbar, ThemeProvider } from '@mui/material';
import Alert from '@mui/material/Alert/Alert';
import Box from '@mui/material/Box/Box';
import {} from 'raviger';
import '../../globals.css';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  // const [progress, setProgress] = useState(10);

  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );

  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  // const { getLogs, setLogs } = useLogging();

  // useEffect(() => {
  //   if (window) {
  //     window.onbeforeunload = (e) => {
  //       e.preventDefault();
  //       const logs = getLogs();
  //       console.log('Saving logs...');

  //       const sendLogs = async () => {
  //         const response = await fetch('/api/logs', {
  //           body: JSON.stringify({ logs }),
  //           method: 'POST',
  //           signal: createTimeoutSignal(),
  //         });

  //         if (response.status === 201) {
  //           console.log('Logs sent successfully.');
  //           setLogs((_) => []);
  //           window.onbeforeunload = null; // Allow the unload to proceed

  //           window.location.reload(); // Trigger the page unload
  //         }
  //       };

  //       sendLogs();
  //       return false; // Prevent the unload until logs are sent
  //     };
  //   }
  // }, []);

  const handleClose = (snackbarEntry: (typeof snackbarEntries)[0]) => {
    setStoreState({
      snackbarEntries: snackbarEntries.filter(
        (entry) => entry.notificationId !== snackbarEntry.notificationId
      ),
    });
  };
  return (
    <html lang="en">
      <head>
        <title>Platform - Alchemyst.ai</title>
        <meta content="Alchemyst Platform" name="description" />
        <base href="." />
        <link href="/favicon.ico" rel="icon" />
      </head>
      <body>
        <ThemeProvider theme={currentTheme === 'dark' ? darkTheme : lightTheme}>
          <CssBaseline />
          <MuiXLicense />
          {children}
          <Snackbar
            autoHideDuration={6000}
            open={true}
            sx={{
              zIndex: 99999,
              width: '20vw',
              scrollMarginBlock: 2,
            }}
          >
            <Box
              flexDirection="column"
              marginTop={3}
              onFocus={(e) => {
                e.persist();
              }}
              sx={{
                maxHeight: '20vh',
                scrollBehavior: 'smooth',
                overflowY: 'scroll',
                scrollbarWidth: 1,
              }}
            >
              {snackbarEntries.map((snackbarEntry, idx) => {
                switch (snackbarEntry.type) {
                  case 'alert':
                    return (
                      <Alert
                        key={`alert-${idx + 1}`}
                        onClose={() => handleClose(snackbarEntry)}
                        severity={snackbarEntry.severity}
                        sx={{
                          width: '100%',
                          marginY: 1,
                          textOverflow: 'ellipsis',
                          zIndex: 99999,
                        }}
                      >
                        {snackbarEntry.message}
                      </Alert>
                    );
                  case 'progress':
                    return (
                      <CircularProgressMessage
                        key={`alert-${idx + 1}`}
                        message={snackbarEntry.message}
                        onClose={() => handleClose(snackbarEntry)}
                        value={snackbarEntry.value ?? 0}
                        sx={{
                          zIndex: 99999,
                        }}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Box>
          </Snackbar>
        </ThemeProvider>
      </body>
    </html>
  );
}
