import type { AlchemystProps } from '../types/appStates';

export const defaultAlchemysts: AlchemystProps[] = [
  {
    persona: 'maya',
    avatarUrl:
      'https://alchemyst-updated.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmaya.57854fbf.png&w=256&q=75',
    name: 'Maya',
    role: 'Sales Development Representative',
  },
  // {
  //   persona: 'moh',
  //   avatarUrl:
  //     'https://alchemyst-updated.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmoh.7fea4676.png&w=256&q=75',
  //   name: 'Moh',
  //   role: 'Marketer',
  // },
  {
    persona: 'ron',
    avatarUrl:
      'https://alchemyst-updated.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fmoh.7fea4676.png&w=256&q=75',
    name: 'Ron',
    role: 'Customer Support',
  },
];
