import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import MarketingCampaign from './MarketingCampaign';
import SalesCampaign from './SalesCampaign';

function CampaignParent() {
  const { alchemystPersona } = useAlchemystStoreForAi((store) => ({
    alchemystPersona: store.alchemyst,
  }));

  switch (alchemystPersona.name.toLowerCase()) {
    case 'maya':
      return <SalesCampaign />;
    case 'ron':
      return <MarketingCampaign />;
    default:
      return (
        <div>Couldn&apos;t find relevant campaigns for the Alchemyst.</div>
      );
  }
}

export default CampaignParent;
