import { RightSidePanel } from '@/common/Sidepanel';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { ChatHistoryContainer } from './history';
import { ChatMessagesContainer } from './messages';

/**
 * ChatMainLayout component
 *
 * This component is the main layout for the chat application.
 * It contains the chat history panel on the right side and the main chat messages area.
 * The chat history panel can be toggled open and closed, and it displays the chat history.
 */
function ChatMainLayout(): React.ReactNode {
  const [open, setOpen] = useState<boolean>(true); // Controls the visibility of the side panel
  const [chatHistoryLoading, setChatHistoryLoading] = useState<boolean>(false);
  const [currentChatID, setCurrentChatID] = useState<string>('');
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const [chatChannel, setChatChannel] = useState<string>('platform.converse');
  const panelWidth = '20vw'; // Width of the right-side panel

  return (
    <Box>
      {/* Right side panel containing chat history */}
      <RightSidePanel open={open} setOpen={setOpen} panelWidth={panelWidth}>
        <ChatHistoryContainer
          currentChatID={currentChatID}
          setCurrentChatID={setCurrentChatID}
          loading={chatHistoryLoading}
        />
      </RightSidePanel>

      {/* Main content area that adjusts based on sidebar visibility */}
      <Box
        marginRight={open ? panelWidth : 0}
        maxWidth={open ? `calc(100% - ${panelWidth})` : '100%'}
      >
        <ChatMessagesContainer
          currentChatID={currentChatID}
          setCurrentChatID={setCurrentChatID}
        />
      </Box>
    </Box>
  );
}

export default ChatMainLayout;
