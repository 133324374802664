import { StyledButton } from '@/constants/themes';
import type { StepConfig } from '@/types/campaign';
import { Box, Modal, Stack, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import TextSpaceTab from './TextSpaceTab';
import VoiceSpaceTab from './VoiceSpaceTab';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import CircularProgress from '@mui/material/CircularProgress';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';

export interface StepsEditorProps {
  selectedLeads: Record<string, any>[];
  openModal: boolean;
  setOpenModal: (setter: boolean) => void;
  currentStep: StepConfig | null;
  setCurrentStep: (editedConfig: StepConfig | null) => void;
  onSave: (id: number) => void;
}

function StepsEditor({
  selectedLeads,
  openModal,
  setOpenModal,
  currentStep,
  setCurrentStep,
  onSave,
}: StepsEditorProps): React.ReactNode {
  const { channels } = useCampaignBuilderStore((store) => ({
    channels: store.campaignData?.channels,
  }));

  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const [savingStep, setSavingStep] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    console.log('THE CHANNELS', channels);
    if (channels?.whatsapp.length === 0) {
      setSaveDisabled(false);
      return;
    }

    if (currentStep?.templateNameForWhatsapp === '') {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }

    // if (currentStep?.templateNameForWhatsapp) {
    //   console.log('CURRENT STEPS', currentStep);
    //   setSaveDisabled(currentStep.templateNameForWhatsapp.trim() === '');
    // } else {
    //   setSaveDisabled(false);
    // }
  }, [currentStep?.templateNameForWhatsapp, channels]);

  const handleSaveStep = async () => {
    if (channels?.whatsapp.length !== 0) {
      setSavingStep(true);

      const res = await fetchWithRewrites('/api/whatsapptemplates/create', {
        method: 'POST',
        body: JSON.stringify({
          name: currentStep?.templateNameForWhatsapp,
          phoneNumbers: channels?.whatsapp,
        }),
      });
      const data = await res.json();
      console.log('THE API DATA', data);

      if (!res.ok) {
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              severity: 'error',
              message: 'Template could not be created.',
              type: 'alert',
              notificationId: crypto.randomUUID(),
            },
          ],
        });
      } else {
        setStoreState({
          snackbarEntries: [
            ...snackbarEntries,
            {
              severity: 'success',
              message: 'Template created successfully.',
              type: 'alert',
              notificationId: crypto.randomUUID(),
            },
          ],
        });
      }
    }

    setSavingStep(false);
    onSave(currentStep ? currentStep.id : 0);
    setOpenModal(false);
    setCurrentStep(null);
  };

  const [modality, setModality] = useState<'text' | 'voice'>('text');
  const [usingAiFunctions, setUsingAiFunctions] = useState<
    'idle' | 'rephrasing' | 'generating'
  >('idle');
  //TODO should change forcing state update for the time being
  const [editorKey, setEditorKey] = useState<number>(0);
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);

  useEffect(() => {
    async function fetchTemplates() {
      const res = await fetchWithRewrites('/api/whatsapptemplates');
      const data = await res.json();
      if (res.ok) {
        setWhatsappTemplates(data.templates);
      }
    }

    fetchTemplates();
  }, []);

  if (!currentStep) {
    return null;
  }

  const handleRichTextChange = (
    content: string,
    generatedUsingAI?: boolean
  ): void => {
    if (!!currentStep) {
      setCurrentStep({
        ...currentStep,
        aiGenerated: generatedUsingAI ?? false,
        message: { ...currentStep.message, body: content },
      });
    }
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: 'text' | 'voice'
  ) => {
    setModality(newValue);
  };

  return (
    <Modal
      onClose={() => {
        setOpenModal(false);
      }}
      open={openModal}
    >
      <Box
        borderRadius="8px"
        px={4}
        py={2}
        justifyContent={'space-between'}
        sx={{
          backgroundColor: 'background.paper',
          width: '80%',
          height: '80%',
          mx: 'auto',
          my: '5%',
          boxShadow: 24,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Stack my={4} spacing={3} sx={{ overflow: 'auto' }}>
          <Stack direction={'row'} spacing={3}>
            <Tabs
              aria-label="context-visualizer-tabs"
              onChange={handleTabChange}
              role="navigation"
              value={modality}
            >
              <Tab label="text" value="text" />
              <Tab label="voice" value="voice" />
            </Tabs>
          </Stack>
          <Box>
            {modality === 'text' && (
              <TextSpaceTab
                currentStep={currentStep}
                editorKey={editorKey}
                handleRichTextChange={handleRichTextChange}
                handleSaveStep={handleSaveStep}
                selectedLeads={selectedLeads}
                setCurrentStep={setCurrentStep}
                setEditorKey={setEditorKey}
                setOpenModal={setOpenModal}
                setUsingAiFunctions={setUsingAiFunctions}
                usingAiFunctions={usingAiFunctions}
                templates={whatsappTemplates}
                setTemplateSelected={setSaveDisabled}
              />
            )}
            {modality === 'voice' && (
              <VoiceSpaceTab
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                selectedLeads={selectedLeads}
                setOpenModal={setOpenModal}
                setUsingAiFunctions={setUsingAiFunctions}
                usingAiFunctions={usingAiFunctions}
                key={editorKey}
              />
            )}
          </Box>
        </Stack>
        <Box display="flex" gap={2} justifyContent="flex-end" mt={4}>
          <StyledButton
            onClick={() => {
              setOpenModal(false);
            }}
            variant="text"
          >
            Cancel
          </StyledButton>
          {savingStep ? (
            <CircularProgress size={20} />
          ) : (
            <StyledButton
              disabled={saveDisabled}
              color="primary"
              onClick={handleSaveStep}
              variant="contained"
            >
              Save
            </StyledButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default StepsEditor;
