import useTemplateBuilderStore from '@/hooks/useTemplateBuilderStore';
import type { CampaignWorkflowTemplate } from '@/types/campaign';
import { Field } from '@/types/campaign/field';
import { DragHandle } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import FieldItem from './FieldItem';

interface FieldsListProps {
  activeStepIndex: number;
  updateCurrentCampaignTemplate: (template: CampaignWorkflowTemplate) => void;
}

const FieldsList: React.FC<FieldsListProps> = ({
  activeStepIndex,
  updateCurrentCampaignTemplate,
}) => {
  const [fields, setFields] = useState<Field[]>([]);
  const currentCampaignTemplate = useTemplateBuilderStore(
    (store) => store.currentCampaignTemplate
  );
  if (!currentCampaignTemplate) return null;

  useEffect(() => {
    const _fields = currentCampaignTemplate.steps[activeStepIndex]
      .fields as Field[];
    setFields(_fields);
  }, [activeStepIndex, currentCampaignTemplate]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) return;

    const reorderedFields = [...fields];
    const [movedField] = reorderedFields.splice(source.index, 1);
    reorderedFields.splice(destination.index, 0, movedField);

    // update local state
    setFields(reorderedFields);

    // Ensure step exists before updating
    const updatedCampaign = { ...currentCampaignTemplate };
    const step = updatedCampaign.steps[activeStepIndex];

    if (step) {
      step.fields = reorderedFields;
      updateCurrentCampaignTemplate(updatedCampaign);
    }
  };

  return (
    <Box
      sx={{
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        paddingX: '10%',
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="fields">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, fieldIndex) => (
                <Draggable
                  draggableId={`field-${fieldIndex}`}
                  index={fieldIndex}
                  key={`field-${fieldIndex}`}
                >
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        padding: 2,
                        borderRadius: 2,
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        transition: 'background-color 0.2s ease',
                      }}
                    >
                      <Box
                        {...provided.dragHandleProps}
                        sx={{
                          cursor: 'grab',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DragHandle />
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <FieldItem
                          field={field}
                          index={fieldIndex}
                          fields={fields}
                          setFields={setFields}
                          activeStepIndex={activeStepIndex}
                          onUpdateTemplate={updateCurrentCampaignTemplate}
                        />
                      </Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default FieldsList;
