import { Header, StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import { CampaignWorkflow } from '@/types/campaign';
import {
  CustomSelect,
  CustomSwitch,
  CustomTextArea,
  Features,
  ListOfTextInputs,
  MinMaxRange,
} from '@/ui';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { navigate } from 'raviger';
import { useEffect, useState } from 'react';
import CampaignSequence from '../fields/CampaignSequence';
import CampaignTargets from '../fields/CampaignTargets';
import CommunicationSelector from '../fields/CommunicationSelector';

interface FieldProps {
  name: string;
  theme: 'light' | 'dark';
  value: any;
  onChange: (value: any) => void;
}

function CampaignForm(): React.ReactNode {
  const [activeStep, setActiveStep] = useState<number>(0);
  const campaignData = useCampaignBuilderStore((store) => store.campaignData);

  const setBuilderStoreState = useCampaignBuilderStore(
    (store) => store.setStoreState
  );

  const theme = useAlchemystStoreForAi((store) => store.theme);
  const themeType = (theme === 'dark' ? 'dark' : 'light') as 'dark' | 'light';

  const [creatingCampaign, setCreatingCampaign] = useState(false);

  useEffect(() => {
    if (!campaignData?.steps?.length) return;

    const steps = [...campaignData.steps];
    const lastStep = steps[steps.length - 1];

    if (lastStep.step_name !== 'Settings') {
      // NOTE: Add the sequence step and settings step
      steps.push(
        {
          step_name: 'Target',
          fields: [
            {
              field_type: 'target',
              name: 'Target',
              control_var: 'leads',
            },
          ],
        },
        {
          step_name: 'Channel',
          fields: [
            {
              field_type: 'channel',
              name: 'Channel Selector',
              control_var: 'channel',
            },
          ],
        },
        {
          step_name: 'Sequence',
          fields: [
            {
              field_type: 'sequence',
              name: 'Sequence',
              control_var: 'sequence',
            },
          ],
        },
        { step_name: 'Settings', fields: [] }
      );
      setBuilderStoreState({
        campaignData: {
          ...campaignData,
          steps: steps,
        },
      });
    }
  }, []);

  if (!campaignData) {
    return <Typography variant="h4">Loading...</Typography>;
  }

  // handle next click
  const handleClickNextOrFinish = async () => {
    if (activeStep === campaignData.steps.length - 1) {
      const updatedCampaign: CampaignWorkflow = {
        ...campaignData,
        status: 'not active',
      };

      // console.log('UPDATED CAMPAIGN', updatedCampaign);

      setCreatingCampaign(true);
      const response = await fetchWithRewrites('/api/campaigns/update', {
        method: 'POST',
        body: JSON.stringify({
          ...updatedCampaign,
        }),
      });
      if (!response.ok) {
        console.error('Failed to start campaign');
      }

      const { updatedId } = await response.json();

      if (!updatedId) {
        console.error('No id found');
      }

      setBuilderStoreState({
        campaignData: {
          ...updatedCampaign,
          id: updatedId,
        },
      });
      setCreatingCampaign(false);
      navigate(`/campaigns/${updatedId}`, {
        replace: true,
      });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const renderField = (field: any, fieldIndex: number) => {
    const commonProps: FieldProps = {
      name: field.name,
      theme: themeType,
      value:
        campaignData.steps[activeStep].fields[fieldIndex][field.control_var],
      onChange: (value: any) => {
        const updatedCampaign = { ...campaignData };
        updatedCampaign.steps[activeStep].fields[fieldIndex][
          field.control_var
        ] = value;
        setBuilderStoreState({
          campaignData: updatedCampaign,
        });
      },
    };

    switch (field.field_type) {
      case 'input.select':
        return (
          <CustomSelect
            {...commonProps}
            default={field.default}
            key={fieldIndex}
            large_options={field.large_options || false}
            large_options_key={field.large_options_key || ''}
            multiselect={field.multiselect}
            options={field.options || []}
            placeholder={field.placeholder || ''}
            sx={{ width: '100%' }}
          />
        );

      case 'input.switch':
        return (
          <CustomSwitch
            {...commonProps}
            key={fieldIndex}
            sx={{ width: '100%' }}
          />
        );

      case 'input.textarea':
        return (
          <CustomTextArea
            {...commonProps}
            key={fieldIndex}
            multiline={field.multiline || false}
            placeholder={field.placeholder || ''}
            sx={{ width: '100%' }}
          />
        );

      case 'min_max_range':
        return (
          <MinMaxRange
            {...commonProps}
            default_max={field.default_max || 100}
            default_min={field.default_min || 0}
            key={fieldIndex}
            max_placeholder={field.max_placeholder || 'Max'}
            min_placeholder={field.min_placeholder || 'Min'}
            sx={{ width: '100%' }}
          />
        );

      case 'list_of_text_inputs':
        return (
          <ListOfTextInputs
            {...commonProps}
            key={fieldIndex}
            sx={{ width: '100%' }}
          />
        );

      case 'list_of_features':
        return (
          <Features {...commonProps} key={fieldIndex} sx={{ width: '100%' }} />
        );

      case 'sequence':
        return <CampaignSequence key={fieldIndex} />;

      case 'target':
        return <CampaignTargets key={fieldIndex} />;

      case 'channel':
        return <CommunicationSelector />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        padding: '2rem',
      }}
    >
      <Header>
        <Box flex={1} flexGrow={1} width="100%">
          <Typography gutterBottom variant="h4">
            {campaignData.name}
          </Typography>
          <Typography variant="body2">{campaignData.description}</Typography>
        </Box>
      </Header>

      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ paddingX: '10%' }}
      >
        {campaignData.steps.map((step: any, stepIndex: number) => (
          <Step key={stepIndex}>
            <StepLabel
              StepIconProps={{
                sx: {
                  height: '2.5rem',
                  width: '2.5rem',
                },
              }}
              onClick={() => {
                setActiveStep(stepIndex);
              }}
              sx={{
                cursor: 'pointer',
              }}
            >
              {step.step_name}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      {campaignData.steps[activeStep] ? (
        <Box
          sx={{
            marginTop: '2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            paddingX: '10%',
          }}
        >
          {campaignData.steps[activeStep].fields.map(
            (field: any, fieldIndex: number) => renderField(field, fieldIndex)
          )}
        </Box>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '2rem',
          paddingX: '10%',
        }}
      >
        <StyledButton
          disabled={activeStep === 0}
          onClick={() => {
            setActiveStep(activeStep - 1);
          }}
          size="large"
          startIcon={<ArrowLeft />}
          sx={{
            padding: '10px 20px',
            fontSize: '15px',
            borderRadius: '8px',
          }}
          variant="contained"
        >
          Prev
        </StyledButton>

        <StyledButton
          endIcon={<ArrowRight />}
          onClick={handleClickNextOrFinish}
          size="large"
          sx={{
            padding: '10px 20px',
            fontSize: '15px',
            borderRadius: '8px',
          }}
          variant="contained"
          disabled={
            creatingCampaign && activeStep === campaignData.steps.length - 1
          }
        >
          {activeStep === campaignData.steps.length - 1 ? 'Finish' : 'Next'}
        </StyledButton>
      </Box>
    </Box>
  );
}

export default CampaignForm;
