import { Header, IOSSwitch, StyledButton } from '@/constants/themes/index';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { HourglassEmptyTwoTone, UploadFile } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  Tab,
  IconButton,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { useNavigate } from 'raviger';
import { useEffect, useState } from 'react';
import LeadMapSettings from './LeadMapSettings';
import LeadsCardView from './LeadsCardView';
import LeadsSideSheet from './LeadsSideSheet';
import LeadsTableView from './LeadsTableView';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';

function LeadsComponent(): React.ReactNode {
  const navigate = useNavigate();
  const sideSheetpanelWidth: string = '20vw';
  const [leadsLoading, setLeadsLoading] = useState<boolean>(false);
  const {
    leadSets,
    activeLeadSetId,
    leads,
    fields,
    setStoreState,
    selectedLeads,
  } = useAlchemystStoreForAi((store) => {
    return {
      leadSets: store.leadSets,
      activeLeadSetId: store.activeLeadSetId,
      leads: store.leads,
      fields: store.fields,
      setStoreState: store.setStoreState,
      selectedLeads: store.selectedLeads,
    };
  });
  const [selectedView, setSelectedView] = useState<string>('0');
  const [isCampaignButtonDisabled, setIsCampaignButtonDisabled] =
    useState(true);

  // Get the active lead set or use the legacy leads/fields if no active set
  const activeLeadSet = activeLeadSetId ? leadSets[activeLeadSetId] : null;
  const currentLeads = activeLeadSet ? activeLeadSet.leads : leads;
  const currentFields = activeLeadSet ? activeLeadSet.fields : fields;
  const currentSelectedLeads = activeLeadSet
    ? activeLeadSet.selectedLeads
    : selectedLeads;

  useEffect(() => {
    setStoreState({ isNavBarDrawerOpen: false });
  }, []);

  useEffect(() => {
    async function fetchApiKeyLength() {
      const res = await fetchWithRewrites('/api/settings/api-keys/list');

      if (res.ok) {
        const data = await res.json();
        setIsCampaignButtonDisabled(data.apiKeys.length === 0);
      }
    }

    fetchApiKeyLength();
  }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLeadsLoading(true);
    const file = event.target.files?.[0];
    if (file) {
      // Initialize the worker
      const worker = new Worker('/workers/workers/parsecsv.js');
      worker.postMessage({ file });
      // Handle messages from the worker
      worker.onmessage = (e) => {
        const { leads: parsedLeads, fields: parsedFields } = e.data;

        // Generate a unique ID for this lead set
        const newLeadSetId = `lead-set-${Date.now()}`;

        // Update the store with the new lead set
        setStoreState({
          leadSets: {
            ...leadSets,
            [newLeadSetId]: {
              leads: parsedLeads,
              fields: parsedFields,
              selectedLeads: [],
              fileName: file.name,
              dateAdded: new Date(),
            },
          },
          activeLeadSetId: newLeadSetId,
          // Also update legacy fields for backward compatibility
          leads: parsedLeads,
          fields: parsedFields,
          selectedLeads: [],
        });

        setLeadsLoading(false);
        worker.terminate();
      };
      // Handle worker errors
      worker.onerror = (error) => {
        console.error('Worker error:', error);
        setLeadsLoading(false);
        worker.terminate();
      };
    } else {
      setLeadsLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === 'add-new-tab') {
      // Trigger file upload dialog
      const fileInput = document.getElementById(
        'csv-file-upload'
      ) as HTMLInputElement;
      if (fileInput) {
        fileInput.click();
      }
      return;
    }

    // Clear selected leads from the current tab before switching
    if (activeLeadSetId && leadSets[activeLeadSetId]) {
      setStoreState({
        leadSets: {
          ...leadSets,
          [activeLeadSetId]: {
            ...leadSets[activeLeadSetId],
            selectedLeads: [],
          },
        },
      });
    }

    setStoreState({ activeLeadSetId: newValue });

    // Update legacy fields for backward compatibility
    if (newValue && leadSets[newValue]) {
      setStoreState({
        leads: leadSets[newValue].leads,
        fields: leadSets[newValue].fields,
        selectedLeads: [], // Reset selected leads when switching tabs
      });
    }
  };

  const handleCloseTab = (tabId: string, event: React.MouseEvent) => {
    event.stopPropagation();

    // Create a copy of leadSets without the closed tab
    const updatedLeadSets = { ...leadSets };
    delete updatedLeadSets[tabId];

    // Determine the new active tab
    let newActiveTab: string | null = null;
    if (Object.keys(updatedLeadSets).length > 0) {
      newActiveTab = Object.keys(updatedLeadSets)[0];
    }

    setStoreState({
      leadSets: updatedLeadSets,
      activeLeadSetId: newActiveTab,
    });

    // Update legacy fields for backward compatibility
    if (newActiveTab) {
      setStoreState({
        leads: updatedLeadSets[newActiveTab].leads,
        fields: updatedLeadSets[newActiveTab].fields,
        selectedLeads: updatedLeadSets[newActiveTab].selectedLeads,
      });
    } else {
      setStoreState({
        leads: [],
        fields: [],
        selectedLeads: [],
      });
    }
  };

  const addColumnHandler = () => {
    if (!activeLeadSetId) {
      // Legacy behavior
      const newField = `Column ${fields.length + 1}`;
      const updatedFields = [...fields, newField];
      const updatedLeads = leads.map((lead) => ({ ...lead, [newField]: '' }));
      setStoreState({ fields: updatedFields, leads: updatedLeads });
      return;
    }

    // New behavior with lead sets
    const leadSet = leadSets[activeLeadSetId];
    const newField = `Column ${leadSet.fields.length + 1}`;
    const updatedFields = [...leadSet.fields, newField];
    const updatedLeads = leadSet.leads.map((lead) => ({
      ...lead,
      [newField]: '',
    }));

    setStoreState({
      leadSets: {
        ...leadSets,
        [activeLeadSetId]: {
          ...leadSet,
          fields: updatedFields,
          leads: updatedLeads,
        },
      },
      // Also update legacy fields
      fields: updatedFields,
      leads: updatedLeads,
    });
  };

  const deleteColumnHandler = (fieldToDelete: string) => {
    if (!activeLeadSetId) {
      // Legacy behavior
      const updatedFields = fields.filter((field) => field !== fieldToDelete);
      const updatedLeads = leads.map((lead) => {
        const updatedLead = { ...lead };
        delete updatedLead[fieldToDelete];
        return updatedLead;
      });
      setStoreState({ fields: updatedFields, leads: updatedLeads });
      return;
    }

    // New behavior with lead sets
    const leadSet = leadSets[activeLeadSetId];
    const updatedFields = leadSet.fields.filter(
      (field) => field !== fieldToDelete
    );
    const updatedLeads = leadSet.leads.map((lead) => {
      const updatedLead = { ...lead };
      delete updatedLead[fieldToDelete];
      return updatedLead;
    });

    setStoreState({
      leadSets: {
        ...leadSets,
        [activeLeadSetId]: {
          ...leadSet,
          fields: updatedFields,
          leads: updatedLeads,
        },
      },
      // Also update legacy fields
      fields: updatedFields,
      leads: updatedLeads,
    });
  };

  const updateRowHandler = (params: GridRowModel) => {
    if (!activeLeadSetId) {
      // Legacy behavior
      const updatedLeads = leads.map((lead) =>
        lead.id === params.id ? params : lead
      );
      setStoreState({ leads: updatedLeads });
      return params;
    }

    // New behavior with lead sets
    const leadSet = leadSets[activeLeadSetId];
    const updatedLeads = leadSet.leads.map((lead) =>
      lead.id === params.id ? params : lead
    );

    setStoreState({
      leadSets: {
        ...leadSets,
        [activeLeadSetId]: {
          ...leadSet,
          leads: updatedLeads,
        },
      },
      // Also update legacy fields
      leads: updatedLeads,
    });

    return params;
  };

  const deleteRowsHandler = (rowsToDelete: number[]) => {
    if (!activeLeadSetId) {
      // Legacy behavior
      const updatedLeads = leads
        .filter((lead, index) => !rowsToDelete.includes(index))
        .map((lead, index) => ({ ...lead, id: index }));
      setStoreState({ leads: updatedLeads });
      return;
    }

    // New behavior with lead sets
    const leadSet = leadSets[activeLeadSetId];
    const updatedLeads = leadSet.leads
      .filter((lead, index) => !rowsToDelete.includes(index))
      .map((lead, index) => ({ ...lead, id: index }));

    setStoreState({
      leadSets: {
        ...leadSets,
        [activeLeadSetId]: {
          ...leadSet,
          leads: updatedLeads,
        },
      },
      // Also update legacy fields
      leads: updatedLeads,
    });
  };

  const addRowHandler = () => {
    if (!activeLeadSetId) {
      // Legacy behavior
      const updatedLeads = [...leads, { id: leads.length + 1 }];
      setStoreState({ leads: updatedLeads });
      return;
    }

    // New behavior with lead sets
    const leadSet = leadSets[activeLeadSetId];
    const updatedLeads = [...leadSet.leads, { id: leadSet.leads.length + 1 }];

    setStoreState({
      leadSets: {
        ...leadSets,
        [activeLeadSetId]: {
          ...leadSet,
          leads: updatedLeads,
        },
      },
      // Also update legacy fields
      leads: updatedLeads,
    });
  };

  // Helper to format date for tab display
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
    });
  };

  return (
    <LeadsSideSheet panelWidth={sideSheetpanelWidth}>
      <Header>
        <Box width={'100%'} flex={1} flexGrow={1}>
          <Typography variant="h4" gutterBottom>
            Leads
          </Typography>
          <Typography variant="body2">
            All leads found by Maya and uploaded by you.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <label htmlFor="csv-file-upload">
            <StyledButton
              variant="contained"
              startIcon={<UploadFile />}
              sx={{
                padding: '10px 20px',
                fontSize: '16px',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => {
                const fileInput = document.getElementById(
                  'csv-file-upload'
                ) as HTMLInputElement;
                if (fileInput) {
                  fileInput.click();
                }
              }}
            >
              Upload CSV
            </StyledButton>
          </label>
          <input
            id="csv-file-upload"
            type="file"
            style={{ display: 'none' }}
            accept=".csv"
            onChange={handleFileUpload}
          />
          <Tooltip
            title={
              !currentLeads ||
              currentLeads.length === 0 ||
              isCampaignButtonDisabled
                ? 'You need to create an API key'
                : ''
            }
            arrow
          >
            <span>
              <Button
                variant="outlined"
                disabled={
                  !currentLeads ||
                  currentLeads.length === 0 ||
                  isCampaignButtonDisabled
                }
                onClick={(e) => {
                  if (
                    !activeLeadSetId ||
                    !currentSelectedLeads ||
                    currentSelectedLeads.length === 0
                  ) {
                    console.log('No leads selected or no active lead set');
                    return;
                  }

                  // Use the selected leads from the current active lead set
                  const leadsForCampaign = currentSelectedLeads.map((lead) => ({
                    ...lead,
                    // Add required CampaignLead fields with default values
                    status: 'no action',
                    progress: 0,
                  }));

                  // Log for debugging
                  console.log('Sending to campaigns:', leadsForCampaign);
                  console.log('Number of leads:', leadsForCampaign.length);

                  // Update the store with the selected leads
                  setStoreState({ selectedLeads: leadsForCampaign });

                  // Navigate to campaigns page
                  navigate('/campaigns?start=true');
                }}
                startIcon={<PeopleIcon />}
                sx={{
                  padding: '10px 20px',
                  fontSize: '16px',
                  borderRadius: '8px',
                }}
              >
                To Campaigns
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Header>

      {/* Tabs for multiple lead sets */}
      <TabContext value={activeLeadSetId || ''}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ mb: 2 }}
          >
            {Object.entries(leadSets).map(([id, leadSet]) => (
              <Tab
                key={id}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 1 }}>
                      {leadSet.fileName.length > 15
                        ? `${leadSet.fileName.substring(0, 15)}...`
                        : leadSet.fileName}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => handleCloseTab(id, e)}
                      sx={{ p: 0.5 }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                }
                value={id}
              />
            ))}
            <Tab
              icon={<AddIcon />}
              value="add-new-tab"
              sx={{ minWidth: 'auto' }}
            />
          </TabList>
        </Box>
      </TabContext>

      <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography>Leads Map Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LeadMapSettings />
        </AccordionDetails>
      </Accordion>
      <Box
        flex={1}
        flexDirection="row"
        width={'100%'}
        sx={{
          py: 2,
          mb: 2,
          display: 'flex',
          gap: 2,
          alignItems: 'right',
          justifyContent: 'flex-end',
        }}
      >
        <Typography>View</Typography>
        <IOSSwitch
          onChange={(e) =>
            selectedView === '1' ? setSelectedView('0') : setSelectedView('1')
          }
        ></IOSSwitch>
        <Typography>Edit</Typography>
      </Box>
      {leadsLoading ? (
        <Box
          sx={{
            height: '40vh',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <CircularProgress size={50} />
          <Typography variant="h5">Loading...</Typography>
        </Box>
      ) : (
        <Box sx={{ height: '73vh', width: '100%' }}>
          {(!currentLeads || currentLeads.length === 0) && (
            <Box
              sx={{
                height: '40vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <HourglassEmptyTwoTone sx={{ fontSize: 50 }} />
              <Typography variant="h5">No leads to display</Typography>
              <Typography variant="body1" color="text.secondary">
                Upload a CSV file to get started
              </Typography>
            </Box>
          )}
          {currentLeads && currentLeads.length > 0 && selectedView === '0' && (
            <LeadsCardView
              addColumnHandler={addColumnHandler}
              addRowHandler={addRowHandler}
              deleteRowsHandler={deleteRowsHandler}
              fields={currentFields}
              hideFooter={false}
              leads={currentLeads}
            />
          )}
          {currentLeads && currentLeads.length > 0 && selectedView === '1' && (
            <LeadsTableView
              addColumnHandler={addColumnHandler}
              addRowHandler={addRowHandler}
              deleteColumnHandler={deleteColumnHandler}
              deleteRowsHandler={deleteRowsHandler}
              fields={currentFields}
              hideFooter={false}
              leads={currentLeads}
              updateRowHandler={updateRowHandler}
            />
          )}
        </Box>
      )}
    </LeadsSideSheet>
  );
}

export default LeadsComponent;
