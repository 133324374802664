import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { ChatHistoryListProps } from '@/types/components/converse/history';
import groupChatHistory from '@/utils/converse/groupChatHistory';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import ChatHistoryItem from './ChatHistoryItem';

/**
 * ChatHistoryList component
 *
 * Displays a list of chat history items, grouped by categories.
 * Each chat item can be selected, and the component also displays
 * a message if no chat history is available.
 *
 * @param chatHistory - Array of chat history objects to be displayed
 * @param currentChatID - ID of the currently selected chat
 * @param setCurrentChatID - Callback to set the current chat ID on selection
 */

function ChatHistoryList({
  currentChatID,
  setCurrentChatID,
}: ChatHistoryListProps): React.ReactNode {
  const { setStoreState, chatHistory } = useAlchemystStoreForAi((store) => ({
    chatHistory: store.chatHistory,
    setStoreState: store.setStoreState,
  }));

  console.log('THE CHAT HISTORY', chatHistory);

  const [filteredChatHistory, setFilteredChatHistory] = useState(chatHistory);
  const [filter, setFilter] = useState<string | null>(null);

  // Memoize grouped chat history to avoid recalculation
  const groupedChatHistory = useMemo(
    () => groupChatHistory(filteredChatHistory),
    [filteredChatHistory]
  );

  // Display a message if no chat history is present
  // if (filteredChatHistory.length === 0) {
  //   return <Typography>No chat history available.</Typography>;
  // }

  const handleSearchTerms = (): void => {
    if (filter === '' || !filter) {
      setFilteredChatHistory(chatHistory);
      return;
    }

    setFilteredChatHistory(
      chatHistory.filter((chat) => chat.title.includes(filter))
    );
  };

  useEffect(handleSearchTerms, [chatHistory]);
  useEffect(handleSearchTerms, [!!filter]);

  return (
    <Stack spacing={2} mt={3}>
      <TextField
        onChange={(e) => setFilter(e.target.value)}
        label="Search by chat title"
      />
      {filteredChatHistory.length === 0 && (
        <Typography>No chat history available</Typography>
      )}
      {filteredChatHistory.length > 0 && (
        <Box
          sx={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {(
            Object.keys(groupedChatHistory) as Array<
              keyof typeof groupedChatHistory
            >
          ).map((groupKey) => (
            <Stack key={groupKey} spacing={1}>
              {groupedChatHistory[groupKey].length > 0 && (
                <>
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 'bold',
                      padding: '6px',
                      paddingTop: '16px',
                      color: '#ff9a35',
                    }}
                  >
                    {groupKey[0].toUpperCase() +
                      groupKey.slice(1).replace(/_/g, ' ')}
                  </Typography>
                  {groupedChatHistory[groupKey]
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .map((chat) => (
                      <ChatHistoryItem
                        key={chat.id}
                        chat={chat}
                        currentChatID={currentChatID}
                        setCurrentChatID={setCurrentChatID}
                      />
                    ))}
                </>
              )}
            </Stack>
          ))}
        </Box>
      )}
    </Stack>
  );
}

export default ChatHistoryList;