import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import type { CampaignWorkflow } from '@/types/campaign';
import { fetchCampaigns } from '@/utils/campaigns/fetchCampaigns';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'raviger';
import React, { useState } from 'react';
import Moment from 'react-moment';

interface CampaignCardProps {
  campaign: CampaignWorkflow;
}

function CampaignCard({ campaign }: CampaignCardProps): React.ReactNode {
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );

  const navigate = useNavigate();

  const [deleting, setDeleting] = useState(false);
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);


  const cardBackground =
    currentTheme === 'dark'
      ? 'linear-gradient(320deg, #000000 0%, #0A0A0A 100%)'
      : 'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)';

  const deleteCampaign = async () => {
    setDeleting(true);
    const res = await fetchWithRewrites(
      `/api/campaigns/delete/${campaign.id}`,
      {
        method: 'DELETE',
      }
    );

    const fetchedCampaignsAfterDelete = await fetchCampaigns();

    setStoreState({
      campaignWorkflows: fetchedCampaignsAfterDelete,
    });

    setDeleting(false);

    if (res.ok) {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'success',
            message: 'Campaign deleted successfully',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    } else {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: 'Failed to delete campaign',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    }
  };

  return (
    <Card
      // className={currentTheme === 'dark' ? 'custom-border' : ''}
      onClick={() => navigate(`/campaigns/${campaign.id}`)}
      sx={{
        p: 2,
        borderRadius: 2,
        border: '1px solid #1a1a1a',
        background: cardBackground,
        cursor: 'pointer',
        width: '100%',
        maxWidth: '450px',
        minHeight: '250px',
        '&:hover': {
          scale: 1.01,
        },
        transition: 'scale 0.3s ease-in-out',
      }}
      variant="elevation"
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Chip
            color={
              campaign.status === 'draft'
                ? 'default'
                : campaign.status === 'running'
                  ? 'primary'
                  : campaign.status === 'not active'
                    ? 'info'
                    : 'success'
            }
            label={campaign.status}
            size="medium"
            sx={{
              borderRadius: 2,
              textTransform: 'capitalize',
              fontSize: '0.85rem',
              fontWeight: 500,
              width: 'fit-content',
              px: 2,
            }}
          />
          <Button
            disabled={deleting}
            onClick={(e) => {
              e.stopPropagation();
              deleteCampaign();
            }}
          >
            <DeleteIcon />
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography
            color="text.primary"
            sx={{ fontWeight: 600, lineHeight: 1.3, fontSize: '1.25rem' }}
            variant="h5"
          >
            {campaign.name}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ fontSize: '0.85rem' }}
            variant="body2"
          >
            <Moment format="MMMM D, YYYY h:mm A">{campaign.created_at}</Moment>
          </Typography>
        </Box>
        <Typography
          color="text.secondary"
          sx={{ mt: 1, fontSize: '0.9rem', lineHeight: 1.6 }}
          variant="body2"
        >
          {campaign.description}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            color="text.primary"
            sx={{ fontWeight: 600, fontSize: '1rem', lineHeight: 1.3 }}
            variant="h6"
          >
            Locations:
          </Typography>
          <AvatarGroup
            max={4}
            sx={{
              '.MuiAvatar-root': { width: 36, height: 36, fontSize: '0.8rem' },
            }}
          >
            {(campaign.locations ?? []).map((location, index) => (
              <Avatar
                alt={location}
                key={index}
                src={`https://flagsapi.com/${location}/flat/64.png`}
              >
                {location}
              </Avatar>
            ))}
          </AvatarGroup>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            mt: 2,
          }}
        >
          {campaign.tags?.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              variant="outlined"
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

export default CampaignCard;
