import useScreenDimensions from '@/hooks/useScreenDimensions';
import {
  ContextEdge,
  ContextGraphData,
  ContextNode,
} from '@/types/context/context-page';
import { AppBar, Box, TextField, Toolbar, Typography } from '@mui/material';
import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';

import useContextStore from '@/hooks/useContextStore';

interface NetworkGraphProps {
  data: ContextGraphData | undefined;
}

interface NodeInfoboxProps {
  node: ContextNode | null;
}

function NodeInfobox({ node }: NodeInfoboxProps): React.ReactNode {
  if (!node) return null;

  return (
    <Box
      width={200}
      sx={(theme) => ({
        position: 'absolute',
        border: '1px solid #ccc',
        padding: '8px',
        borderRadius: '4px',
        pointerEvents: 'none',
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Typography>
        <strong>ID:</strong> {node._id}
      </Typography>
      <Typography>
        <strong>Content:</strong> {node.content}
      </Typography>
      <Typography>
        <strong>Tags:</strong>{' '}
        {node.tags.length > 0 ? node.tags.join(', ') : 'None'}
      </Typography>
    </Box>
  );
}

function NetworkGraph({ data }: NetworkGraphProps): React.ReactNode {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hoveredNode, setHoveredNode] = useState<ContextNode | null>(null);
  const { selectedNodes, setStoreState, deletedNodeIds } = useContextStore(
    (store) => ({
      selectedNodes: store.selectedNodes,
      setStoreState: store.setStoreState,
      deletedNodeIds: store.deletedNodeIds,
    })
  );
  const [contextMenuNode, setContextMenuNode] = useState<ContextNode | null>(
    null
  );

  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const { height, width } = useScreenDimensions();

  const setSelectedNodes = (newNodes: ContextNode[]) => {
    setStoreState({ selectedNodes: newNodes });
  };
  const toggleNodeSelection = (node: ContextNode) => {
    const nodeExists =
      selectedNodes.filter((n) => n.content === node.content).length > 0;
    if (nodeExists) {
      console.log('Node exists, removing from selected Nodes...');
      setSelectedNodes(selectedNodes.filter((n) => n.content !== node.content));
      return;
    }
    console.log('Node does not exist, adding to selected Nodes...');
    setSelectedNodes([...selectedNodes, node]);
  };

  // @ts-ignore
  const renderGraph = () => {
    try {
      console.log('Data:', data);
      if (!data || !data.nodes || !data.edges) {
        console.error(
          'Invalid or undefined data provided to NetworkGraph component.'
        );
        return;
      }

      const svgWidth = width * 0.75;
      const svgHeight = height * 0.75;

      const svg = d3
        .select(svgRef.current)
        .attr('width', svgWidth)
        .attr('height', svgHeight)
        .call(
          // @ts-ignore
          d3.zoom().on('zoom', (event) => {
            svg.attr('transform', event.transform);
          })
        )
        .append('g');

      const filteredNodes = data.nodes.filter(
        (node) => !deletedNodeIds.has(node._id)
      );
      const filteredEdges = data.edges.filter(
        (edge) =>
          filteredNodes.some((node) => node._id === edge.source) &&
          filteredNodes.some((node) => node._id === edge.target)
      );

      const simulation = d3
        .forceSimulation<ContextNode>(filteredNodes)
        .force(
          'link',
          d3
            .forceLink<ContextNode, ContextEdge>(filteredEdges)
            .id((d) => d._id)
            .distance(50)
        )
        .force('charge', d3.forceManyBody().strength(-200))
        .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2));

      // Add links
      const link = svg
        .selectAll('line')
        .data(filteredEdges)
        .enter()
        .append('line')
        .attr('stroke-width', 1)
        .attr('stroke', '#aaa');

      // Add nodes
      const node = svg
        .selectAll('circle')
        .data(filteredNodes)
        .enter()
        .append('circle')
        .attr('r', 5)
        .attr('fill', (d) => {
          const scopes = (d as any).scopes as string[];
          return scopes.some((scope) => /^.*\.internal$/.test(scope))
            ? '#564ddb'
            : '#69b3a2';
        })
        .on('mouseover', (event, d) => {
          if (!contextMenuNode) setHoveredNode(d);
          setTooltipPosition({ x: event.pageX + 10, y: event.pageY + 10 });
        })
        .on('mousemove', (event) => {
          setTooltipPosition({ x: event.pageX + 10, y: event.pageY + 10 });
        })
        .on('mouseout', () => {
          setHoveredNode(null);
          setContextMenuNode(null);
        })
        .on('contextmenu', (event, d) => {
          event.preventDefault();
          setHoveredNode(null);
          setContextMenuNode(d);
          toggleNodeSelection(d);
          setContextMenuPosition({ x: event.pageX, y: event.pageY });
        })
        .call(
          d3
            .drag<SVGCircleElement, ContextNode>()
            .on('start', (event, d) => {
              if (!event.active) simulation.alphaTarget(0.3).restart();
              d.fx = d.x;
              d.fy = d.y;
            })
            .on('drag', (event, d) => {
              d.fx = event.x;
              d.fy = event.y;
            })
            .on('end', (event, d) => {
              if (!event.active) simulation.alphaTarget(0);
              d.fx = null;
              d.fy = null;
            })
        );

      // Update positions
      simulation.on('tick', () => {
        link
          .attr('x1', (d: any) => (d.source as ContextNode).x!)
          .attr('y1', (d: any) => (d.source as ContextNode).y!)
          .attr('x2', (d: any) => (d.target as ContextNode).x!)
          .attr('y2', (d: any) => (d.target as ContextNode).y!);

        node.attr('cx', (d: any) => d.x!).attr('cy', (d: any) => d.y!);
      });

      return () => simulation.stop();
    } catch (e) {
      console.error('Error in NetworkGraph:', e);
    }
  };

  // @ts-ignore
  useEffect(renderGraph, [data]);

  // Handle search
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (!data || !data.nodes) return;

    d3.select(svgRef.current)
      .selectAll<SVGCircleElement, ContextNode>('circle')
      .attr(
        'border',
        (d: any) =>
          d.content.toLowerCase().includes(query.toLowerCase()) &&
          query.length > 0
            ? '5px solid orange'
            : '0px'
        // : (d.scopes as string).includes('basic.external') // NOTE: Separate coloring scheme for external and internal information nodes
        //   ? '#564ddb'
        //   : '#69b3a2'
      )
      .attr('r', (d: any) => {
        if (query.length === 0) return 5;
        return d.content.toLowerCase().includes(query.toLowerCase()) ? 15 : 5;
      });
  };

  return (
    <Box>
      <AppBar
        position="static"
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.default
              : theme.palette.background.paper,
        })}
      >
        <Toolbar>
          <TextField
            label="Search"
            value={searchQuery}
            onChange={handleSearch}
            size="small"
            sx={{ borderRadius: 1 }}
            variant="outlined"
          />
        </Toolbar>
      </AppBar>
      <Box sx={{ mt: 2 }}>
        <svg ref={svgRef} style={{ border: '1px solid #ccc' }} />
        {!!hoveredNode && (
          <Box
            sx={{
              position: 'absolute',
              top: tooltipPosition.y,
              left: tooltipPosition.x,
            }}
          >
            <NodeInfobox node={hoveredNode} />
          </Box>
        )}
        {!!contextMenuNode && (
          <Box
            sx={{
              position: 'absolute',
              top: contextMenuPosition.y,
              left: contextMenuPosition.x,
            }}
          >
            <Typography>
              <strong>Selected nodes:</strong>
            </Typography>
            {selectedNodes.map((node) => (
              <Typography key={node._id}>{node.content}</Typography>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default NetworkGraph;
