import { IOSSwitch } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import type { HumanMessage } from '@/types/ai/dropInLangchain';
import type {
  APIResponse,
  ChatMessagesContainerProps,
} from '@/types/components/converse/messages';
import type { Message } from '@/types/converse/message';
import {
  createLangchainJsonFromLangchainMessage,
  createLangchainMessageFromDBMessages,
} from '@/utils/ai/conversions';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { modifyAttributes } from '@/utils/ai/modifyState';
import { useSession } from '@/hooks/useSession';
import type { LangChainJSON } from '@/types/ai/messages';
import type { ChatHistoryType } from '@/types/converse/chatHistory';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import InitialSuggestedQuestions from './InitialSuggestedQuestions';
import PromptInputBox from './PromptInputBox';
import RenderMessage from './RenderMessage';

// Helper function for generating a new message ID
const generateMessageId = (): string => crypto.randomUUID();

/**
 * ChatMessagesContainer component
 *
 * Displays the chat messages and handles sending messages to the API.
 * The component includes the chat history, user input, and the assistant's responses.
 *
 * @param currentChatID - ID of the current chat
 * @param setCurrentChatID - Callback to set the current chat ID
 * @param setChatHistory - Callback to update the chat history
 */

function ChatMessagesContainer({
  currentChatID,
  setCurrentChatID,
}: ChatMessagesContainerProps): React.ReactNode {
  /* ----------------------------------------------------------------- States ----------------------------------------------------------------- */
  const setAlchemystStoreState = useAlchemystStoreForAi(
    (store) => store.setStoreState
  );
  const isResearchMode = useAlchemystStoreForAi(
    (store) => store.isResearchMode
  );
  const [messages, setMessages] = useState<Message[]>([]);
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [thinking, setThinking] = useState<boolean>(false);
  const [newMessageIds, setNewMessageIds] = useState<Set<string>>(new Set());
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const showLoading = useRef<boolean>(true);
  const [selectedTuners, setSelectedTuners] = useState<string[]>([]); // Tracks the selected tuners
  const { setStoreState, chatHistory, chatChannel, alchemyst } =
    useAlchemystStoreForAi((store) => ({
      alchemyst: store.alchemyst,
      setStoreState: store.setStoreState,
      chatHistory: store.chatHistory,
      chatChannel: store.chatChannel,
    }));

  const setChatHistory = (chats: ChatHistoryType[]): void => {
    setStoreState({ chatHistory: chats });
  };

  const userData = useSession();

  /* ----------------------------------------------------------------- Functions ----------------------------------------------------------------- */

  const exportChatHistory = (): void => {
    const chatHistoryJson = messages
      .map(
        (chatMessage, idx) =>
          `${chatMessage.role === 'user' ? userData.data?.name : alchemyst.name} wrote: ${chatMessage.message}`
      )
      .join('\n\n');
    const currentChat = chatHistory.filter(
      (chatHistoryObject) => chatHistoryObject.id === currentChatID
    )[-1];
    const chatHistoryFinal = `================= Chat History [${new Date(currentChat?.timestamp ?? Date.now()).toISOString()}] =================\n\n${chatHistoryJson}`;
    const blob = new Blob([chatHistoryFinal], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `chat_history - ${currentChat?.title.replaceAll(':', '-')}.txt`;
    a.click();
  };

  /**
   * Fetches chat history data from the API and updates state.
   * Memoized with `useCallback` to avoid unnecessary re-renders.
   */
  const fetchHistory = async () => {
    try {
      // setChatHistoryLoading(true);
      const chatHistoryResponse = await fetchWithRewrites(
        `/api/chat/history?source=${chatChannel}`,
        {
          signal: createTimeoutSignal(),
        }
      );
      const chatHistoryJson: { chats: ChatHistoryType[] } =
        await chatHistoryResponse.json();
      console.log('Received chat history from /api/chat/history = ');
      console.log(chatHistoryJson.chats ?? []);
      setChatHistory(chatHistoryJson.chats ?? []);
    } catch (error) {
      setChatHistory([]);
    } finally {
      // setChatHistoryLoading(false); // Always end loading state
    }
  };
  const fetchHistoryCallback = useCallback(fetchHistory, [chatChannel]);

  // Fetch chat history on initial render and when `fetchHistory` changes
  useEffect(() => {
    fetchHistoryCallback();
  }, [fetchHistoryCallback, chatChannel]);

  const scrollToBottom = useCallback(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  // Fetch messages when chat ID changes
  const fetchMessages = useCallback(async (id: string) => {
    showLoading.current && setLoadingHistory(true);
    try {
      const messagesResponse = await fetchWithRewrites(
        `/api/chat/fetch/${id}`,
        {
          method: 'GET',
          signal: createTimeoutSignal(),
        }
      );
      const messagesJson: { messages: Message[] } =
        await messagesResponse.json();
      setMessages(messagesJson.messages);
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
    setLoadingHistory(false);
  }, []);

  // Utility to process leads response
  // const processLeads = (jsonRes: any, isUseProspector: boolean): string => {
  //   if (isUseProspector) {
  //     const leadsResponse: LeadsResponse = jsonRes;
  //     return leadsResponse.entityType === null ? getSampleLeadsAsJsonResponse(15) : jsonRes;
  //   }
  //   return '';
  // };

  // //Handle change platform UX
  // const handleChangePlatformUX = useCallback(
  //   async (message: string) => {
  //     const results = await modifyAttributes(message, { alchemystStore }, true);
  //     console.log('Modified Results = ', results);

  //     const previousStoreValues = alchemystStore;

  //     setStoreChanged(true);
  //     setAlchemystStoreState(results.alchemystStore);

  //     const confirmChange = window.confirm(
  //       'The UI has been changed. Do you want to keep it?'
  //     );

  //     if (!confirmChange) setAlchemystStoreState(previousStoreValues);
  //   },
  //   [alchemystStore, setAlchemystStoreState]
  // );

  // handle generating title bassed on the chat history
  const generateResponse = useCallback(
    async (chat_history: LangChainJSON[]): Promise<APIResponse> => {
      const chatObject = {
        chat_history,
        chatId: currentChatID.length > 0 ? currentChatID : undefined,
        researchMode: isResearchMode,
        stream: false,
      };

      console.log('Chat Object = ', chatObject);
      const res = await fetchWithRewrites('/api/chat/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(chatObject),
        signal: createTimeoutSignal(),
      });

      console.log('Result status = ', res.status);

      if (!res.ok) {
        throw new Error('Failed to generate response');
      }

      const response: APIResponse = await res.json();
      return response;
    },
    [currentChatID, isResearchMode]
  );

  /**
   * Function to handle sending a message in the chat.
   * Processes the user message, sends it to the API, updates the UI with the response,
   * and handles special cases like "change platform ux" commands.
   */
  const handleSendMessage = useCallback(
    async (direct_message?: string) => {
      // Use the provided message or the current message in the input
      let messageContent = direct_message || currentMessage;

      // Return if the message is empty or whitespace
      if (!messageContent.trim()) return;

      // add the tuners prompt to the message
      selectedTuners.forEach((tuner) => {
        messageContent += ` ${tuner}`;
      });

      // Special command to trigger UX changes based on the message content
      // if (messageContent.toLowerCase().includes('change platform ux')) {
      //   handleChangePlatformUX(messageContent); // Calls a function to modify the UX
      //   return; // Stop further processing of this message
      // }

      // Indicate that the assistant (AI) is "thinking" (i.e., processing the message)
      setThinking(true);

      // Generate a unique ID for the new user message and add it to the current messages
      const newMessageId = generateMessageId();
      const updatedMessages: Message[] = [
        ...messages,
        { id: newMessageId, role: 'user', message: messageContent, json: '' },
      ];
      setMessages(updatedMessages); // Update the messages state
      setNewMessageIds((prev) => new Set([...prev, newMessageId])); // Track the new message ID for animation
      setCurrentMessage(''); // Clear the input field for the next message

      // Prepare message data for the API by converting the user message into a format that LangChain uses
      const userMessage: HumanMessage = {
        content: messageContent,
        type: 'user',
      };
      // const messageJson = JSON.parse(JSON.stringify(userMessage));
      const messageBody = [
        ...createLangchainMessageFromDBMessages(updatedMessages), // Combine previous messages
        // ...createMessageFromLangchainJson([messageJson as LangChainJSON]), // Add the new message
        userMessage,
      ];

      console.log(messageBody[0], 'message body hehe');

      try {
        // Send the message to the API and wait for the response
        const response: APIResponse = await generateResponse(
          createLangchainJsonFromLangchainMessage(messageBody)
        );

        // If there's no current chat, create a new one with an ID and title
        if (!currentChatID) {
          const { chatId, title } = response;
          setChatHistory([
            ...chatHistory,
            { id: chatId, title, timestamp: Date.now() },
          ]);
          setCurrentChatID(chatId); // Set the new chat as the current chat
        }

        // Get the assistant's reply content and process any leads if using "prospector"

        //TODO refactor type error for generate repsonse
        // const assistantMessageContent: string = response.result.response
        //   .lc_kwargs
        //   ? response.result.response.lc_kwargs
        //   : (response.result.response as any).kwargs
        //     ? (response.result.response as any).kwargs
        //     : undefined;

        let assistantMessageContent: string;
        if (response.result.response.lc_kwargs)
          assistantMessageContent = response.result.response.lc_kwargs.content;
        else if ((response.result.response as any).kwargs)
          assistantMessageContent = (response.result.response as any).kwargs
            .content;
        // const leads = processLeads(response.result.json, isUseProspector);
        const leads = JSON.stringify(response.result.json) ?? '';

        // Generate a unique ID for the assistant's response and add it to the messages
        const assistantMessageId = generateMessageId();
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: assistantMessageId,
            role: 'assistant',
            message: assistantMessageContent,
            json: leads,
          },
        ]);
        setNewMessageIds((prev) => new Set([...prev, assistantMessageId])); // Track for animations
      } catch (error) {
        // If there's an error, log it and display a generic error message to the user
        console.error('Error during message processing:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: generateMessageId(),
            role: 'assistant',
            message:
              'Sorry, I am unable to process your request at the moment. Please try again later.',
            json: '',
          },
        ]);
      } finally {
        // Scroll the chat to the bottom and stop the "thinking" indicator
        scrollToBottom();
        setThinking(false);
      }
    },
    [
      currentMessage,
      messages,
      // handleChangePlatformUX,
      generateResponse,
      currentChatID,
      setChatHistory,
      setCurrentChatID,
      scrollToBottom,
    ]
  );

  // Load messages when chat ID changes
  useEffect(() => {
    if (currentChatID) {
      fetchMessages(currentChatID);
    } else {
      setMessages([]);
    }
  }, [currentChatID, fetchMessages]);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Memoized messages to prevent unnecessary re-renders
  const renderedMessages = useMemo(() => {
    return messages.map((message) => (
      <Box key={message.id} width="100%">
        <RenderMessage
          animate={newMessageIds.has(message.id)}
          id={message.id}
          json={message.json}
          message={message.message}
          role={message.role}
        />
      </Box>
    ));
  }, [messages, newMessageIds]);

  /* ----------------------------------------------------------------- JSX ----------------------------------------------------------------- */

  return (
    <>
      {Boolean(currentChatID) && (
        <Box
          alignItems="flex-end"
          alignSelf="flex-end"
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="right"
          pb={3}
        >
          <Button
            disabled={messages.length === 0 || !chatHistory || !userData}
            endIcon={<FileDownloadIcon />}
            onClick={exportChatHistory}
            variant="outlined"
          >
            Export
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '82.5vh',
          marginX: 3,
        }}
      >
        {currentChatID.length === 0 && (
          <Box
            alignItems="center"
            display="flex"
            flex="1"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Typography sx={{ marginRight: 1 }}>Research ICPs</Typography>
            <IOSSwitch
              checked={isResearchMode}
              disabled={thinking}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={() => {
                setAlchemystStoreState({ isResearchMode: !isResearchMode });
              }}
            />
          </Box>
        )}
        {loadingHistory && currentChatID !== '' ? (
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {messages.length > 0 ? (
              <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
                <Stack spacing={2}>
                  {renderedMessages}
                  {thinking ? (
                    <Box width="100%">
                      {/* Display a "thinking" message while the AI processes the user input */}
                      <RenderMessage
                        animate // No need to animate "thinking" message
                        id={generateMessageId()}
                        json=""
                        message="Thinking..."
                        role="assistant"
                      />
                    </Box>
                  ) : null}
                  <div ref={chatEndRef} />
                </Stack>
              </Box>
            ) : (
              <InitialSuggestedQuestions onSend={handleSendMessage} />
            )}
          </>
        )}
        <Box sx={{ paddingTop: 1, position: 'sticky', bottom: 0 }}>
          <PromptInputBox
            onSend={handleSendMessage}
            selectedTuners={selectedTuners}
            setSelectedTuners={setSelectedTuners}
            setThinking={setThinking}
            setValue={setCurrentMessage}
            thinking={thinking}
            value={currentMessage}
          />
        </Box>
      </Box>
    </>
  );
}

export default ChatMessagesContainer;
