import LeadsImportModal from '@/components/Leads/LeadsImportModal';
import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { useSession } from '@/hooks/useSession';
import {
  ConvertedData,
  Lead,
  RenderMessageProps,
} from '@/types/components/converse/messages';
import { SuccessAILead } from '@/types/leads';
import { isSpeechSynthesisSupported, speakText } from '@/utils/ai/speech/t2s';
import { prettyPrintObject } from '@/utils/leads';
import { ContentCopy, OpenInNew, VolumeUp } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ChatModal from '../ChatModal';

function RenderMessage({
  role,
  message,
  json,
  animate,
}: RenderMessageProps): React.ReactNode {
  const { alchemyst, setStoreState, snackbarEntries, localLeads } =
    useAlchemystStoreForAi((store) => ({
      alchemyst: store.alchemyst,
      localLeads: store.localLeads,
      setStoreState: store.setStoreState,
      snackbarEntries: store.snackbarEntries,
    }));
  // const user = useSession().session?.user;
  const userData = useSession();

  const { log } = useLogging();
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [leadsImportModalOpen, setLeadsImportModalOpen] = useState(false);
  const [chatEmailModalOpen, setChatEmailModalOpen] = useState(false);
  const [localFields, setLocalFields] = useState<string[]>([]);
  const [isLeadsAvailable, setIsLeadsAvailable] = useState(false);

  const setLocalLeads = (leads: Lead[]) => {
    setStoreState({ localLeads: leads });
  };
  const convertJsonToForm = (jsonInput: string): ConvertedData | null => {
    try {
      const { searchResults = [], query = {} } = JSON.parse(jsonInput);
      // console.log('searchResults:', searchResults);
      if (!searchResults.data.length) return null;
      const fields = Object.keys(searchResults.data[0]);
      const leads = searchResults.data.map((item: Record<string, any>) =>
        fields.reduce(
          (acc: Lead, field) => ({
            ...acc,
            [field]: prettyPrintObject(item[field]),
          }),
          {}
        )
      );
      return { leads, fields, query };
    } catch (error) {
      console.error('Invalid JSON format:', error);
      return null;
    }
  };

  const handleConvertToLeadsPreview = () => {
    const convertedData = convertJsonToForm(json!);
    if (!convertedData) return;

    setIsLeadsAvailable(true);
    const { leads, fields } = convertedData;
    log(
      { message: `${leads.length} Leads generated`, count: leads.length },
      'platform/converse.prospector.leads'
    );
    setLocalLeads(leads);
    setLocalFields(fields);
    if (Object.keys(convertedData.query).includes('searchParams')) {
      setStoreState({ prospectorQuery: convertedData.query as SuccessAILead });
    }
  };

  useEffect(() => {
    if (json) handleConvertToLeadsPreview();
  }, [json]);

  useEffect(() => {
    if (role === 'assistant' && animate) {
      let index = 0;
      const interval = setInterval(() => {
        setDisplayedMessage(
          (prevMessage) => prevMessage + message[index++] || ''
        );
        if (index >= message.length) clearInterval(interval);
      }, 10);
    } else {
      setDisplayedMessage(message);
    }
  }, [animate, message, role]);

  const handleCopyToClipboard = () => {
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          message: 'Message copied to clipboard!',
          severity: 'success',
          type: 'alert',
          notificationId: crypto.randomUUID(),
        },
      ],
    });
    navigator.clipboard.writeText(displayedMessage);
  };

  const borderColor = (theme: any) =>
    role === 'user' ? theme.palette.primary.main : theme.palette.primary.light;

  const handleOpenLeadsPreview = () => {
    handleConvertToLeadsPreview();
    setLeadsImportModalOpen(true);
  };
  return (
    <Box
      alignItems="flex-start"
      display="flex"
      justifyContent={role === 'user' ? 'flex-end' : 'flex-start'}
      mb={2}
    >
      {role !== 'user' && (
        <Avatar src={alchemyst.avatarUrl} sx={{ mr: 2 }}>
          {alchemyst.name[0]}
        </Avatar>
      )}
      <Box
        borderRadius={3}
        color="black"
        display="flex"
        flexDirection="column"
        maxWidth="60%"
        px={2}
        py={1}
        bgcolor={borderColor}
        // color="text.primary"
        boxShadow={1}
      >
        <Typography variant="caption">
          {role !== 'user' ? alchemyst.name : userData.data?.given_name}
        </Typography>
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="chat-message">
          {displayedMessage}
        </ReactMarkdown>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          {role === 'assistant' && displayedMessage && (
            <StyledButton
              color="inherit"
              disabled={!isSpeechSynthesisSupported()}
              onClick={() => speakText(displayedMessage)}
              sx={{ mr: 'auto' }}
              variant="text"
            >
              <VolumeUp />
            </StyledButton>
          )}
          <StyledButton
            color={'inherit'}
            onClick={handleCopyToClipboard}
            sx={{ ml: 'auto' }}
            variant="text"
          >
            <ContentCopy />
          </StyledButton>
        </Box>
        {isLeadsAvailable && (
          <StyledButton
            color="inherit"
            onClick={handleOpenLeadsPreview}
            sx={{ mb: 1, borderRadius: 4, py: 0.5, px: 1 }}
            variant="outlined"
          >
            See leads preview <OpenInNew />
          </StyledButton>
        )}
        {displayedMessage.toLowerCase().includes('subject:') && (
          <StyledButton
            color="inherit"
            onClick={() => setChatEmailModalOpen(true)}
            sx={{ mb: 1, borderRadius: 4, py: 0.5, px: 1 }}
            variant="outlined"
          >
            Send email <OpenInNew />
          </StyledButton>
        )}
      </Box>
      {role === 'user' && (
        <Avatar src={userData.data?.picture || ''} sx={{ ml: 2 }}>
          U
        </Avatar>
      )}
      <LeadsImportModal
        localFields={localFields}
        localLeads={localLeads}
        open={leadsImportModalOpen}
        setOpen={setLeadsImportModalOpen}
      />
      <ChatModal
        emailContent={displayedMessage}
        open={chatEmailModalOpen}
        setOpen={setChatEmailModalOpen}
      />
    </Box>
  );
}

export default RenderMessage;