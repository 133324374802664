import { Header, StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CampaignWorkflow } from '@/types/campaign';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { extractEmails } from '@/utils/inbox/extractEmails';
import {
  CheckCircle,
  Circle,
  DirectionsRun,
  Edit,
  ToggleOff,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useNavigate } from 'raviger';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';

interface CampaignDashboardProps {
  campaign: CampaignWorkflow;
  senderName: string;
  onStatusChange: (status: CampaignWorkflow['status']) => void;
  onSenderNameChange: (sender: string) => void;
}

const getChipColor = (
  status: 'draft' | 'running' | 'not active' | 'completed'
) => {
  switch (status) {
    case 'running':
      return 'primary';
    case 'not active':
      return 'info';
    case 'completed':
      return 'success';
    case 'draft':
      return 'inherit';
  }
};

const getChipIcon = (status: string) => {
  switch (status) {
    case 'running':
      return <DirectionsRun sx={{ fontSize: 35 }} />;
    case 'draft':
      return <Edit sx={{ fontSize: 35 }} />;
    case 'not active':
      return <ToggleOff sx={{ fontSize: 35 }} />;
    case 'completed':
      return <CheckCircle sx={{ fontSize: 35 }} />;
    default:
      return <Circle sx={{ fontSize: 35 }} />;
  }
};

function StatusModal({
  open,
  onClose,
  onStatusChange,
  currentStatus,
}: {
  open: boolean;
  onClose: () => void;
  onStatusChange: (status: CampaignWorkflow['status']) => void;
  currentStatus: string;
}): React.ReactNode {
  const statuses: CampaignWorkflow['status'][] = [
    'draft',
    'running',
    'not active',
    'completed',
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Change Campaign Status
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {statuses.map((status) => (
            <StyledButton
              key={status}
              disabled={
                status === currentStatus ||
                (status !== 'completed' && currentStatus === 'running')
              }
              variant={status === currentStatus ? 'contained' : 'outlined'}
              color={getChipColor(status)}
              onClick={() => {
                onStatusChange(status);
                onClose();
              }}
            >
              {status}
            </StyledButton>
          ))}
        </Box>
      </Box>
    </Modal>
  );
}

function CampaignDashboard({
  campaign,
  senderName,
  onSenderNameChange,
  onStatusChange,
}: CampaignDashboardProps): React.ReactNode {
  /* ------------------------------------ State ------------------------------------ */
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [wamrupEmailsSent, setWamupEmialsSent] = useState<number>(0);
  const [followupEmailsSent, setFollowUpEmialsSent] = useState<number>(0);
  const [openedEmailsCount, setOpenedEmailsCount] = useState<number>(0);
  const [openedEmails, setOpenedEmails] = useState<string[]>([]);
  const [conversionPercentage, setConversionPercentage] = useState<number>(0);
  const [emailsReceived, setEmailsReceived] = useState<number>(0);
  const [interactionDisabled, setInteractionDisabled] =
    useState<boolean>(false);

  /* ------------------------------------ Custom Hooks ------------------------------------ */
  const { setStoreState, inbox } = useAlchemystStoreForAi((store) => ({
    setStoreState: store.setStoreState,
    inbox: store.inbox,
  }));

  const navigate = useNavigate();

  /* ------------------------------------ Effects ------------------------------------ */
  console.log('Campaign data: ', campaign);

  useEffect(() => {
    if (!campaign?._id) return;

    function fetchCampaignInfo() {
      const queryParams = new URLSearchParams({
        warmupEmailCount: 'true',
        followUpEmailCount: 'true',
      });

      return fetchWithRewrites(
        `/api/campaigns/fetch/${campaign._id}?${queryParams}`
      )
        .then((res) => res.json())
        .then((data) => {
          console.log('PROMISE DATA', data);
          setFollowUpEmialsSent(data.followUpEmailCount);
          setWamupEmialsSent(data.warmupEmailCount);
          return data;
        });
    }

    function fetchNumberOfEmailsOpened() {
      return fetchWithRewrites(`/api/campaigns/emails/${campaign._id}`)
        .then((res) => res.json())
        .then((data) => {
          setOpenedEmailsCount(data.openedEmails?.count ?? 0);
          setOpenedEmails(data.openedEmails?.openedEmails ?? []);
          return data;
        });
    }

    Promise.all([fetchCampaignInfo(), fetchNumberOfEmailsOpened()])
      .then(([campaignData, emailData]) => {
        const totalEmailsSent =
          campaignData.warmupEmailCount + campaignData.followUpEmailCount;
        setConversionPercentage(
          totalEmailsSent === 0
            ? 0
            : (Number(emailData.openedEmails?.count ?? 0) / totalEmailsSent) *
                100
        );
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [campaign?._id]);

  useEffect(() => {
    if (!campaign?._id) return;

    const emailsReplied = async () => {
      const queryParams = new URLSearchParams(
        Object.entries({
          campaignId: String(campaign._id),
        })
      );

      const res = await fetchWithRewrites(
        `/api/campaigns/replies?${queryParams}`
      );

      const resData = await res.json();
      console.log('THE DATA', resData);

      if (res.ok) {
        setEmailsReceived(resData.count ?? 0);
      }
    };

    emailsReplied();
  }, []);

  // console.log('Campaign data: ', campaign.leads);

  /* ------------------------------------ Handlers ------------------------------------ */
  const handleTrackEmailConversations = (): void => {
    const selectedLeads = campaign.leads;

    const emailList = selectedLeads.map(extractEmails).flat();

    localStorage.setItem('emailsToCheck', JSON.stringify(emailList));
    const selectedEmails = campaign.channels.emails;

    setStoreState({
      inbox: {
        ...inbox,
        autoFetchCampaignEmails: true,
        selectedInboxEmails: selectedEmails,
        emailsToCheck: emailList,
      },
    });
    navigate('/inbox');
  };

  /* ------------------------------------ JSX ------------------------------------ */

  return (
    <Box sx={{ p: 2 }}>
      <Header>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography
              color="text.primary"
              sx={{ fontWeight: 600, lineHeight: 1.3 }}
              variant="h4"
            >
              {campaign.name}
            </Typography>
            <Typography color="text.secondary" variant="body1">
              <Moment format="MMMM D, YYYY h:mm A">
                {campaign.created_at}
              </Moment>
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          flexDirection={'column'}
        >
          <Chip
            color={
              getChipColor(campaign.status) === 'inherit'
                ? 'default'
                : (getChipColor(campaign.status) as
                    | 'primary'
                    | 'info'
                    | 'success'
                    | 'default'
                    | 'secondary'
                    | 'error'
                    | 'warning')
            }
            label={campaign.status}
            size="medium"
            sx={{
              textTransform: 'capitalize',
              fontSize: '1.2rem',
              fontWeight: 500,
              width: 'fit-content',
              p: 3,
              cursor: 'pointer',
            }}
            icon={getChipIcon(campaign.status)}
            disabled={interactionDisabled}
            onClick={() => setModalOpen(true)}
          />
          <StyledButton
            onClick={handleTrackEmailConversations}
            variant="outlined"
            sx={{
              px: 5,
              py: 1,
              borderRadius: 2,
            }}
          >
            Track Emails
          </StyledButton>
        </Box>
      </Header>

      <Box
        sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Typography
          color="text.primary"
          sx={{ fontWeight: 600, fontSize: '1rem', lineHeight: 1.3 }}
          variant="h6"
        >
          Locations:
        </Typography>
        <AvatarGroup
          max={4}
          sx={{
            '.MuiAvatar-root': { width: 36, height: 36, fontSize: '0.8rem' },
          }}
        >
          {(campaign.locations ?? []).map((location, index) => (
            <Avatar
              alt={location}
              key={index}
              src={`https://flagsapi.com/${location}/flat/64.png`}
            >
              {location}
            </Avatar>
          ))}
        </AvatarGroup>
      </Box>
      <TextField
        value={senderName}
        onChange={(e) => onSenderNameChange(e.target.value)}
        label="Send as"
      />
      <Typography
        color="text.secondary"
        sx={{ mt: 1, fontSize: '0.9rem', lineHeight: 1.6 }}
        variant="body2"
      >
        {campaign.description}
      </Typography>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
        {campaign.tags?.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            variant="outlined"
            sx={{ fontSize: '0.8rem', fontWeight: 500 }}
          />
        ))}
      </Box>

      <Box
        sx={{
          px: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          gap: 2,
          mt: 3,
        }}
      >
        {[1, 2, 3, 4].map((data, index) => {
          let text = '';
          let value = '';

          switch (data) {
            case 1:
              text = 'Warmup Emails Sent';
              value = `${wamrupEmailsSent}`;
              break;
            case 2:
              text = 'Followup Emails Sent';
              value = `${followupEmailsSent}`;
              break;
            case 3:
              text = 'Emails Received';
              value = `${emailsReceived}`;
              break;
            case 4:
              text = 'Conversion Percentage';
              value = `${conversionPercentage.toFixed(2)}%`;
              break;
            default:
              break;
          }

          return (
            <Box
              key={index}
              sx={{
                textAlign: 'center',
              }}
            >
              <Box>
                <Typography variant="h3">{value}</Typography>
                <Typography variant="h6" gutterBottom>
                  {text}
                </Typography>
              </Box>
            </Box>
          );
        })}
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Typography variant="h3">{openedEmailsCount}</Typography>
          <Typography variant="body1" gutterBottom>
            Opened
          </Typography>
        </Box>
      </Box>

      <Box sx={{ my: 4 }}>
        {/* <Typography variant="h5" gutterBottom>
          {campaign.leads.length} Leads
        </Typography> */}
        <DataGridPro
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{
            width: '99%',
            fontSize: '1.15rem',
            height: '50vh',
            overflowY: 'auto',
            overflowX: 'scroll',
          }}
          // density="comfortable"
          rows={campaign.leads.map((lead, index) => ({
            id: lead.id ?? index,
            name: lead.Name || lead.name || '',
            title: lead.Title || lead.title || '',
            email: lead.Email || lead.email || '',
            phone: lead.Phone || lead.phone || '',
            progress: lead.progress ?? 0,
            status: lead.status || 'no action',
          }))}
          columns={[
            { field: 'id', headerName: 'ID', flex: 0.25 },
            { field: 'name', headerName: 'Name', flex: 0.8 },
            { field: 'title', headerName: 'Title', flex: 1 },
            { field: 'phone', headerName: 'Phone', flex: 1 },
            {
              field: 'email',
              headerName: 'Email',
              flex: 1.1,
              renderCell: (params) => {
                const email = params.value;
                const isViewed = openedEmails.includes(email);

                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <a
                      href={`mailto:${email}`}
                      target="_blank noopener noreferrer"
                      className="underline text-blue-200"
                    >
                      {email}
                    </a>
                    {isViewed ? (
                      <Visibility sx={{ cursor: 'pointer' }} />
                    ) : (
                      <VisibilityOff sx={{ cursor: 'pointer' }} />
                    )}
                  </Box>
                );
              },
            },
            // {
            //   field: 'progress',
            //   headerName: 'Progress',
            //   flex: 0.4,
            //   renderCell: (params) => (
            //     <Box
            //       sx={{
            //         display: 'flex',
            //         alignItems: 'center',
            //         height: '100%',
            //         gap: 1,
            //       }}
            //     >
            //       {[1, 2, 3].map((step) => (
            //         <Box
            //           key={step}
            //           sx={{
            //             height: 25,
            //             width: 25,
            //             backgroundColor:
            //               params.value >= step
            //                 ? step === 3 && params.value >= 4
            //                   ? 'red'
            //                   : step === 3
            //                     ? 'green'
            //                     : step === 2
            //                       ? 'yellow'
            //                       : 'blue'
            //                 : '',
            //             border: `1px solid ${
            //               params.value >= step
            //                 ? step === 3 && params.value >= 4
            //                   ? 'red'
            //                   : step === 3
            //                     ? 'green'
            //                     : step === 2
            //                       ? 'yellow'
            //                       : 'blue'
            //                 : 'gray'
            //             }`,
            //           }}
            //         />
            //       ))}
            //     </Box>
            //   ),
            // },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              renderCell: (params) => (
                <Chip
                  label={params.value}
                  variant="outlined"
                  color={(() => {
                    switch (params.value) {
                      case 'no action':
                        return 'default';
                      case 'Warmup email sent':
                        return 'info';
                      case 'Followup email sent':
                        return 'warning';
                      case 'Lead not interested':
                        return 'error';
                      case 'Lead started conversation':
                        return 'success';
                      default:
                        return 'info';
                    }
                  })()}
                  sx={{
                    textTransform: 'capitalize',
                    fontSize: '0.85rem',
                    fontWeight: 500,
                    px: 2,
                  }}
                />
              ),
            },
          ]}
          columnHeaderHeight={40}
          hideFooter
          rowSelection={false}
        />
      </Box>
      <StatusModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onStatusChange={async (status) => {
          setInteractionDisabled(true);
          try {
            await onStatusChange(status);
          } catch (error) {
            console.error('Error changing status:', error);
          }
          setInteractionDisabled(false);
        }}
        currentStatus={campaign.status}
      />
    </Box>
  );
}

export default CampaignDashboard;
