import { AlchemystStoreType } from '@/types/appStates';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';

interface LeadsCardViewProps {
  leads: AlchemystStoreType['leads'];
  fields: AlchemystStoreType['fields'];
  addColumnHandler?: () => void;
  addRowHandler?: () => void;
  deleteRowsHandler?: (rowsToDelete: number[]) => void;
  hideFooter?: boolean;
  showSelection?: boolean;
}

function LeadsCardView({
  leads,
  fields,
  addRowHandler,
  deleteRowsHandler,
  showSelection = true,
}: LeadsCardViewProps): React.ReactNode {
  // State for pagination and data loading
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [displayedLeads, setDisplayedLeads] = useState<typeof leads>([]);
  const { selectedLeads, setStoreState, leadSets, activeLeadSetId } =
    useAlchemystStoreForAi((store) => ({
      selectedLeads: store.selectedLeads,
      setStoreState: store.setStoreState,
      leadSets: store.leadSets,
      activeLeadSetId: store.activeLeadSetId,
    }));

  // Convert selectedLeads to selectedRows indices
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  // Helper functions remain the same
  const cleanUrl = (url: string) => {
    if (!url) return '';
    return url.replace(/^["\\]+|["\\]+$/g, '');
  };

  const getProfileImage = (lead: Record<string, any>) => {
    return (
      cleanUrl(lead.photo_url) ||
      'https://static.licdn.com/aero-v1/sc/h/244xhbkr7g40x6bsu4gi6q4ry'
    );
  };

  const getCaseInsensitiveValue = (obj: Record<string, any>, key: string) => {
    for (const objKey in obj) {
      if (objKey.toLowerCase() === key.toLowerCase()) {
        return obj[objKey];
      }
    }
    return null;
  };

  // Initialize selectedRows from selectedLeads when component mounts or selectedLeads changes
  useEffect(() => {
    if (selectedLeads.length > 0) {
      // Find indices of selectedLeads in the leads array
      const indices = selectedLeads
        .map((selectedLead) =>
          leads.findIndex(
            (lead) => JSON.stringify(lead) === JSON.stringify(selectedLead)
          )
        )
        .filter((index) => index !== -1);

      setSelectedRows(indices);
    } else {
      setSelectedRows([]);
    }
  }, [selectedLeads, leads]);

  // Pagination handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    loadPageData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    loadPageData(0, newRowsPerPage);
  };

  // Function to load data for the current page
  const loadPageData = async (pageNum: number, numRows: number) => {
    setLoading(true);
    const startIndex = pageNum * numRows;
    const endIndex = startIndex + numRows;
    const pageLeads = leads.slice(startIndex, endIndex);
    setDisplayedLeads(pageLeads);
    setLoading(false);
  };

  // Load initial data
  useEffect(() => {
    loadPageData(page, rowsPerPage);
  }, [leads]); // Reload when leads array changes

  const handleCheckboxClick = (index: number, event: React.MouseEvent) => {
    event.stopPropagation(); // Stop event from triggering row click
    setSelectedRows((prev) => {
      const newSelection = prev.includes(index)
        ? prev.filter((id) => id !== index)
        : [...prev, index];

      // Update store's selectedLeads when selection changes
      const newSelectedLeads = newSelection.map((idx) => leads[idx]);

      if (activeLeadSetId) {
        // Update the lead set's selectedLeads
        setStoreState({
          leadSets: {
            ...leadSets,
            [activeLeadSetId]: {
              ...leadSets[activeLeadSetId],
              selectedLeads: newSelectedLeads,
            },
          },
          // Also update legacy selectedLeads
          selectedLeads: newSelectedLeads,
        });
      } else {
        // Legacy behavior
        setStoreState({ selectedLeads: newSelectedLeads });
      }

      return newSelection;
    });
  };

  // Separate handler for row clicks
  const handleRowClick = (index: number) => {
    if (!showSelection) return;

    // Toggle selection
    setSelectedRows((prev) => {
      const newSelection = prev.includes(index)
        ? prev.filter((id) => id !== index)
        : [...prev, index];

      // Update store's selectedLeads when selection changes
      const newSelectedLeads = newSelection.map((idx) => leads[idx]);

      if (activeLeadSetId) {
        // Update the lead set's selectedLeads
        setStoreState({
          leadSets: {
            ...leadSets,
            [activeLeadSetId]: {
              ...leadSets[activeLeadSetId],
              selectedLeads: newSelectedLeads,
            },
          },
          // Also update legacy selectedLeads
          selectedLeads: newSelectedLeads,
        });
      } else {
        // Legacy behavior
        setStoreState({ selectedLeads: newSelectedLeads });
      }

      return newSelection;
    });
  };

  // Add this new function to handle select all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = leads.map((_, index) => index);
      setSelectedRows(newSelected);
      const newSelectedLeads = newSelected.map((idx) => leads[idx]);

      if (activeLeadSetId) {
        // Update the lead set's selectedLeads
        setStoreState({
          leadSets: {
            ...leadSets,
            [activeLeadSetId]: {
              ...leadSets[activeLeadSetId],
              selectedLeads: newSelectedLeads,
            },
          },
          // Also update legacy selectedLeads
          selectedLeads: newSelectedLeads,
        });
      } else {
        // Legacy behavior
        setStoreState({ selectedLeads: newSelectedLeads });
      }
    } else {
      setSelectedRows([]);

      if (activeLeadSetId) {
        // Update the lead set's selectedLeads
        setStoreState({
          leadSets: {
            ...leadSets,
            [activeLeadSetId]: {
              ...leadSets[activeLeadSetId],
              selectedLeads: [],
            },
          },
          // Also update legacy selectedLeads
          selectedLeads: [],
        });
      } else {
        // Legacy behavior
        setStoreState({ selectedLeads: [] });
      }
    }
  };

  // Memoized display fields
  const displayFields = useMemo(() => ['title', 'email'], []);

  return (
    <Box sx={{ width: '100%' }}>
      {addRowHandler && (
        <Box display="flex" justifyContent="flex-end" marginBottom={2}>
          <Button
            startIcon={<AddIcon />}
            onClick={addRowHandler}
            variant="contained"
            color="primary"
          >
            Add Lead
          </Button>
        </Box>
      )}

      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '400px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            tableLayout: 'fixed', // This ensures consistent column widths
            '& th': {
              width: (theme) => ({
                // Distribute column widths appropriately
                '&:first-of-type': showSelection ? '48px' : 'auto',
                '&:nth-of-type(1)': '300px', // Profile column
                '&:last-child': deleteRowsHandler ? '100px' : 'auto',
              }),
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: 'background.default' }}>
              {showSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < displayedLeads.length
                    }
                    checked={
                      leads.length > 0 && selectedRows.length === leads.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              <TableCell>Profile</TableCell>
              {displayFields.map((field) => (
                <TableCell key={field}>
                  {field.charAt(0).toUpperCase() + field.slice(1)}
                </TableCell>
              ))}
              {deleteRowsHandler && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={displayFields.length + (showSelection ? 3 : 2)}
                  align="center"
                  sx={{ height: '200px' }}
                >
                  <CircularProgress size={24} />
                </TableCell>
              </TableRow>
            ) : displayedLeads.length > 0 ? (
              displayedLeads.map((lead, index) => {
                const calculatedIndexBasedOnPageNumberAndRowsPerPage =
                  page !== 0 ? page * rowsPerPage + index : index;

                const isSelected = selectedRows.includes(
                  calculatedIndexBasedOnPageNumberAndRowsPerPage
                );
                const linkedInUrl = cleanUrl(lead.linkedin_url);
                return (
                  <TableRow
                    key={calculatedIndexBasedOnPageNumberAndRowsPerPage}
                    sx={{
                      backgroundColor: isSelected
                        ? 'action.selected'
                        : 'inherit',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                      cursor: showSelection ? 'pointer' : 'default',
                    }}
                    onClick={() =>
                      handleRowClick(
                        calculatedIndexBasedOnPageNumberAndRowsPerPage
                      )
                    }
                  >
                    {showSelection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selectedRows.includes(
                            calculatedIndexBasedOnPageNumberAndRowsPerPage
                          )}
                          onClick={(e) => {
                            handleCheckboxClick(
                              calculatedIndexBasedOnPageNumberAndRowsPerPage,
                              e
                            );
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Avatar
                          src={getProfileImage(lead)}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              fontWeight: 500,
                            }}
                          >
                            {getCaseInsensitiveValue(lead, 'name') ||
                              getCaseInsensitiveValue(lead, 'first_name') ||
                              getCaseInsensitiveValue(lead, 'profile') ||
                              'Unnamed Lead'}
                            {linkedInUrl && (
                              <IconButton
                                href={
                                  linkedInUrl.startsWith('http')
                                    ? linkedInUrl
                                    : `https://${linkedInUrl}`
                                }
                                target="_blank"
                                size="small"
                                sx={{ padding: 0 }}
                              >
                                <LinkedInIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    {displayFields.map((field, index) => (
                      <TableCell key={field}>
                        {getCaseInsensitiveValue(lead, field) || '-'}
                      </TableCell>
                    ))}

                    {deleteRowsHandler && (
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteRowsHandler([
                              calculatedIndexBasedOnPageNumberAndRowsPerPage,
                            ]);
                          }}
                          size="small"
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={displayFields.length + (showSelection ? 3 : 2)}
                  align="center"
                >
                  <Typography variant="body1" color="text.secondary">
                    No leads found. Click "Add Lead" to get started.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={leads.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </TableContainer>
    </Box>
  );
}

export default LeadsCardView;
