import React, { useState, useEffect, useCallback } from 'react';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { chatChannels } from '@/constants/chatHistory';
import JSZip from 'jszip';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
  SelectChangeEvent,
  Tooltip,
  Button,
} from '@mui/material';
import {
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
  FileDownloadOutlined,
} from '@mui/icons-material';
import { CommunicationsSideSheetProps, PlatformType } from './types';
import { ChatHistoryList } from './history';

const CommunicationsSideSheet: React.FC<CommunicationsSideSheetProps> = ({
  children,
  selectedPlatform,
  onPlatformChange,
  selectedTranscriptId,
  onTranscriptSelect,
  setCurrentChatID,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const theme = useTheme();
  const panelWidth = '300px';
  const { chatChannel, setStoreState, chatHistory } = useAlchemystStoreForAi(
    (store) => ({
      chatChannel: store.chatChannel,
      setStoreState: store.setStoreState,
      chatHistory: store.chatHistory,
    })
  );

  const calculateWidth = (rightSheetOpen: boolean): string => {
    if (rightSheetOpen) return `calc(100% - ${panelWidth})`;
    return '100%';
  };

  const handlePlatformChange = (event: SelectChangeEvent<string>) => {
    onPlatformChange(event.target.value as PlatformType);
  };

  // Format date to a readable format
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  // Handle export of all transcripts as a zip file
  const handleExportAllTranscripts = async () => {
    if (!chatHistory || chatHistory.length === 0) {
      console.error('No conversations to export');
      return;
    }

    try {
      const zip = new JSZip();
      const transcriptsFolder = zip.folder('conversations');
      if (!transcriptsFolder) return;

      let successCount = 0;
      let failCount = 0;

      // Process each conversation
      await Promise.all(
        chatHistory.map(async (chat) => {
          try {
            // Fetch the messages for this conversation
            const messagesResponse = await fetchWithRewrites(
              `/api/chat/fetch/${chat.id}`,
              {
                method: 'GET',
                signal: createTimeoutSignal(),
              }
            );
            const messagesJson = await messagesResponse.json();

            // Create the content for this conversation
            let exportContent = `Transcript: ${chat.title}\n`;
            exportContent += `Date: ${formatDate(new Date(chat.timestamp).toISOString())}\n`;
            exportContent += `Platform: ${chatChannel}\n`;
            exportContent += `ID: ${chat.id}\n\n`;
            exportContent += `Messages:\n`;
            exportContent += `${'-'.repeat(50)}\n\n`;

            // Add messages
            if (messagesJson.messages && messagesJson.messages.length > 0) {
              messagesJson.messages.forEach((message: any) => {
                const sender = message.role === 'user' ? 'You' : 'Assistant';
                const timestamp = message.timestamp
                  ? formatDate(new Date(message.timestamp).toISOString())
                  : '';

                exportContent += `[${sender}]${timestamp ? ` - ${timestamp}` : ''}\n`;
                exportContent += `${message.message}\n`;
                exportContent += `${'-'.repeat(30)}\n\n`;
              });
            } else {
              exportContent += 'No messages in this conversation.\n';
            }

            // Add the file to the zip with a clean filename
            const cleanTitle = chat.title
              .replace(/[^a-zA-Z0-9-_]/g, '_') // Replace invalid chars with underscore
              .replace(/_+/g, '_') // Replace multiple underscores with single
              .replace(/^_|_$/g, ''); // Remove leading/trailing underscores

            const fileName = `${cleanTitle}_${chat.id}.txt`;
            transcriptsFolder.file(fileName, exportContent);
            successCount++;
          } catch (error) {
            console.error(`Failed to process conversation ${chat.id}:`, error);
            failCount++;
          }
        })
      );

      if (successCount === 0) {
        console.error('Failed to export any conversations');
        return;
      }

      // Add a summary file
      const summaryContent = `Export Summary
${'-'.repeat(50)}
Date: ${new Date().toLocaleString()}
Platform: ${chatChannel}
Total Conversations: ${chatHistory.length}
Successfully Exported: ${successCount}
Failed to Export: ${failCount}
`;
      transcriptsFolder.file('_export_summary.txt', summaryContent);

      // Generate and download the zip file
      const content = await zip.generateAsync({ type: 'blob' });
      const url = URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = url;
      const timestamp = new Date().toISOString().split('T')[0];
      link.download = `conversations_${chatChannel}_${timestamp}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export transcripts:', error);
    }
  };

  const setChatChannel = (chatChannelName: string): void => {
    setStoreState({ chatChannel: chatChannelName });
  };

  return (
    <>
      <Drawer
        anchor="right"
        open
        sx={{
          overflowX: 'hidden',
          width: open ? panelWidth : 40,
          border: 'none',
          transition: 'all 0.3s',
          flexShrink: 0,
          '& .MuiDrawer-paperAnchorRight': {
            border: 'none',
            width: open ? panelWidth : 40,
          },
          '& .MuiDrawer-paper': {
            width: open ? panelWidth : 40,
            backgroundColor: 'transparent',
          },
        }}
        variant="persistent"
      >
        <Box
          sx={{
            display: 'flex',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              backgroundColor: 'transparent',
            }}
          >
            <IconButton
              onClick={() => {
                setOpen(!open);
              }}
              sx={{
                cursor: 'pointer',
                color: 'white',
                borderRadius: '10px 0 0 10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                padding: '0.2rem',
              }}
            >
              {open ? (
                <KeyboardDoubleArrowRightRounded sx={{ fontSize: '2rem' }} />
              ) : (
                <KeyboardDoubleArrowLeftRounded sx={{ fontSize: '2rem' }} />
              )}
            </IconButton>
          </Box>

          {/* Side Panel Content */}
          {open && (
            <Box
              sx={{
                width: panelWidth,
                typography: 'caption',
                overflowX: 'hidden',
                overflowY: 'auto',
                border: '3px solid',
                borderRadius: 2,
                background:
                  'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
                borderImageSource:
                  'linear-gradient(90deg, #ff9a3520 1.52%, #000 90.82%);',
                borderImageSlice: 1,
                height: '100%',
                p: 2,
              }}
            >
              {/* Add padding at the top to prevent content from going under navbar */}
              <Box sx={{ pt: 8 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3,
                  }}
                >
                  <Typography variant="h6">Channels</Typography>

                  {/* Export All Button */}
                  <Tooltip title="Export all conversations as zip file">
                    <span>
                      <IconButton
                        onClick={handleExportAllTranscripts}
                        disabled={!chatHistory || chatHistory.length === 0}
                        color="primary"
                        size="small"
                        sx={{
                          backgroundColor: 'rgba(255, 154, 53, 0.1)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 154, 53, 0.2)',
                          },
                          '&.Mui-disabled': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            color: 'rgba(0, 0, 0, 0.3)',
                          },
                        }}
                      >
                        <FileDownloadOutlined />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>

                {/* Platform Selector */}
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <InputLabel id="platform-select-label">Platform</InputLabel>
                  <Select
                    id="demo-simple-select"
                    label="Option"
                    labelId="demo-simple-select-label"
                    onChange={(e) => {
                      setChatChannel(e.target.value);
                    }}
                    value={chatChannel}
                  >
                    {chatChannels.map((channel) => (
                      <MenuItem
                        key={`channel-${channel.channel}`}
                        value={channel.channel}
                      >
                        {channel.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Divider sx={{ mb: 2 }} />

                {/* Transcripts List */}
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Conversations
                </Typography>
                <ChatHistoryList
                  currentChatID={selectedTranscriptId || ''}
                  setCurrentChatID={(value) => {
                    const chatId =
                      typeof value === 'function' ? value('') : value;
                    setCurrentChatID(chatId);
                    onTranscriptSelect(chatId);
                  }}
                  {...props}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>

      <Box
        sx={{
          marginRight: open ? panelWidth : 0,
          maxWidth: calculateWidth(open),
          flex: 1,
          transition: 'all 0.3s',
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default CommunicationsSideSheet;
