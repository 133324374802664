import React from 'react';

export const generateCode = async (prompt: string) => {
  let reactElement: React.ReactElement;
  try {
    // NOTE: Step 1: Generate the React JSX Code
    return React.createElement(
      'div',
      '<Stack spacing={3}><Box>helloWorld</Box><div>Hi</div></Stack>'
    );

    // NOTE: Step 2: Compile it into pure HTML/JS
  } catch (error) {
    return React.createElement(
      'div',
      `<div style="border: 1px solid white;">This is a sample widget</div>`
    );
  }
};
