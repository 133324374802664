import { leads } from '@/constants';

export function getSampleLeadsAsJsonResponse(numberOfLeads: number): string {
  if (numberOfLeads >= leads.length) {
    return JSON.stringify({
      searchResults: leads,
    });
  }

  const shuffledLeads = [...leads].sort(() => 0.5 - Math.random());
  return JSON.stringify({
    searchResults: shuffledLeads.slice(0, numberOfLeads),
  });
}

export function mapFields<T>(
  originalRecord: Record<string, T>,
  fieldsMap: Record<string, string | undefined>
): Record<string, T> {
  return Object.keys(originalRecord).reduce<Record<string, T>>((acc, key) => {
    acc[fieldsMap[key] ?? key] = originalRecord[key]; // NOTE: Also account for cases where fieldsMap[key] is undefined
    return acc;
  }, {});
}

export function mapFieldsForAllRecords<T>(
  originalRecords: Record<string, T>[],
  fieldsMap: Record<string, string | undefined>
): Record<string, T>[] {
  const mappedRecords = originalRecords.map((record) =>
    mapFields(record, fieldsMap)
  );
  console.log('An example mapped record = ', mappedRecords[0]);
  return mappedRecords;
}

export const prettyPrintObject = (jsonInput: any): string => {
  if (Array.isArray(jsonInput)) {
    if (jsonInput.every((item) => typeof item === 'string')) {
      return jsonInput.join(', ');
    } else {
      return jsonInput.map((item) => prettyPrintObject(item)).join('\n\n');
    }
  } else if (typeof jsonInput === 'object' && jsonInput !== null) {
    return Object.entries(jsonInput)
      .map(([key, value]) => `${key}: ${JSON.stringify(value, null, 2)}`)
      .join('\n');
  } else {
    return jsonInput;
  }
};
