import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

const ApiKeys = () => {
  const [rows, setRows] = useState<any[]>([]);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const [apiKeyName, setApiKeyName] = useState<string>('Untitled API Key');
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  async function deleteApiKey(apiKey: string): Promise<void> {
    const res = await fetchWithRewrites('/api/settings/api-keys/delete', {
      method: 'DELETE',
      body: JSON.stringify({
        apiKey: apiKey,
      }),
      signal: createTimeoutSignal(),
    });

    const res_json = await res.json();
    console.log(
      'Received response status from delete api key request: ',
      res_json
    );

    if (res_json.success) {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'success',
            message: 'API key deleted successfully!',
            type: 'alert',
            // variant: 'determinate',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
      setRows(rows.filter((row) => row.apiKey !== apiKey));
    } else {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: 'Failed to delete API key. Message: ' + res_json.message,
            type: 'alert',
            // variant: 'determinate',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    }
  }
  async function fetchApiKeys(): Promise<any[]> {
    const res = await fetchWithRewrites('/api/settings/api-keys/list', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const res_json = await res.json();
    const apiKeys = res_json.apiKeys ?? [];

    const apiKeysResults = apiKeys.map((apiKey: any, index: number) => ({
      ...apiKey,
      id: index + 1,
    }));

    setRows(apiKeysResults);

    return apiKeysResults;
  }

  useEffect(() => {
    let res = fetchApiKeys().then((apiKeys) =>
      apiKeys.map((apiKey: any, index: number) => ({
        ...apiKey,
        id: index + 1,
      }))
    );
  }, []);

  async function createApiKey(): Promise<void> {
    const res = await fetchWithRewrites('/api/settings/api-keys/new', {
      method: 'POST',
      body: JSON.stringify({
        name: apiKeyName,
      }),
      signal: createTimeoutSignal(),
    });
    // const res_status = await res.status;
    const res_json = await res.json();
    console.log(
      'Received response status from create new api key request: ',
      res_json
    );

    if (res_json.success) {
      setRows([
        ...rows,
        {
          ...res_json.apiKey,
          id: rows.length + 1,
        },
      ]);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'success',
            message: 'API key created successfully!',
            type: 'alert',
            notificationId: crypto.randomUUID(),

            // variant: 'determinate',
          },
        ],
      });
    } else {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: 'Failed to create API key. Message: ' + res_json.message,
            type: 'alert',
            notificationId: crypto.randomUUID(),

            // variant: 'determinate',
          },
        ],
      });
    }
  }

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'apiKey',
      headerName: 'Key',
      flex: 0.6,
      renderCell: (params: any) => {
        console.log('Value coming in API Key = ', params);
        let paramsValue = params.value ?? '';
        let passwordLikeValue = '*'.repeat(paramsValue.length);
        return (
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {passwordLikeValue}
          </div>
        );
      },
    },
    {
      field: 'actions',
      flex: 0.4,
      headerName: 'Actions',
      renderCell: (params: any) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignmentBaseline: 'central',
            }}
          >
            <Tooltip title={'Copy API key to clipboard'}>
              <IconButton
                color="inherit"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  // console.log("Copied to clipboard");
                  navigator.clipboard.writeText(params.row.apiKey);
                  // console.log("API Key: ", params.row.apiKey);
                  setStoreState({
                    snackbarEntries: [
                      ...snackbarEntries,
                      {
                        severity: 'success',
                        message: 'API Key copied to clipboard.',
                        type: 'alert',
                        notificationId: crypto.randomUUID(),
                        // variant: 'determinate',
                      },
                    ],
                  });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Delete API Key'}>
              <IconButton
                color="error"
                onClick={async (event: React.MouseEvent<HTMLButtonElement>) => {
                  console.log('Clicked delete button');
                  console.log('API Key: ', params.row.apiKey);
                  await deleteApiKey(params.row.apiKey);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <div style={{ height: 400 }} id="api-keys-table">
        <DataGrid rows={rows} columns={columns} />
      </div>
      <Box
        id="new-api-key"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'left',
          maxWidth: '35%',
        }}
      >
        <TextField
          value={apiKeyName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setApiKeyName(event.target.value)
          }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={async () => await createApiKey()}
        >
          Add API Key
          <AddIcon />
        </Button>
      </Box>
    </Stack>
  );
};

export default ApiKeys;
