import {
  footerText,
  leftContentWrapper,
  rightImage,
  signInContainer,
  styledCard,
} from '@/constants/themes/index'; // Adjust the path as needed
import GoogleIcon from '@mui/icons-material/Google';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

export default function SignInPage() {
  return (
    <Grid container sx={signInContainer}>
      <Grid item xs={12} md={6} sx={leftContentWrapper}>
        <Card sx={styledCard}>
          <CardContent>
            <Stack direction="column" alignItems="center">
              <img src="/logo.png" alt="Logo" width={200} height={80} />
              <Typography
                fontWeight="bold"
                fontSize={26}
                align="center"
                sx={{ mt: 6 }}
              >
                Welcome to the future of workforce
              </Typography>
              <Typography variant="body1" color={'white'} align="center">
                Your enterprise AI space, all at one place.
              </Typography>
            </Stack>
          </CardContent>
          <Box
            sx={{
              flexGrow: 1,
              mt: 3,
              mb: 2,
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Typography fontWeight="bold" variant="h6">
              Sign in with
            </Typography>
          </Box>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="warning"
              startIcon={<GoogleIcon />}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/google`;
              }}
              sx={{
                textTransform: 'none',
                py: 1.5,
                px: 3,
                width: '100%',
                borderRadius: '0.8rem',
              }}
            >
              Google
            </Button>
            <Button
              variant="contained"
              color="warning"
              // startIcon={<GoogleIcon />}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/zoho`;
              }}
              sx={{
                textTransform: 'none',
                py: 1.5,
                px: 3,
                width: '100%',
                borderRadius: '0.8rem',
              }}
              disabled={true}
            >
              Zoho (Coming soon)
            </Button>
          </CardActions>
          <Typography
            variant="caption"
            sx={footerText}
            align="center"
            justifyContent={'center'}
            display={'flex'}
          >
            &copy; {new Date().getFullYear()} Alchemyst.AI. All rights reserved.
          </Typography>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            ...rightImage,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src="/login-graphics.png"
            alt="AI Image"
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
