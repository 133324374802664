export const chatChannels: { channel: string; label: string }[] = [
  {
    channel: 'platform.converse',
    label: 'Platform',
  },
  {
    channel: 'integrations.slack',
    label: 'Slack',
  },
  {
    channel: 'integrations.telegram.direct',
    label: 'Telegram Direct',
  },
  {
    channel: 'integrations.telegram.groups',
    label: 'Telegram Groups',
  },
  {
    channel: 'integrations.whatsapp',
    label: 'WhatsApp',
  },
  {
    channel: 'integrations.google',
    label: 'Google',
  },
  {
    channel: 'integrations.others',
    label: 'Others',
  },
];
