import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { useSession } from '@/hooks/useSession';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, CircularProgress, Typography } from '@mui/material';

type HeaderProps = {
  monthlyTarget: number;
  maxMonthlyTarget: number;
};

const Header: React.FC<HeaderProps> = ({ monthlyTarget, maxMonthlyTarget }) => {
  const userData = useSession();

  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">
          Welcome Back, {userData.data?.given_name} 👋
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            paddingX: 2,
            paddingY: 1.8,
            boxSizing: 'border-box',
            borderRadius: 6,
            background:
            currentTheme === 'dark'
            ? 'linear-gradient(320deg, #000000 0%, #0A0A0A 100%)'
            : 'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
          }}
          className={
            currentTheme === 'dark'
              ? 'custom-border'
              : 'custom-border_for_light'
          }
        >
          <GroupsIcon color="primary" />
          <Typography variant="body1">Monthly Target</Typography>
          <Typography variant="body1" color={'primary'}>
            {monthlyTarget}/{maxMonthlyTarget}
          </Typography>
          <CircularProgress
            variant="determinate"
            value={(monthlyTarget / maxMonthlyTarget) * 100}
            size={30}
            sx={{
              color: 'primary.main',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
