import { sampleIntegrationsMenu } from '@/constants';
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Modal,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import IntegrationCard from './IntegrationCard';
import { useEffect, useState } from 'react';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import CircularProgress from '@mui/material/CircularProgress';
import {
  CheckCircleOutline,
  Check,
  Close,
  ContentCopy,
  Delete,
  ArrowBackIosNew,
} from '@mui/icons-material';

const Integrations = () => {
  const installedIntegrations = sampleIntegrationsMenu.filter(
    (item) => item.installed
  );

  const [configModals, setConfigModals] = useState({
    whatsapp: false,
    telegram: false,
    slack: false,
    hubspot: false,
  });

  return (
    <Box>
      <Typography variant="h6" marginY={3}>
        Installed {installedIntegrations.length}/{sampleIntegrationsMenu.length}{' '}
        integrations.
      </Typography>
      <Box>
        {installedIntegrations.map((item, index) => (
          <IntegrationCard
            key={index}
            item={item}
            setConfigModals={setConfigModals}
          />
        ))}
      </Box>
      <WhatsAppConfigModal
        openModal={configModals.whatsapp}
        setConfigModals={setConfigModals}
      />
      <TelegramConfigModal
        openModal={configModals.telegram}
        setConfigModals={setConfigModals}
      />
    </Box>
  );
};

export default Integrations;

interface WhatsappModalProps {
  openModal: boolean;
  setConfigModals: React.Dispatch<
    React.SetStateAction<{
      whatsapp: boolean;
      telegram: boolean;
      slack: boolean;
      hubspot: boolean;
    }>
  >;
}

const WhatsAppConfigModal = (props: WhatsappModalProps) => {
  const { openModal, setConfigModals } = props;

  const [formValues, setFormValues] = useState({
    phoneNumber: '',
    accessToken: '',
    whatsappBusinessAccountId: '',
    phoneNumberId: '',
    appId: '',
    appSecret: '',
    verifyToken: '',
    apiKey: '',
  });
  const [savingIntegration, setSavingIntegration] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [connectedAccounts, setConnectedAccounts] = useState<
    {
      phoneNumber: string;
      webhookUrl: string;
    }[]
  >([]);

  const handleCopy = () => {
    navigator.clipboard.writeText(webhookUrl).then(() => {
      setCopied(true);
      closeModal();
      setCopied(false);
    });
  };

  const handleInputChange = (field: string, value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };
  const closeModal = () => {
    setModalIndex(0);
    setWebhookUrl('');
    setConfigModals((prev) => ({
      ...prev,
      whatsapp: false,
    }));
  };
  const handleFormSubmit = async () => {
    setSavingIntegration(true);
    try {
      const response = await fetchWithRewrites(
        `/api/maya/integrations/whatsapp/init`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formValues),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();
      // console.log('Response from API:', result);

      if (result) setWebhookUrl(result.webhookUrl);

      setFormValues((prev) => ({
        ...prev,
        phoneNumber: '',
        accessToken: '',
        phoneNumberId: '',
        whatsappBusinessAccountId: '',
        appId: '',
        appSecret: '',
        verifyToken: '',
        apiKey: '',
      }));
    } catch (error) {
      console.error('Error while saving WhatsApp integration:', error);
    } finally {
      setSavingIntegration(false);
    }
  };

  const removeIntegration = async (webhookUrl: string) => {
    const res = await fetchWithRewrites('/api/maya/integrations/whatsapp', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ webhookUrl }),
    });

    if (res.ok) {
      fetchConnectedAccounts();
      console.log('DELETED');
    }
  };

  const fetchConnectedAccounts = async () => {
    const res = await fetchWithRewrites(
      '/api/maya/integrations/whatsapp/fetch'
    );
    const data = await res.json();

    setConnectedAccounts(
      data.data.map(
        (item: { credentials: { phoneNumber: string; webhookUrl: string } }) =>
          item.credentials
      )
    );
  };

  useEffect(() => {
    fetchConnectedAccounts();
  }, []);

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="integration-settings-modal"
      aria-describedby="integration-settings-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box>
          {modalIndex === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4,
              }}
            >
              <Typography variant="h5">Accounts connected</Typography>
              <Button
                onClick={() => {
                  setModalIndex(1);
                }}
              >
                Add
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                pb: 4,
              }}
            >
              <Button onClick={() => setModalIndex(0)}>
                <ArrowBackIosNew />
              </Button>
              <Typography variant="h5">Add New Account</Typography>
            </Box>
          )}
        </Box>
        <Box>
          {modalIndex === 0 ? (
            <Box
              sx={{
                maxHeight: 500,
                overflowY: 'scroll',
              }}
            >
              {connectedAccounts.length > 0 ? (
                connectedAccounts.map((integration, idx) => (
                  <Card key={idx} sx={{ mb: 2, borderRadius: 2 }}>
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 2,
                        }}
                      >
                        <Typography variant="h6">
                          {integration.phoneNumber}
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 3 }}>
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          Webhook URL
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <TextField
                            fullWidth
                            value={integration.webhookUrl}
                            InputProps={{ readOnly: true }}
                            size="small"
                          />
                          <IconButton onClick={handleCopy} size="small">
                            <ContentCopy />
                          </IconButton>
                        </Box>
                      </Box>
                    </CardContent>

                    <CardActions sx={{ p: 2, pt: 0 }}>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => {
                          removeIntegration(integration.webhookUrl);
                        }}
                      >
                        Remove Integration
                      </Button>
                    </CardActions>
                  </Card>
                ))
              ) : (
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 3 }}>
                  No connected accounts. Please create one.
                </Typography>
              )}
            </Box>
          ) : (
            <Box>
              <Stack spacing={2}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={formValues.phoneNumber}
                  onChange={(e) =>
                    handleInputChange('phoneNumber', e.target.value)
                  }
                />
                <TextField
                  label="Access Token"
                  variant="outlined"
                  fullWidth
                  value={formValues.accessToken}
                  onChange={(e) =>
                    handleInputChange('accessToken', e.target.value)
                  }
                />
                <TextField
                  label="Whatsapp Business AccountId"
                  variant="outlined"
                  fullWidth
                  value={formValues.whatsappBusinessAccountId}
                  onChange={(e) =>
                    handleInputChange(
                      'whatsappBusinessAccountId',
                      e.target.value
                    )
                  }
                />
                <TextField
                  label="Phone Number ID"
                  variant="outlined"
                  fullWidth
                  value={formValues.phoneNumberId}
                  onChange={(e) =>
                    handleInputChange('phoneNumberId', e.target.value)
                  }
                />
                <TextField
                  label="App ID"
                  variant="outlined"
                  fullWidth
                  value={formValues.appId}
                  onChange={(e) => handleInputChange('appId', e.target.value)}
                />
                <TextField
                  label="App Secret"
                  variant="outlined"
                  fullWidth
                  value={formValues.appSecret}
                  onChange={(e) =>
                    handleInputChange('appSecret', e.target.value)
                  }
                />
                <TextField
                  label="Verify Token"
                  variant="outlined"
                  fullWidth
                  value={formValues.verifyToken}
                  onChange={(e) =>
                    handleInputChange('verifyToken', e.target.value)
                  }
                />
                <TextField
                  label="Platform Api Key"
                  variant="outlined"
                  fullWidth
                  value={formValues.apiKey}
                  onChange={(e) => handleInputChange('apiKey', e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFormSubmit}
                  disabled={savingIntegration}
                  startIcon={
                    savingIntegration && <CircularProgress size={24} />
                  }
                >
                  {savingIntegration ? 'Saving...' : 'Save'}
                </Button>
              </Stack>
              {webhookUrl.length > 0 && (
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={webhookUrl}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Tooltip title={copied ? 'Copied!' : 'Copy'}>
                    <IconButton color="primary" onClick={handleCopy}>
                      {copied ? (
                        <CheckCircleOutline color="success" />
                      ) : (
                        <ContentCopy />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

interface TelegramModalProps {
  openModal: boolean;
  setConfigModals: React.Dispatch<
    React.SetStateAction<{
      whatsapp: boolean;
      telegram: boolean;
      slack: boolean;
      hubspot: boolean;
    }>
  >;
}

const TelegramConfigModal = (props: TelegramModalProps) => {
  const { openModal, setConfigModals } = props;

  const [formValues, setFormValues] = useState({
    phoneNumber: '',
    profileHandle: '',
    apiKey: '',
  });
  const [modalIndex, setModalIndex] = useState(0);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [connectedAccounts, setConnectedAccounts] = useState<
    {
      phoneNumber: string;
      profileHandle: string;
    }[]
  >([]);
  const [savingIntegration, setSavingIntegration] = useState(false);
  const [otp, setOtp] = useState('');
  const [optInput, setOtpInput] = useState(false);

  const closeModal = () => {
    if (!connectionStatus && modalIndex !== 0) return;
    setModalIndex(0);
    setConfigModals((prev) => ({
      ...prev,
      telegram: false,
    }));
  };

  const handleInputChange = (field: string, value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleFormSubmit = async () => {
    setSavingIntegration(true);
    try {
      const response = await fetchWithRewrites(
        `/api/maya/integrations/telegram/init`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formValues),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const result = await response.json();

      if (response.ok) {
        setOtpInput(true);
      }

      setFormValues((prev) => ({
        ...prev,
        phoneNumber: '',
        profileHandle: '',
        apiKey: '',
      }));
    } catch (error) {
      console.error('Error while saving WhatsApp integration:', error);
    } finally {
      setSavingIntegration(false);
    }
  };

  const handleVerify = async () => {
    const response = await fetch(`http://localhost:8000/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ otp, userId: '67a33279c160bd201f3f6f05' }),
    });

    console.log('teh res', response);

    if (response.ok) {
      setConnectionStatus(true);
      // fetchConnectedAccounts();
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="integration-settings-modal"
      aria-describedby="integration-settings-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box>
          {modalIndex === 0 ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4,
              }}
            >
              <Typography variant="h5">Accounts connected</Typography>
              <Button
                onClick={() => {
                  setModalIndex(1);
                }}
              >
                Add
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                pb: 4,
              }}
            >
              <Button onClick={() => setModalIndex(0)}>
                <ArrowBackIosNew />
              </Button>
              <Typography variant="h5">Add New Account</Typography>
            </Box>
          )}
        </Box>
        <Box>
          {modalIndex === 0 ? (
            <Box
              sx={{
                maxHeight: 500,
                overflowY: 'scroll',
              }}
            >
              {connectedAccounts.length > 0 ? (
                connectedAccounts.map((integration, idx) => (
                  <Card key={idx} sx={{ mb: 2, borderRadius: 2 }}>
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 2,
                        }}
                      >
                        <Typography variant="h6">
                          {integration.phoneNumber}
                        </Typography>
                      </Box>
                    </CardContent>

                    <CardActions sx={{ p: 2, pt: 0 }}>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => {
                          console.log('DELETING');
                        }}
                      >
                        Remove Integration
                      </Button>
                    </CardActions>
                  </Card>
                ))
              ) : (
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 3 }}>
                  No connected accounts. Please create one.
                </Typography>
              )}
            </Box>
          ) : optInput ? (
            <Box>
              <Stack spacing={2}>
                <TextField
                  label="OTP"
                  variant="outlined"
                  fullWidth
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerify}
                >
                  Verify
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box>
              <Stack spacing={2}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={formValues.phoneNumber}
                  onChange={(e) =>
                    handleInputChange('phoneNumber', e.target.value)
                  }
                />
                <TextField
                  label="Profile Handle"
                  variant="outlined"
                  fullWidth
                  value={formValues.profileHandle}
                  onChange={(e) =>
                    handleInputChange('profileHandle', e.target.value)
                  }
                />
                <TextField
                  label="Platform Api Key"
                  variant="outlined"
                  fullWidth
                  value={formValues.apiKey}
                  onChange={(e) => handleInputChange('apiKey', e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFormSubmit}
                  disabled={savingIntegration}
                  startIcon={
                    savingIntegration && <CircularProgress size={24} />
                  }
                >
                  {savingIntegration ? 'Saving...' : 'Save'}
                </Button>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
