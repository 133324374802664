import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useContextStore from '@/hooks/useContextStore';
import {
  ContextEdge,
  ContextNode,
  RawContextNode,
} from '@/types/context/context-page';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import NetworkGraph from './NetworkGraph';
import NodeOperator from './NodeOperator';
import Documents from './Documents';

const transformNodes = (nodes: RawContextNode[]): ContextNode[] => {
  const transformedNodes: ContextNode[] = [];
  console.log('Nodes = ', nodes);
  (nodes ?? []).forEach((node) => {
    transformedNodes.push({
      ...node,
      id: node._id,
    });
  });
  return transformedNodes;
};

const createEdgesFromNodes = (nodes: ContextNode[]): ContextEdge[] => {
  const edges: ContextEdge[] = [];
  nodes.forEach((node) => {
    node.children_context_nodes.forEach((childId) => {
      edges.push({ source: node.id, target: childId });
    });
  });
  return edges;
};

function ContextVisualizer(): React.ReactNode {
  const {
    setStoreState: setContextStoreState,
    contextNodes,
    selectedNodes,
  } = useContextStore((store) => ({
    setStoreState: store.setStoreState,
    contextNodes: store.contextNodes ?? [],
    selectedNodes: store.selectedNodes,
  }));
  const { setAlchemystStoreState, snackbarEntries } = useAlchemystStoreForAi(
    (store) => ({
      setAlchemystStoreState: store.setStoreState,
      snackbarEntries: store.snackbarEntries,
    })
  );
  const [tabValue, setTabValue] = useState(0);

  const getContextProcessedNodes = async () => {
    const contextNodesFetchResponse =
      await fetchWithRewrites('/api/context/view');
    const contextNodesFetchResponseJson =
      await contextNodesFetchResponse.json();
    console.log(
      'contextNodesFetchResponseJson = ',
      contextNodesFetchResponseJson
    );
    const fetchedNodes = contextNodesFetchResponseJson.context;
    // const nodes = transformNodes(sampleNodes);
    const nodes = transformNodes(fetchedNodes);
    console.log(nodes);
    setContextStoreState({
      contextNodes: nodes,
    });
  };

  useEffect(() => {
    getContextProcessedNodes().catch((e) => {
      setAlchemystStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: "Couldn't visualize the contexts. Sorry :(",
            severity: 'error',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    });
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, tabIdx: number) => {
    setTabValue(tabIdx);
  };

  const memoizedNetworkGraph = useMemo(
    () => (
      <NetworkGraph
        data={{
          nodes: contextNodes,
          edges: createEdgesFromNodes(contextNodes),
        }}
      />
    ),
    [contextNodes]
  );

  return (
    <>
      <Box sx={{ width: '100%', padding: '1rem 0' }}>
        <Tabs
          aria-label="context-visualizer-tabs"
          onChange={handleTabChange}
          role="navigation"
          value={tabValue}
        >
          <Tab label="Dissect" />
          <Tab label="Operate" />
          <Tab label="Documents" />
        </Tabs>
      </Box>
      {tabValue === 0 && (
        <Stack sx={{ my: 2 }} spacing={2}>
          <Typography variant="h5">Dissect</Typography>
          <Typography>
            Surf and search through your document contexts here, split across{' '}
            <b>{contextNodes.length}</b> piece of information.
          </Typography>
          {/* {memoizedNetworkGraph}
           */}
          <NetworkGraph
            data={{
              nodes: contextNodes,
              edges: createEdgesFromNodes(contextNodes),
            }}
          />
        </Stack>
      )}
      {tabValue === 1 && (
        <Stack sx={{ my: 2 }} spacing={2}>
          <Typography variant="h5">Operate</Typography>
          <Typography>Edit/Delete your context here.</Typography>
          <NodeOperator nodes={selectedNodes} />
        </Stack>
      )}
      {tabValue === 2 && <Documents />}
    </>
  );
}

export default ContextVisualizer;
