import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import ParentScraperComponent from '../Augment/scraper/ScraperParent';
import BatchedWebEnrichment from '../Augment/scraper/web/BatchedWebEnrichment';
import ComingSoon from '../ComingSoon';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';

const LeadsSideSheetTabs = ({ panelWidth }: { panelWidth: string }) => {
  const [scraperState, setScraperState] = useState<
    'closed' | 'working' | 'open' | 'saved'
  >('closed');
  const [sessions, setSessions] = useState<any>(null);
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);

  return (
    <Box
      sx={{
        width: panelWidth,
        typography: 'caption',
        overflowX: 'hidden',
        border: '3px solid',
        borderRadius: 2,
        background:
          currentTheme === 'dark'
            ? 'linear-gradient(320deg, #000000 0%, #0A0A0A 100%)'
            : 'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
        borderImageSource:
          'linear-gradient(270deg, #00000000 1.52%, #5A5A5A20 90.98%)',
        borderImageSlice: 1,
      }}
      paddingY={'10vh'}
      height={'100vh'}
      paddingX={1}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Box
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            flex="1"
            pb={2}
            flexDirection={'row'}
          >
            <Typography variant="h5">Enrich Data</Typography>
          </Box>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="By URL" value="1" />
            <Tab label="By Web" value="2" />
            <Tab label="With Integrations" value="3" />
            {/* <Tab label="Sales Campaign" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <ParentScraperComponent mode="individual" />
        </TabPanel>
        <TabPanel value="2">
          {/* <ComingSoon message="Augment your data, powered by the web." /> */}
          <BatchedWebEnrichment />
        </TabPanel>
        <TabPanel value="3">
          <ComingSoon message="Why not bring more integrations here?" />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default LeadsSideSheetTabs;
