import { StyledButton } from '@/constants/themes';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { AutoAwesome } from '@mui/icons-material';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import { navigate } from 'raviger';
import { useState } from 'react';

const GenerateWorkflowPromptBox = () => {
  const [userPrompt, setUserPrompt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleGenerateTemplate = async () => {
    setLoading(true);
    setError(null);
    if (!userPrompt) {
      setError('Please enter a prompt');
      setLoading(false);
      return;
    }
    try {
      const res = await fetchWithRewrites(
        '/api/campaigns/workflows/generate/sales',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userPrompt,
          }),
          signal: AbortSignal.timeout(120_000),
        }
      );
      const data = await res.json();
      if (data.result) {
        console.log('Result', data.result);
        localStorage.setItem('generatedCampaignTemplate', data.result);
        navigate('/campaigns/builder');
      } else {
        setError('Error generating template');
      }
    } catch (error) {
      console.error('Error generating template:', error);
      setError('Error generating template');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <TextField
        variant="outlined"
        fullWidth
        size="medium"
        value={userPrompt}
        onChange={(e) => {
          setUserPrompt(e.target.value);
          setError(null);
        }}
        placeholder="✨ Describe your ideal campaign template! Include goals, target audience, and steps for a tailored template powered by Maya."
        multiline
        minRows={5}
        sx={{
          backgroundColor: 'background.default',
        }}
        error={Boolean(error)}
        helperText={error}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <StyledButton
          variant="outlined"
          size="small"
          sx={{
            fontSize: '14px',
            padding: '5px 20px',
            width: 'fit-content',
          }}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <AutoAwesome />
            )
          }
          onClick={handleGenerateTemplate}
        >
          {loading ? 'Generating...' : 'Generate Workflow'}
        </StyledButton>
        <Typography
          variant="caption"
          align="right"
          color="text.secondary"
          mt={-0.5}
          mr={1}
        >
          powered by <strong>Maya</strong>
        </Typography>
      </Box>
    </>
  );
};

export default GenerateWorkflowPromptBox;
