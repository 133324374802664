import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { groupListByKey } from '@/utils/inbox';

function Conversations({
  emails,
  handleViewConversation,
}: {
  emails: Record<string, any>[];
  handleViewConversation: (threadId: string) => void;
}): React.ReactNode {
  const groupedEmailsByInbox = groupListByKey(emails, 'sender');
  let groupedEmailsByInboxAndThreads: Record<
    string,
    Record<string, Record<string, any>[]>
  > = {};

  Object.entries(groupedEmailsByInbox).forEach(([key, val]) => {
    groupedEmailsByInboxAndThreads[key] = groupListByKey(val, 'threadId');
  });

  return (
    <Stack
      maxHeight="80vh"
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          outline: '1px solid slategrey',
        },
      }}
    >
      <Box pb={2}>
        <Typography variant="h6">Conversations</Typography>
      </Box>
      {Object.entries(groupedEmailsByInboxAndThreads).map(
        ([inbox, threads], idx) => (
          <Accordion key={`inbox-${idx + 1}`}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{inbox}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                maxHeight: '50vh',
                overflow: 'auto',
                '&::-webkit-scrollbar': { width: '0.4em' },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,.1)',
                  outline: '1px solid slategrey',
                },
              }}
            >
              {Object.entries(threads).map(
                ([threadId, emailValues], threadIdx) => (
                  <Box
                    key={`thread-${threadIdx + 1}`}
                    onClick={() => {
                      handleViewConversation(threadId);
                    }}
                  >
                    <Stack mx={3} py={2}>
                      {emailValues.length > 0 && (
                        <>
                          <Divider />
                          <Box mt={2}>
                            {emailValues[0]?.subject || '(No subject line)'}
                          </Box>
                        </>
                      )}
                    </Stack>
                  </Box>
                )
              )}
            </AccordionDetails>
          </Accordion>
        )
      )}
    </Stack>
  );
}

export default Conversations;
