import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

function SelectInboxesToCheck({
  onClose,
}: {
  onClose: () => void;
}): React.ReactNode {
  const { inbox, setStoreState } = useAlchemystStoreForAi((store) => ({
    inbox: store.inbox,
    setStoreState: store.setStoreState,
  }));

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchInboxEmails = async () => {
    setLoading(true);
    try {
      const response = await fetchWithRewrites(
        '/api/inbox/list?emailsOnly=true'
      );
      if (!response.ok) {
        setError('Error fetching inbox emails');
        return;
      }

      const responseJson = await response.json();
      console.log('Inbox emails:', responseJson.data);
      setStoreState({
        inbox: {
          ...inbox,
          inboxEmails: responseJson.data.map((item: any) => item.emailId),
        },
      });
    } catch (error) {
      console.error('Error fetching inbox emails:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInboxEmails();
  }, []);

  useEffect(() => {
    console.log('Selected inbox emails:', inbox.selectedInboxEmails);
  }, [inbox.selectedInboxEmails]);

  const handleToggleInboxSelection = (email: string) => {
    const updatedSelectedEmails = inbox.selectedInboxEmails.includes(email)
      ? inbox.selectedInboxEmails.filter((e) => e !== email)
      : [...inbox.selectedInboxEmails, email];
    setStoreState({
      inbox: {
        ...inbox,
        selectedInboxEmails: updatedSelectedEmails,
      },
    });
  };

  return (
    <Box>
      <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
        Select Inbox Emails
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
        Select the emails that you want to use for the AI
      </Typography>
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
      {!loading && !error && (
        <Box>
          <Stack direction="column" spacing={1}>
            {(inbox?.inboxEmails ?? []).map((email) => (
              <Box key={email} sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={inbox.selectedInboxEmails.includes(email)}
                  onChange={() => {
                    handleToggleInboxSelection(email);
                  }}
                />
                <Typography variant="body1">{email}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        disabled={loading}
        onClick={onClose}
        sx={{ mt: 2 }}
        aria-label="Confirm inbox email selection"
      >
        Confirm Selection
      </Button>
    </Box>
  );
}

export default SelectInboxesToCheck;
