import featuresBenefitsImage from '../../assets/fields/list-input.png';
import supportingEvidenceListImage from '../../assets/fields/list.png';
import minMaxRangeImage from '../../assets/fields/min-max.png';
import SelectImage from '../../assets/fields/select.png';
import toggleSwitchImage from '../../assets/fields/switch.png';
import multilineTextInputImage from '../../assets/fields/textarea.png';

export const fieldOptions: {
  type: string;
  name: string;
  description: string;
  image: string;
}[] = [
  {
    type: 'input.select',
    name: 'Dropdown Selector',
    description:
      'Allows users to select one or multiple options from a dropdown list.',
    image: SelectImage,
  },
  {
    type: 'min_max_range',
    name: 'Min-Max Range',
    description:
      'Enables users to specify a range with minimum and maximum values.',
    image: minMaxRangeImage,
  },
  {
    type: 'input.textarea',
    name: 'Text Area Input',
    description: 'Provides a multi-line input for detailed text entries.',
    image: multilineTextInputImage,
  },
  {
    type: 'list_of_features',
    name: 'Features and Benefits',
    description:
      'Lists key features and corresponding benefits to highlight product value.',
    image: featuresBenefitsImage,
  },
  {
    type: 'list_of_text_inputs',
    name: 'Supporting Evidence List',
    description: 'Displays points or statements to support claims or features.',
    image: supportingEvidenceListImage,
  },
  {
    type: 'input.switch',
    name: 'Toggle Switch',
    description: 'A simple switch for yes/no or true/false selections.',
    image: toggleSwitchImage,
  },
];
