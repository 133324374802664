import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { useEffect, useState } from 'react';

function Documents() {
  const [documents, setDocuments] = useState<string[]>();

  useEffect(() => {
    async function fetchDocuments() {
      const res = await fetchWithRewrites('/api/context/view/docs', {
        method: 'GET',
      });
      const data = await res.json();

      if (res.ok) {
        setDocuments(data.documents);
      }

      console.log('The Data', data);
    }

    fetchDocuments();
  }, []);

  const handleDelete = async (fileName: string) => {
    //TODO actual logic to delete the document
    const res = await fetchWithRewrites('/api/context/delete', {
      method: 'POST',
      body: JSON.stringify({
        source: fileName,
        by_doc: true,
        by_id: false,
      }),
    });
    setDocuments(documents?.filter((file) => file !== fileName));
  };

  return (
    <Box>
      <List>
        {documents?.map((file, index) => (
          <ListItem
            sx={{
              border: '1px solid gray',
              borderRadius: '0.3rem',
            }}
            key={index}
            secondaryAction={
              <IconButton edge="end" onClick={() => handleDelete(file)}>
                <DeleteIcon color="error" />
              </IconButton>
            }
          >
            <ListItemText primary={file} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default Documents;
