import { mainDrawerComponents } from '@/constants';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { Box, Drawer, Stack, Typography } from '@mui/material';
import { useNavigate, usePath } from 'raviger';
import { useEffect } from 'react';
import AlchemystSelection from './AlchemystSelection';

interface LeftDrawerProps {
  drawerWidth: string | number;
}

function LeftDrawer({ drawerWidth }: LeftDrawerProps): React.ReactNode {
  const isNavBarDrawerOpen = useAlchemystStoreForAi(
    (store) => store.isNavBarDrawerOpen
  );
  const currentTheme = useAlchemystStoreForAi((store) => store.theme);
  const drawerComponents = useAlchemystStoreForAi(
    (store) => store.drawerComponents
  );
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);

  const navigate = useNavigate();
  const pathname = usePath();

  useEffect(() => {
    setStoreState({ drawerComponents: mainDrawerComponents });
  }, [setStoreState]);

  const renderDrawerComponents = (
    components: typeof mainDrawerComponents,
    paddingLeft = 2
  ) => {
    return components.map((drawerComponent, index) => (
      <Box
        key={`menu-option-${index}`}
        onClick={() => navigate(drawerComponent.path)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          // paddingLeft: paddingLeft,
          paddingY: 1,
          paddingX: 1,
          borderRadius: 2,
          cursor: 'pointer',
          transition: '0.2s',
          width: '100%',
          '&:hover': {
            background: 'rgba(255, 255, 255, 0.1)',
          },
          ...(pathname === drawerComponent.path && currentTheme === 'dark'
            ? {
                background: 'rgba(255, 255, 255, 0.1)',
              }
            : {}),
          ...(pathname === drawerComponent.path && currentTheme === 'light'
            ? {
                background: 'rgba(0, 0, 0, 0.1)',
              }
            : {}),
        }}
      >
        {drawerComponent.icon}
        <Typography
          sx={{
            marginX: 1,
            fontWeight: 'semibold',
          }}
        >
          {drawerComponent.name}
        </Typography>
        {drawerComponent.children &&
          renderDrawerComponents(drawerComponent.children, paddingLeft + 2)}
      </Box>
    ));
  };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      sx={{
        width: isNavBarDrawerOpen ? drawerWidth : '5%',
        alignItems: 'center',
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          border: '3px solid',
          borderRadius: 2,
          background:
            currentTheme === 'dark'
              ? 'linear-gradient(320deg, #000000 0%, #0A0A0A 100%)'
              : 'linear-gradient(320deg, #ff9a3520 1.52%, #00000000 90.82%)',
          borderImageSource:
            'linear-gradient(270deg, #5A5A5A20 1.52%, #00000000 90.98%)',
          borderImageSlice: 1,
        },
      }}
      open={isNavBarDrawerOpen}
    >
      <Stack
        spacing={2}
        alignItems={'center'}
        alignContent={'center'}
        alignSelf={'center'}
        display={'flex'}
        justifyItems={'center'}
        direction={'column'}
        justifyContent={'space-between'}
      >
        <Box
          sx={{
            paddingY: 4,
          }}
        />
        {/* <img
          src="https://alchemyst-updated.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FAI.e605ee6d.png&w=1080&q=75"
          alt="logo"
          width={200}
          height={70}
          className={`${currentTheme === 'dark' ? '' : 'invert'}`}
        /> */}
        <AlchemystSelection />
        <Stack
          spacing={1}
          display={'flex'}
          direction={'column'}
          justifyContent={'space-between'}
          alignContent={'center'}
          alignItems={'start'}
          sx={{
            scrollBehavior: 'smooth',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {renderDrawerComponents(drawerComponents)}
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default LeftDrawer;
