import { fetchWithRewrites } from '../fetchWithRewrites';
import { createTimeoutSignal } from '../signalConfig';

const fetchThreadIdListClientSide = async (
  emailToCheck: string[],
  selectedInboxes: string[] = []
): Promise<{ threads: string[]; messages: Record<string, any>[] }> => {
  try {
    const response = await fetchWithRewrites('/api/inbox', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailsToCheck: emailToCheck,
        selectedInboxes,
      }),
      signal: createTimeoutSignal(),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const threadIdData: {
      threads: string[];
      messages: Record<string, any>[];
    } = await response.json();
    return threadIdData;
  } catch (error) {
    console.error('Failed to fetch thread IDs at client side:', error);
    return { threads: [], messages: [] };
  }
};

export default fetchThreadIdListClientSide;
