import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import type { MinMaxRangeProps } from './types';

const MinMaxRange: React.FC<MinMaxRangeProps> = ({
  name,
  min_placeholder,
  max_placeholder,
  value = { min: 0, max: 0 },
  onChange,

  sx,
}) => {
  const [min, setMin] = useState<number>(value.min || 0);
  const [max, setMax] = useState<number>(value.max || 0);
  const handleMinChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMin(Number(event.target.value));
    onChange({ min: Number(event.target.value), max });
  };
  const handleMaxChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMax(Number(event.target.value));
    onChange({ min, max: Number(event.target.value) });
  };
  return (
    <Box>
      <Typography gutterBottom variant="h6">
        {name}
      </Typography>
      <Box alignItems="center" display="flex">
        <TextField
          label="max"
          onChange={(event) => {
            const value = Number(event.target.value);
            if (value >= min) {
              handleMaxChange(event);
            }
          }}
          placeholder={max_placeholder}
          sx={sx}
          type="number"
          value={max}
        />

        <span style={{ margin: '0 10px', fontSize: '2rem', color: 'gray' }}>
          -
        </span>
        <TextField
          label="min"
          onChange={(event) => {
            const value = Number(event.target.value);
            if (value <= max) {
              handleMinChange(event);
            }
          }}
          placeholder={min_placeholder}
          sx={sx}
          type="number"
          value={min}
        />
      </Box>
    </Box>
  );
};
export default MinMaxRange;
