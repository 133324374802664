import { StyledButton } from '@/constants/themes';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import type { AlchemystStoreType } from '@/types/appStates';
import { Add, Delete } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import type { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface LeadsTableViewProps {
  leads: AlchemystStoreType['leads'];
  fields: AlchemystStoreType['fields'];
  addColumnHandler?: () => void;
  deleteColumnHandler?: (fieldToDelete: string) => void;
  updateRowHandler?: (params: GridRowModel) => GridRowModel;
  addRowHandler?: () => void;
  deleteRowsHandler?: (rowsToDelete: number[]) => void;
  hideFooter?: boolean;
  showSelection?: boolean;
  selection?: boolean;
}

function LeadsTableView({
  leads,
  fields,
  addColumnHandler,
  deleteColumnHandler,
  updateRowHandler,
  addRowHandler,
  deleteRowsHandler,
  hideFooter = true,
  showSelection = true,
  selection = true,
}: LeadsTableViewProps): React.ReactNode {
  const { selectedLeads, setStoreState, leadSets, activeLeadSetId } =
    useAlchemystStoreForAi((store) => ({
      selectedLeads: store.selectedLeads,
      setStoreState: store.setStoreState,
      leadSets: store.leadSets,
      activeLeadSetId: store.activeLeadSetId,
    }));
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  // Initialize selectedRows from selectedLeads when component mounts or selectedLeads changes
  useEffect(() => {
    if (selectedLeads.length > 0) {
      // Find indices of selectedLeads in the leads array using field-by-field comparison
      const indices = selectedLeads
        .map((selectedLead) =>
          leads.findIndex((lead) => {
            // Compare lead properties except system properties
            const leadKeys = Object.keys(lead).filter(
              (k) =>
                !['id', 'status', 'progress'].includes(k) && !k.startsWith('_')
            );
            const selectedLeadKeys = Object.keys(selectedLead).filter(
              (k) =>
                !['id', 'status', 'progress'].includes(k) && !k.startsWith('_')
            );

            // If key lengths don't match, they're not the same
            if (leadKeys.length !== selectedLeadKeys.length) return false;

            // Check if all key-value pairs match
            return leadKeys.every((key) => lead[key] === selectedLead[key]);
          })
        )
        .filter((index) => index !== -1);

      setSelectedRows(indices);
    } else {
      setSelectedRows([]);
    }
  }, [selectedLeads, leads]);

  useEffect(() => {
    console.log('Number of leads = ', leads.length);
  }, [leads]);

  const renderHeaderWithDelete = useCallback(
    (params: any) => (
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="body1">{params.colDef.headerName}</Typography>
        {deleteColumnHandler ? (
          <IconButton
            aria-label={`delete column ${params.colDef.headerName}`}
            color="error"
            onClick={() => {
              deleteColumnHandler(params.colDef.field);
            }}
            size="small"
          >
            <Delete fontSize="small" />
          </IconButton>
        ) : null}
      </Stack>
    ),
    [deleteColumnHandler]
  );

  const handleRowSelectionChange = (params: any) => {
    // Ensure params is an array of row indices
    const selectedIndices = Array.isArray(params) ? params : [];

    // Map indices to the actual lead objects
    const newSelectedLeads = selectedIndices.map((index) => leads[index]);

    if (activeLeadSetId) {
      // Update the lead set's selectedLeads
      setStoreState({
        leadSets: {
          ...leadSets,
          [activeLeadSetId]: {
            ...leadSets[activeLeadSetId],
            selectedLeads: newSelectedLeads,
          },
        },
        // Also update legacy selectedLeads
        selectedLeads: newSelectedLeads,
      });
    } else {
      // Legacy behavior
      setStoreState({ selectedLeads: newSelectedLeads });
    }

    setSelectedRows(selectedIndices);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        {addRowHandler ? (
          <StyledButton
            onClick={addRowHandler}
            startIcon={<Add />}
            sx={{ mt: 0.5, p: 0.4 }}
          >
            Add Lead
          </StyledButton>
        ) : null}
        {deleteRowsHandler && selectedRows.length > 0 ? (
          <StyledButton
            onClick={() => {
              deleteRowsHandler(selectedRows);
            }}
            startIcon={<Delete />}
            sx={{ mt: 0.5, p: 0.4 }}
          >
            Delete
          </StyledButton>
        ) : null}

        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  const rows = useMemo(
    () => leads.map((lead, index) => ({ id: index, ...lead })),
    [leads]
  );

  const columns: GridColDef[] = useMemo(() => {
    const baseColumns: GridColDef[] = fields
      .filter((field) => field !== 'id')
      .map((field) => ({
        field,
        headerName: field,
        minWidth: 200,
        maxWidth: 300,
        editable: true,
        renderHeader: renderHeaderWithDelete,
      }));

    if (addColumnHandler) {
      const addColumn: GridColDef = {
        field: 'addColumn',
        headerName: 'Add Column',
        minWidth: 200,
        maxWidth: 300,
        renderHeader: () => (
          <Button onClick={addColumnHandler} startIcon={<Add />} variant="text">
            Add Column
          </Button>
        ),
      };
      baseColumns.push(addColumn);
    }
    return baseColumns;
  }, [addColumnHandler, fields, renderHeaderWithDelete]);

  return (
    <DataGridPro
      checkboxSelection={showSelection}
      columns={columns}
      hideFooter={hideFooter}
      onRowSelectionModelChange={handleRowSelectionChange}
      rows={rows}
      rowSelectionModel={selectedRows}
      showCellVerticalBorder
      rowSelection={selection}
      slots={{ toolbar: CustomToolbar }}
      sx={{
        backgroundColor: 'background.paper',
      }}
      editMode="cell"
      // autosizeOnMount
      processRowUpdate={updateRowHandler}
    />
  );
}

export default LeadsTableView;
