import React, { useState, useEffect, useCallback } from 'react';
import { Box, Container, Typography } from '@mui/material';
import CommunicationsSideSheet from './CommunicationsSideSheet';
import MessagesDisplay from './MessagesDisplay';
import { MessageType, PlatformType } from './types';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import { ChatHistoryType } from '@/types/converse/chatHistory';

const CommunicationsPage: React.FC = () => {
  const [selectedPlatform, setSelectedPlatform] =
    useState<PlatformType>('email');
  const [selectedTranscriptId, setSelectedTranscriptId] = useState<
    string | null
  >(null);
  const [currentMessages, setCurrentMessages] = useState<MessageType[]>([]);

  const { chatHistory, setStoreState, chatChannel } = useAlchemystStoreForAi(
    (store) => ({
      chatHistory: store.chatHistory,
      setStoreState: store.setStoreState,
      chatChannel: store.chatChannel,
    })
  );

  // Memoize setChatHistory function
  const setChatHistory = useCallback(
    (chats: ChatHistoryType[]): void => {
      setStoreState({ chatHistory: chats });
    },
    [setStoreState]
  );

  // Fetch chat history from the API
  const fetchHistory = useCallback(async () => {
    try {
      const chatHistoryResponse = await fetchWithRewrites(
        `/api/chat/history?source=${chatChannel}`,
        {
          signal: createTimeoutSignal(),
        }
      );
      const chatHistoryJson: { chats: ChatHistoryType[] } =
        await chatHistoryResponse.json();
      setChatHistory(chatHistoryJson.chats ?? []);
    } catch (error) {
      console.error('Failed to fetch chat history:', error);
      setChatHistory([]);
    }
  }, [chatChannel, setChatHistory]);

  // Fetch chat history when component mounts or chatChannel changes
  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      if (!isSubscribed) return;
      await fetchHistory();
    };

    fetchData();

    return () => {
      isSubscribed = false;
    };
  }, [fetchHistory]);

  // Handle platform change
  const handlePlatformChange = useCallback((platform: PlatformType) => {
    setSelectedPlatform(platform);
    setSelectedTranscriptId(null);
    setCurrentMessages([]);
  }, []);

  // Handle transcript selection
  const handleTranscriptSelect = useCallback((transcriptId: string) => {
    setSelectedTranscriptId(transcriptId);
  }, []);

  return (
    <CommunicationsSideSheet
      selectedPlatform={selectedPlatform}
      onPlatformChange={handlePlatformChange}
      selectedTranscriptId={selectedTranscriptId}
      onTranscriptSelect={handleTranscriptSelect}
      setCurrentChatID={setSelectedTranscriptId}
    >
      <Container maxWidth="xl" sx={{ py: 4, mt: 4 }}>
        <Typography variant="h4" sx={{ mb: 4 }}>
          Transcripts Viewer
        </Typography>

        <Box sx={{ height: '75vh', width: '100%' }}>
          {selectedTranscriptId ? (
            <MessagesDisplay
              messages={currentMessages}
              selectedChatId={selectedTranscriptId}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                bgcolor: 'background.paper',
                borderRadius: 2,
                p: 3,
              }}
            >
              <Typography variant="h6" color="text.secondary">
                Select a conversation from the sidebar to view messages
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </CommunicationsSideSheet>
  );
};

export default CommunicationsPage;
