import { StyledButton } from '@/constants/themes';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';

export interface ModalOpenProps {
  addEmailModal: boolean;
  inboxConfigModal: boolean;
  imapModal: boolean;
}

export interface InboxMenuProps {
  fetchingEmails: boolean;
  handleEmailsFetching: () => void;
  isMenuOpen: boolean;
  handleMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  menuAnchorEl: null | HTMLElement;
  handleMenuClose: () => void;
  modalOpen: ModalOpenProps;
  setModalOpen: React.Dispatch<React.SetStateAction<ModalOpenProps>>;
}

function InboxMenu({
  fetchingEmails,
  handleEmailsFetching,
  isMenuOpen,
  handleMenuClick,
  menuAnchorEl,
  handleMenuClose,
  modalOpen,
  setModalOpen,
}: InboxMenuProps): React.ReactNode {
  return (
    <Box display="flex" justifyContent="space-between">
      <Stack spacing={2}>
        <Typography variant="h4">Inbox</Typography>
        <Typography>View mails</Typography>
      </Stack>
      <Box display="flex" gap={2} my={3}>
        <Tooltip
          title={
            fetchingEmails
              ? 'Fetching Mails, please wait atleast 30 seconds before commencing next fetch'
              : 'Fetch emails from inbox'
          }
          arrow
        >
          <span>
            <StyledButton
              disabled={fetchingEmails}
              onClick={handleEmailsFetching}
              sx={{ width: '100%' }}
              variant="contained"
            >
              Fetch Mails
            </StyledButton>
          </span>
        </Tooltip>
        <StyledButton
          aria-controls={isMenuOpen ? 'menu' : undefined}
          aria-expanded={isMenuOpen ? 'true' : undefined}
          aria-haspopup="true"
          sx={{
            fontSize: '16px',
            borderRadius: '8px',
            alignContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleMenuClick}
          // disabled={!inboxAuthenticated}
          variant="text"
        >
          <MoreVertIcon />
        </StyledButton>
      </Box>
      <Menu
        anchorEl={menuAnchorEl}
        id="menu"
        onClose={handleMenuClose}
        open={isMenuOpen}
      >
        <MenuItem
          onClick={() => {
            setModalOpen((prev) => ({
              ...prev,
              addEmailModal: true,
            }));
          }}
        >
          Add Email
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalOpen((prev) => ({
              ...prev,
              imapModal: true,
            }));
          }}
        >
          Add Inbox
        </MenuItem>
        <MenuItem
          onClick={() => {
            setModalOpen((prev) => ({
              ...prev,
              inboxConfigModal: true,
            }));
          }}
        >
          Inbox Config
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default InboxMenu;
