import { Step } from '@/types/campaign';
import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';

interface CustomStepProps {
  index: number;
  activeStep: number;
  value: Step;
  onChange: () => void;
  onEdit: (value: string) => void;
  onRemove: () => void;
}

function CustomStep({
  index,
  activeStep,
  value,
  onChange,
  onEdit,
  onRemove,
}: CustomStepProps): React.ReactNode {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: 1,
        position: 'relative',
        '&:hover': { cursor: 'pointer' },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {hover && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onEdit(value.step_name);
            }}
          >
            <Edit sx={{ color: 'white' }} />
          </IconButton>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            width: 40,
            backgroundColor: index === activeStep ? 'primary.main' : 'gray',
            borderRadius: '50%',
            color: 'white',
            cursor: 'pointer',
          }}
          onClick={onChange}
        >
          <Typography>{index + 1}</Typography>
        </Box>

        {hover && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          >
            <Delete sx={{ color: 'white' }} />
          </IconButton>
        )}
      </Box>

      <Typography variant="body2">{value.step_name}</Typography>
    </Box>
  );
}

export default CustomStep;
