import useScreenDimensions from '@/hooks/useScreenDimensions';
import { ChildComponentDetails } from '@/types/workx/workbench';
import { generateCode } from '@/utils/ai/componentGeneration';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableComponent from './DraggableComponent'; // Import the new component

const Workbench = ({
  components,
  setComponents,
}: {
  components: ChildComponentDetails[];
  setComponents: (children: ChildComponentDetails[]) => void;
}) => {
  const [textBoxPosition, setTextBoxPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const { width, height } = useScreenDimensions();
  const [textBoxOpen, setTextBoxOpen] = useState<boolean>(false);
  const [currentUserQuery, setCurrentUserQuery] = useState('');

  console.log(components);

  const handleClose = () => {
    setTextBoxOpen(false);
    setTextBoxPosition(null);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setTextBoxOpen(true);
    setTextBoxPosition({ x: event.clientX, y: event.clientY });
  };

  const handleComponentCreationStart = async () => {
    setTextBoxOpen(false);
    const coordinates = textBoxPosition;

    setComponents([
      ...components,
      {
        coordinates: coordinates ?? { x: width * 0.5, y: height * 0.5 },
        html:
          (await generateCode(currentUserQuery)) ??
          "<div>Sorry, I wasn't able to generate a component</div>",
        prompt: currentUserQuery,
      },
    ]);

    setTextBoxPosition(null); // Close the text box first.
    setCurrentUserQuery('');
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          minWidth: width * 0.5,
          height,
        }}
        onDoubleClick={handleDoubleClick}
      >
        {components.map((child, idx) => (
          <DraggableComponent
            key={child.id ?? `workbench-container-${idx}`}
            id={child.id}
            html={child.html as never} // FIXME: Fix types
            coordinates={child.coordinates}
          />
        ))}
        <Typography
          zIndex={-10000}
          sx={(theme) => ({
            position: 'absolute',
            top: height * 0.5,
            left: width * 0.5,
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)', // Adjust color based on theme mode
          })}
          variant="h2"
        >
          Double click to start creating components
        </Typography>
        {textBoxOpen && textBoxPosition && (
          <Stack
            direction={'row'}
            spacing={3}
            sx={{
              position: 'absolute',
              top: textBoxPosition.y,
              left: textBoxPosition.x,
              maxWidth: width * 0.4,
              minWidth: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 2,
              transform: 'translate(-50%, -50%)',
              flex: 1,
            }}
          >
            <TextField
              fullWidth
              label="Enter text"
              variant="outlined"
              value={currentUserQuery}
              onChange={(e) => {
                setCurrentUserQuery(e.target.value);
              }}
              multiline
              draggable
              maxRows={3}
              sx={{ mb: 2 }}
            />
            <Stack spacing={0.5}>
              <Button
                variant="outlined"
                size="small"
                onClick={handleComponentCreationStart}
              >
                Go
              </Button>
              <Button
                variant="text"
                type="reset"
                size="small"
                onClick={handleClose}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </DndProvider>
  );
};

export default Workbench;
