import { defaultAlchemysts, sampleEmailInboxCredentials } from '@/constants';
import { AlchemystStoreType, SnackBarEntry } from '@/types/appStates';
import { create } from 'zustand';

const useAlchemystStoreForAi = create<AlchemystStoreType>((set) => ({
  // Initial state setup
  alchemyst: defaultAlchemysts[0],
  isNavBarDrawerOpen: true,
  isNavBarDrawerPresent: false,
  drawerComponents: [],
  chatChannel: 'platform.converse',
  chatHistory: [],
  theme: 'dark',
  settings: {
    isAutopilot: false,
  },
  leadsMapSettings: {},
  // New structure for multiple lead sets
  leadSets: {},
  activeLeadSetId: null,
  leads: [],
  fields: [],
  localLeads: [],
  localFields: [],
  prospectorQuery: {
    searchParams: {
      page: 1,
      per_page: 100,
      annual_revenue: [],
      employee_count: [],
      industries: [],
      titles: [],
      countries: [],
      name: '',
      technologies: [],
      company_domains: [],
      total_funding: [],
      keywords: [],
      states: [],
      cities: [],
    },
  },
  bigQuery: {
    searchParams: {
      page: 1,
      per_page: 100,
      countries: [],
      region: [],
      job_title: [],
      industry: [],
      company_name: [],
      company_size: [],
      skills: [],
      company_founded: [],
    },
  },
  selectedLeads: [],
  inbox: {
    inboxEmails: [],
    threadIds: [],
    emailsToCheck: [],
    autoFetchCampaignEmails: false,
    selectedInboxEmails: [],
  },
  snackbarEntries: [],
  emailInboxCredentials: sampleEmailInboxCredentials,
  currentScrapingSession: null,
  alchemystCopilotAction: null,
  currentFieldNames: [],
  currentAugmentorOutput: null,
  emailBodiesByThreadByEmail: {},
  inboxAuthenticated: false,
  isResearchMode: false,
  campaignWorkflows: [],

  // Generic setState method
  setStoreState: (newState: Partial<AlchemystStoreType>) => {
    set((state) => ({ ...state, ...newState }));
  },
}));

const updateSnackbarEntry = (entryUpdated: Partial<SnackBarEntry>) => {
  const snackbarEntries = useAlchemystStoreForAi.getState().snackbarEntries;
  const setSnackbarEntries = useAlchemystStoreForAi.getState().setStoreState;
  const entryToBeModified = snackbarEntries.filter(
    (entry) => entry.notificationId !== entryUpdated.notificationId
  );

  if (entryToBeModified.length === 0) {
    console.log('No entry to be modified.');
    return;
  }

  const filteredSnackbarEntries = snackbarEntries.filter(
    (entry) => entry.notificationId !== entryUpdated.notificationId
  );
  setSnackbarEntries({ snackbarEntries: filteredSnackbarEntries });
  // const modifiedSnackbarEntries = snackbarEntries.map(snackbarEntry => {
  //   if (
  //     snackbarEntry.notificationId === entryUpdated.notificationId &&
  //     !!entryUpdated.notificationId
  //   ) {
  //     return entryUpdated;
  //   } else {
  //     return snackbarEntry;
  //   }
  // });
  const modifiedSnackbarEntries = [
    ...filteredSnackbarEntries,
    { ...entryToBeModified[0], ...entryUpdated },
  ];

  setSnackbarEntries({ snackbarEntries: modifiedSnackbarEntries });
};

export { updateSnackbarEntry };

export default useAlchemystStoreForAi;
