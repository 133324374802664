import { StyledButton } from '@/constants/themes';
import { StepConfig } from '@/types/campaign';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface VoiceSpaceTabProps {
  currentStep: StepConfig | null;
  setCurrentStep: (editedConfig: StepConfig | null) => void;
  selectedLeads: Record<string, any>[];
  usingAiFunctions: 'idle' | 'rephrasing' | 'generating';
  setUsingAiFunctions: (newState: 'idle' | 'rephrasing' | 'generating') => void;
  setOpenModal: (newState: boolean) => void;
}
function VoiceSpaceTab({
  currentStep,
  setCurrentStep,
  selectedLeads,
  setOpenModal,
  setUsingAiFunctions,
  usingAiFunctions,
}: VoiceSpaceTabProps): React.ReactNode {
  const [contextInput, setContextInput] = useState<Record<string, any>>(
    currentStep?.voice?.context || {}
  );
  const [fallbackInput, setFallbackInput] = useState<string>(
    currentStep?.voice?.fallback || ''
  );
  const [newFieldName, setNewFieldName] = useState<string>('');

  const handleContextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    currentFieldName: string
  ) => {
    setContextInput((prevContext) => ({
      ...prevContext,
      [currentFieldName]: event.target.value,
    }));

    console.log('Current Voice context = ', contextInput);
  };

  const handleSaveToCurrentStep = (): void => {
    if (!currentStep) return;
    setCurrentStep({
      ...currentStep,
      voice: {
        context: contextInput,
        fallback: fallbackInput,
      } as never,
    });
  };

  useEffect(handleSaveToCurrentStep, [contextInput, fallbackInput]);

  return (
    <Stack spacing={2}>
      <Box display="flex" gap={2} mb={2}>
        <TextField
          label="Default Fallback"
          name="fallback"
          value={fallbackInput}
          onChange={(e) => setFallbackInput(e.target.value)}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Typography>Context</Typography>
      <Stack spacing={2}>
        {Object.entries(contextInput).map(([key, value]) => (
          <Box
            key={`context-${key}-key`}
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="flex-start"
          >
            <TextField
              label="Key Name"
              name={key}
              value={key}
              onChange={(e) => handleContextChange(e, key)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Value"
              name={`value_${key}`}
              value={
                typeof value === 'string'
                  ? value
                  : JSON.stringify(value, null, 2)
              }
              onChange={(e) => handleContextChange(e, key)}
              variant="outlined"
              fullWidth
              multiline={true}
              minRows={2}
              maxRows={5}
            />
            <StyledButton
              variant="contained"
              color="error"
              sx={{
                px: 2,
              }}
              onClick={() => {
                const { [key]: _, ...rest } = contextInput;
                setContextInput(rest);
              }}
            >
              Remove
            </StyledButton>
          </Box>
        ))}
        <Box display="flex" flexDirection="row" gap={2}>
          <TextField
            label="New Field Name"
            name="newFieldName"
            value={newFieldName}
            onChange={(e) => setNewFieldName(e.target.value)}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            disabled={newFieldName === ''}
            onClick={() => {
              setContextInput((prevContext) => ({
                ...prevContext,
                [newFieldName]: '',
              }));
              setNewFieldName('');
            }}
          >
            Add New Field
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

export default VoiceSpaceTab;
