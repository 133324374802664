import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import type { EventData } from '@/types/calendarEvent';
import type { AutoEmail } from '@/types/email';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Modal, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import EmailCard from '../../common/emails/EmailCard';
import ScheduleEventModal from './ScheduleEventModal';

/* -------------------------------------- Utils -------------------------------------- */

const getEmailAddress = (
  emails: Record<string, any>[],
  type: 'external' | 'inbox'
): string | undefined => {
  console.log('Emails = ');
  console.log(emails);
  if (emails.length === 0) {
    return;
  }
  if (type === 'inbox') {
    return (emails[0] as any).inbox as string;
  }

  return emails
    .map((email) => email.sender || email.from)
    .filter(
      (fromAddress) => fromAddress !== ((emails[0] as any).inbox as string)
    )
    .pop();
};

const textToHtml = (text: string) => {
  return text.replace(/\n/g, '<br />');
};

const addMinutesToDate = (dateString: string, minutes: number): Date => {
  const date = new Date(dateString);
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

/* -------------------------------------- Component -------------------------------------- */

interface ReplyActionBarProps {
  emailsInThread: Record<string, any>[];
  threadId: string;
}

function ReplyActionBar({ emailsInThread, threadId }: ReplyActionBarProps) {
  const [generatedContent, setGeneratedContent] = useState<string>('');

  const [generateBtnLoading, setGenerateBtnLoading] = useState<boolean>(false);
  const [emailEditorModalOpen, setEmailEditorModalOpen] =
    useState<boolean>(false);

  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);
  const [eventBtnLoading, setEventBtnLoading] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [isSendActive, setIsSendActive] = useState<boolean>(false);

  const { setStoreState, snackbarEntries } = useAlchemystStoreForAi(
    (store) => ({
      setStoreState: store.setStoreState,
      snackbarEntries: store.snackbarEntries,
    })
  );

  const senderEmail = getEmailAddress(emailsInThread, 'inbox');
  const receiverEmail = getEmailAddress(emailsInThread, 'external');

  const [emailReply, setEmailReply] = useState<AutoEmail>({
    approved: false,
    body: '',
    date: new Date().toISOString(),
    from: senderEmail ?? '',
    subject: '',
    to: receiverEmail ?? '',
  });

  useEffect(() => {
    console.log('THE EMAI REPLY', emailReply);
  }, [emailReply]);

  const [eventData, setEventData] = useState<EventData>({
    description: '',
    subject: '',
    startTime: new Date(),
    endTime: new Date(),
    invitees: [],
    meetingLink: '',
    senderEmail: '',
  });

  const { addLogs } = useLogging();

  /* -------------------------------------- Handlers -------------------------------------- */
  /** Send the generated reply */
  const handleFetchReply = async (): Promise<void> => {
    setGenerateBtnLoading(true);

    try {
      const response = await fetchWithRewrites('/api/emails/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          emailsInThread.map((email) => ({
            from: email.to,
            to: email.from,
            text: email.text,
            date: email.date,
          }))
        ),
        signal: createTimeoutSignal(),
      });

      console.log(response);

      if (!response.ok) {
        console.error(
          `Failed to generate reply. Status: ${response.status}, Message: ${response.statusText}`
        );
        return;
      }

      const replyJson = await response.json();

      if (!replyJson?.text) {
        console.error('Reply text is missing in the response.');
        return;
      }

      setGeneratedContent(replyJson.text);
      setIsSendActive(true);
    } catch (error) {
      if ((error as Error).name === 'AbortError') {
        console.error('Request timed out.');
      } else {
        console.error('Network error:', error);
      }
    } finally {
      setGenerateBtnLoading(false);
    }
  };

  const handleReject = (rejectedEmail: AutoEmail) => {
    // setEmails(
    //   emails.filter(
    //     (email) =>
    //       email.to !== rejectedEmail.to && email.body !== rejectedEmail.body
    //   )
    // );
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          message: `Email to ${rejectedEmail.to} rejected`,
          severity: 'error',
          type: 'alert',
          notificationId: crypto.randomUUID(),
        },
      ],
    });
    setEmailEditorModalOpen(false);
  };

  const handleEmailChange = (updatedEmail: AutoEmail) => {
    console.log('Updated Email received at handleEmailChange = ', updatedEmail);
    setEmailReply((prevState) => {
      console.log('Previous state in handleEmailChange = ', prevState);
      return {
        ...prevState,
        ...updatedEmail,
      };
    });
  };

  const handleApprove = async (approvedEmail: AutoEmail) => {
    setLoadingStatus(true);
    if (!senderEmail || !receiverEmail) {
      return;
    }

    const email = {
      sender: senderEmail,
      from: senderEmail,
      to: receiverEmail,
      subject: approvedEmail.subject,
      html: textToHtml(approvedEmail.body),
      text: approvedEmail.body,
      messageId: emailsInThread[0].messageId.replace(/^<|>$/g, ''),
      references: emailsInThread.map((email) =>
        email.messageId.replace(/^<|>$/g, '')
      ),
    };

    try {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Sending email to ${approvedEmail.to}`,
            severity: 'info',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });

      const response = await fetchWithRewrites('/api/emails/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(email),
        signal: createTimeoutSignal(),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      addLogs([
        {
          context: 'inbox:approved-email',
          message: `Approved email to ${approvedEmail.to}`,
          metadata: { email },
          timestamp: Date.now(),
        },
      ]);
      setEmailEditorModalOpen(false);
    } catch (error) {
      console.error('Failed to send email:', error);
      addLogs([
        {
          context: 'inbox:failed-approved-email',
          message: `Failed to send email to ${approvedEmail.to}`,
          metadata: { email },
          timestamp: Date.now(),
        },
      ]);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Failed to send email to ${approvedEmail.to}`,
            severity: 'error',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    } finally {
      setLoadingStatus(false);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Email to ${approvedEmail.to} approved`,
            severity: 'success',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    }
  };

  const handleFetchEventData = async () => {
    setEventBtnLoading(true);
    try {
      const response = await fetchWithRewrites('/api/calendar/native/check', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emails: emailsInThread }),
        signal: createTimeoutSignal(),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const result = await response.json();
      const eventData: EventData = {
        description: result.event.description,
        subject: result.event.subject,
        startTime: result.event.startTime,
        endTime: addMinutesToDate(result.event.startTime, 60),
        invitees: Array.from(
          new Set([
            ...emailsInThread.map((email) => email.from),
            ...emailsInThread.map((email) => email.to),
          ])
        ),
        meetingLink: result.event.meetingLink ?? '',
        senderEmail: getEmailAddress(emailsInThread, 'inbox') ?? '',
      };
      setEventData(eventData);
      setIsEventModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch event data:', error);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            severity: 'error',
            message: 'Failed to fetch event data.',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    }
    setEventBtnLoading(false);
  };

  const handleSendEmail = (): void => {
    console.log('TE STUFF', senderEmail, receiverEmail);
    if (!senderEmail || !receiverEmail) {
      if (!senderEmail) {
        console.log('No sender email found');
      } else {
        console.log("Sender's email is:", senderEmail);
      }
      if (!receiverEmail) {
        console.log('No receiver email found');
      } else {
        console.log("Receiver's email is:", receiverEmail);
      }
      return;
    }
    setEmailReply({
      approved: false,
      body: generatedContent,
      date: new Date().toISOString(),
      from: senderEmail,
      subject: `Re: ${emailsInThread[0].subject}`,
      to: receiverEmail,
    });
    setEmailEditorModalOpen(true);
  };

  const memoizedObject = useMemo(() => {
    console.log('Email Reply from useMemo = ', emailReply);
    return (
      <EmailCard
        email={emailReply}
        fullWidth
        handleApprove={handleApprove}
        handleEmailChange={handleEmailChange}
        handleReject={handleReject}
        maxRows={10}
        sendingMail={loadingStatus}
        asReply={true}
      />
    );
  }, [emailReply]);
  /* -------------------------------------- JSX -------------------------------------- */

  return (
    <Box
      bottom={0}
      display="flex"
      gap={2}
      justifyContent="space-between"
      right={0}
      width="100%"
      // position="absolute"
    >
      <TextField
        fullWidth
        multiline
        onChange={(e) => {
          setGeneratedContent(e.target.value);
        }}
        rows={4}
        sx={{
          my: 2,
        }}
        value={generatedContent}
      />
      <Box display="flex" justifyContent="space-between" width="25vw">
        <Stack my={2} spacing={2} width="10vw">
          <Button
            color="primary"
            disabled={generateBtnLoading}
            fullWidth
            onClick={handleFetchReply}
            startIcon={<RateReviewIcon />}
            variant="contained"
          >
            Generate Reply
          </Button>
          <Button
            color="primary"
            disabled={!isSendActive}
            onClick={handleSendEmail}
            startIcon={<SendIcon />}
            variant="contained"
          >
            Send Reply
          </Button>
        </Stack>
        <Button
          disabled={eventBtnLoading}
          onClick={handleFetchEventData}
          sx={{
            m: 2,
          }}
          variant="contained"
        >
          <Box>
            <InsertInvitationIcon />
            <Typography>Schedule Meeting</Typography>
          </Box>
        </Button>
      </Box>
      <Modal
        onClose={() => {
          if (!senderEmail || !receiverEmail) {
            return;
          }
          setEmailReply({
            approved: false,
            body: generatedContent,
            date: new Date().toISOString(),
            from: senderEmail,
            subject: '',
            to: receiverEmail,
          });
          setEmailEditorModalOpen(false);
        }}
        open={emailEditorModalOpen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!!emailReply ? memoizedObject : <></>}
      </Modal>

      {eventData ? (
        <ScheduleEventModal
          eventData={eventData}
          onClose={() => {
            setIsEventModalOpen(false);
          }}
          open={isEventModalOpen}
          setEventData={setEventData}
        />
      ) : null}
    </Box>
  );
}

export default ReplyActionBar;
