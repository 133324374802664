import LeadsTableView from '@/components/Leads/LeadsTableView';
import useCampaignBuilderStore from '@/hooks/useCampaignBuilderStore';
import { CampaignLead } from '@/types/campaign';
import { Stack, Typography } from '@mui/material';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

function CampaignTargets() {
  const { campaignData, setStoreState, metadata } = useCampaignBuilderStore(
    (store) => ({
      campaignData: store.campaignData,
      setStoreState: store.setStoreState,
      metadata: store.metadata,
    })
  );

  // Collect fields from all leads, not just the first one
  const [fields, setFields] = useState<string[]>([]);

  useEffect(() => {
    if (campaignData?.leads && campaignData.leads.length > 0) {
      const allFields = new Set<string>();

      // Collect all unique field names from all leads
      campaignData.leads.forEach((lead) => {
        Object.keys(lead).forEach((key) => {
          // Skip system and metadata fields
          if (
            !key.startsWith('_') &&
            !['id', 'status', 'progress'].includes(key)
          ) {
            allFields.add(key);
          }
        });
      });

      setFields(Array.from(allFields));
    } else {
      setFields([]);
    }

    console.log('Fields = ', fields);
  }, [campaignData?.leads]);

  if (!campaignData) return null;

  const addColumnHandler = (): void => {
    const newField = `Column ${fields.length + 1}`;
    const updatedFields = [...fields, newField];
    const updatedLeads = campaignData.leads.map((lead) => ({
      ...lead,
      [newField]: '',
    }));

    setStoreState({
      campaignData: { ...campaignData, leads: updatedLeads },
    });
  };

  const deleteColumnHandler = (fieldToDelete: string): void => {
    const updatedFields = fields.filter((field) => field !== fieldToDelete);
    const updatedLeads = campaignData.leads.map((lead) => {
      const updatedLead = { ...lead };
      delete updatedLead[fieldToDelete];
      return updatedLead;
    });
    setStoreState({
      campaignData: { ...campaignData, leads: updatedLeads },
    });
  };

  const updateRowHandler = (params: GridRowModel): GridValidRowModel => {
    console.log('Updating row', params);
    // Use row index for updates rather than explicit IDs
    const rowIndex = params.id as number;
    const updatedLeads = [...campaignData.leads];

    if (rowIndex >= 0 && rowIndex < updatedLeads.length) {
      updatedLeads[rowIndex] = params as CampaignLead;
    }

    setStoreState({ campaignData: { ...campaignData, leads: updatedLeads } });
    return params;
  };

  const deleteRowsHandler = (rowsToDelete: number[]): void => {
    // Filter out the rows to delete based on their index
    const updatedLeads = campaignData.leads.filter(
      (_, index) => !rowsToDelete.includes(index)
    );

    setStoreState({ campaignData: { ...campaignData, leads: updatedLeads } });
  };

  const addRowHandler = (): void => {
    const updatedLeads = [
      ...campaignData.leads,
      {
        status: 'None',
        progress: 0,
      },
    ];
    setStoreState({
      campaignData: { ...campaignData, leads: updatedLeads },
    });
  };

  return (
    <Stack maxWidth="80vw" maxHeight="80vh" spacing={2}>
      <Typography variant="h6">Targets</Typography>
      <Typography variant="body2">
        Select the targets for this campaign. You can select from existing
        leads, create a new list of leads or import leads from a CSV file.
      </Typography>
      <LeadsTableView
        addColumnHandler={addColumnHandler}
        addRowHandler={addRowHandler}
        deleteColumnHandler={deleteColumnHandler}
        deleteRowsHandler={deleteRowsHandler}
        fields={fields}
        hideFooter
        leads={campaignData.leads}
        updateRowHandler={updateRowHandler}
      />
    </Stack>
  );
}

export default CampaignTargets;
