import EmailCard from '@/common/emails/EmailCard';
import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import useLogging from '@/hooks/useLogging';
import { AutoEmail } from '@/types/email';
import { fetchWithRewrites } from '@/utils/fetchWithRewrites';
import { createTimeoutSignal } from '@/utils/signalConfig';
// import { getEmailAddress } from "@/utils/email/generateEmail";
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

type AutoEmailsProps = {
  emails: AutoEmail[];
  setEmails: React.Dispatch<React.SetStateAction<AutoEmail[]>>;
};

function AutoEmails({ emails, setEmails }: AutoEmailsProps): React.ReactNode {
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const snackbarEntries = useAlchemystStoreForAi(
    (store) => store.snackbarEntries
  );
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const { addLogs } = useLogging();

  const textToHtml = (text: string) => {
    return text.replaceAll(/\n/g, '<br />');
  };

  const getEmailAddress = async (email: AutoEmail): Promise<string> => {
    return email.from;
  };

  const handleApprove = async (approvedEmail: AutoEmail) => {
    // setLoadingIndex(index);
    // const approvedEmail = emails[index];
    const senderEmail = await getEmailAddress(approvedEmail);
    const email = {
      sender: senderEmail,
      from: senderEmail,
      to: approvedEmail.to,
      subject: approvedEmail.subject,
      html: textToHtml(approvedEmail.body),
      text: approvedEmail.body,
    };

    try {
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Sending email to ${approvedEmail.to}`,
            severity: 'info',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });

      const response = await fetchWithRewrites('/api/emails/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(email),
        signal: createTimeoutSignal(),
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      addLogs([
        {
          context: 'inbox:approved-email',
          message: `Approved email to ${approvedEmail.to}`,
          metadata: { email },
          timestamp: Date.now(),
        },
      ]);

      const _emails = emails.filter(
        (email) =>
          email.to !== approvedEmail.to && email.body !== approvedEmail.body
      );
      setEmails(_emails);
      localStorage.setItem('autoEmails', JSON.stringify(_emails));
    } catch (error) {
      console.error('Failed to send email:', error);
      addLogs([
        {
          context: 'inbox:failed-approved-email',
          message: `Failed to send email to ${approvedEmail.to}`,
          metadata: { email },
          timestamp: Date.now(),
        },
      ]);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Failed to send email to ${approvedEmail.to}`,
            severity: 'error',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    } finally {
      setLoadingIndex(null);
      setStoreState({
        snackbarEntries: [
          ...snackbarEntries,
          {
            message: `Email to ${approvedEmail.to} approved`,
            severity: 'success',
            type: 'alert',
            notificationId: crypto.randomUUID(),
          },
        ],
      });
    }
  };

  const handleReject = (rejectedEmail: AutoEmail) => {
    setEmails(
      emails.filter(
        (email) =>
          email.to !== rejectedEmail.to && email.body !== rejectedEmail.body
      )
    );
    setStoreState({
      snackbarEntries: [
        ...snackbarEntries,
        {
          message: `Email to ${rejectedEmail.to} rejected`,
          severity: 'error',
          type: 'alert',
          notificationId: crypto.randomUUID(),
        },
      ],
    });
  };

  const handleEmailChange = (updatedEmail: AutoEmail) => {
    const updatedEmails = emails.map((email) =>
      email.to === updatedEmail.to ? updatedEmail : email
    );
    setEmails(updatedEmails);
  };

  return (
    <Box>
      <Typography variant="h6" marginBottom={1} color="gray" gutterBottom>
        {emails.length} Emails found
      </Typography>
      {emails.map((email, index) => (
        <EmailCard
          key={`email-to-${email.to}-${email.from}-${index + 1}`}
          email={email}
          handleApprove={handleApprove}
          handleEmailChange={handleEmailChange}
          handleReject={handleReject}
          fullWidth
        />
      ))}
    </Box>
  );
}

export default AutoEmails;
