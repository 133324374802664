import useAlchemystStoreForAi from '@/hooks/ai/client/useAlchemystStoreForAi';
import { CircularProgress, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import LeadsFilterSettings from '@/components/Leads/LeadsFilterSettings';
import {
  ModalContent,
  ModalHeader,
  StyledButton,
  StyledModal,
} from '@/constants/themes';
import { AlchemystStoreType } from '@/types/appStates';
import { Close, SystemUpdateAlt } from '@mui/icons-material';
import { useNavigate } from 'raviger';
import { v4 as uuidv4 } from 'uuid';
import LeadsTableView from './LeadsTableView';

interface Lead extends Record<string, any> {
  id?: number;
}

interface Props {
  localLeads: Lead[];
  localFields: AlchemystStoreType['fields'];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

function LeadsImportModal({
  localLeads,
  localFields,
  open,
  setOpen,
}: Props): React.ReactNode {
  const navigate = useNavigate();
  const leads = useAlchemystStoreForAi((store) => store.leads);
  const leadSets = useAlchemystStoreForAi((store) => store.leadSets);
  const fields = useAlchemystStoreForAi((store) => store.fields);
  const setStoreState = useAlchemystStoreForAi((store) => store.setStoreState);
  const [isImporting, setIsImporting] = useState<boolean>(false);

  const onClose = (): void => {
    setOpen(false);
  };

  const onImportData = (): void => {
    setIsImporting(true);
    const localLeadsWithIds = localLeads.map((lead, index) => ({
      ...lead,
      id: leads.length + index,
    }));
    const newLeadSetId = uuidv4();
    setStoreState({
      leadSets: {
        ...leadSets,
        [newLeadSetId]: {
          leads: [...localLeadsWithIds],
          fields: [...localFields],
          selectedLeads: [],
          fileName: `file-${newLeadSetId}`,
          dateAdded: new Date(),
        },
      },
      activeLeadSetId: newLeadSetId,
      leads: [...leads, ...localLeadsWithIds],
      fields: [...fields, ...localFields],
      selectedLeads: [],
    });
    setTimeout(() => {
      setIsImporting(false);
      onClose();
      navigate('/leads');
    }, 1000);
  };

  const memoizedTable = useMemo(
    () => (
      <LeadsTableView
        fields={localFields}
        hideFooter={false}
        selection={false}
        key={Math.random()}
        leads={localLeads}
        showSelection={false}
      />
    ),
    [localFields, localLeads]
  );
  return (
    <StyledModal onClose={onClose} open={open}>
      <ModalContent
        sx={{
          maxWidth: '65vw',
          maxHeight: '80vh',
          backgroundColor: 'background.paper',
        }}
      >
        <ModalHeader>
          <StyledButton
            color="primary"
            onClick={onImportData}
            startIcon={
              isImporting ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <SystemUpdateAlt />
              )
            }
            variant="contained"
          >
            Import Data to Leads
          </StyledButton>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </ModalHeader>
        <LeadsFilterSettings />
        <Box sx={{ height: '40vh', width: '100%' }}>{memoizedTable}</Box>
      </ModalContent>
    </StyledModal>
  );
}

export default LeadsImportModal;
